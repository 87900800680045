/* eslint-disable react/no-unused-state */
import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import moment from 'moment';

import config from '../config';

import { IJWTContent } from '../interfaces/IUser';
import { IWebinar } from '../interfaces/IWebinar';
import { TRoles } from '../types/TAuth';
import { TGetData } from '../types/TGetData';

import History from '../utilities/History';

import AuthService from '../services/AuthService';
import WebinarService from '../services/WebinarService';

import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import TopNav from './components/TopNav';

import Dashboard from './pages/Dashboard';
import WebinarsOverview from './pages/Webinars/WebinarsOverview';
import WebinarForm from './pages/Webinars/WebinarForm';
import Graphics from './pages/Graphics';
import Users from './pages/Users';
import Questions from './pages/Questions';
import Statements from './pages/Statements';
import BreakoutSessions from './pages/BreakoutSessions';
import Speeddates from './pages/Speeddates';
import InformationWindow from './pages/InformationWindow';
import Chat from './pages/Chat';
import Survey from './pages/Survey';
import Host from './pages/Host';
import Registrations from './pages/Registrations/Overview';
import RegistrationsUpload from './pages/Registrations/Upload';
import Reports from './pages/Reports';
import AdminTools from './pages/AdminTools';
import UserPassword from './pages/User/Security';

import Error404 from './errorPages/Error404';

export type ContentProps = Record<string, unknown>;

export interface ContentState {
  userDetails: IJWTContent | undefined;
  initialConnect: boolean;
  connected: boolean;
  authenticated: boolean;
  graphicsConnected: boolean;
  roles: TRoles[];
  webinars: IWebinar[];
  activeWebinarId?: number | undefined;
  viewers: number;
  registrations: number;
}

class Content extends React.Component<ContentProps, ContentState> {
  io: SocketIOClient.Socket = io(config.api.endpoint, {
    autoConnect: false,
  });

  constructor(props: ContentProps) {
    super(props);

    this.state = {
      userDetails: AuthService.getUserDetails(),
      initialConnect: true,
      connected: false,
      authenticated: false,
      graphicsConnected: false,
      roles: [],
      webinars: [],
      activeWebinarId: WebinarService.getActiveWebinarId(),
      viewers: 0,
      registrations: 0,
    };
  }

  componentDidMount() {
    this.updateRolesFromToken();

    this.io.open();
    this.io.on('connect', this.handleConnect);
    this.io.on('disconnect', this.handleDisconnect);
    this.io.on('authenticated', this.handleAuthenticated);
    this.io.on('logout', this.handleLogout);

    this.registerIoEventHandlers();
  }

  componentWillUnmount() {
    this.io.removeAllListeners();
    this.io.close();
  }

  updateRolesFromToken = (): void => {
    const userDetails = AuthService.getUserDetails();

    if (userDetails) {
      const { roles } = userDetails;
      this.setState({ roles });
    }
  };

  handleConnect = (): void => {
    const token = AuthService.getToken();

    if (token) this.io.emit('authenticate', token);
    else AuthService.logout();

    this.setState({ initialConnect: false, connected: true });
  };

  handleDisconnect = (): void =>
    this.setState({ connected: false, authenticated: false });

  handleAuthenticated = (): void => {
    this.setState({ authenticated: true });

    const { activeWebinarId, initialConnect } = this.state;

    if (activeWebinarId) this.io.emit('activeWebinar', activeWebinarId);
    if (initialConnect) return;

    const { pathname } = History.location;

    if (pathname.split('/')[1].startsWith('users'))
      this.handleGetData('usersAndRoles');
    if (pathname.split('/')[1].startsWith('graphics-clients'))
      this.handleGetData('graphicsClients');
    if (pathname.split('/')[1].startsWith('questions'))
      this.handleGetData('questions');
    if (pathname.split('/')[1].startsWith('statements'))
      this.handleGetData('statements');
    if (pathname.split('/')[1].startsWith('breakout-sessions'))
      this.handleGetData('breakoutSessions');
    if (pathname.split('/')[1].startsWith('speeddates'))
      this.handleGetData('speeddates');
    if (pathname.split('/')[1].startsWith('chat')) this.handleGetData('chat');
    if (pathname.split('/')[1].startsWith('survey'))
      this.handleGetData('surveyQuestions');
  };

  handleLogout = (): void => {
    toast.error('Log opnieuw in om deze pagina te kunnen bekijken');
    AuthService.logout();
  };

  registerIoEventHandlers = () => {
    const { io: socket } = this;

    socket.on('webinars', (webinars: IWebinar[]): void =>
      this.setState({
        webinars: webinars.sort(
          (a, b) => moment(b.start).unix() - moment(a.start).unix(),
        ),
      }),
    );
    socket.on('authenticated', () => this.handleGetData('webinars'));
    socket.on('graphicsConnected', (graphicsConnected: boolean) =>
      this.setState({ graphicsConnected }),
    );
    socket.on('updateViewers', (viewers: number) => this.setState({ viewers }));
    socket.on('updateRegistrations', (registrations: number) =>
      this.setState({ registrations }),
    );
    socket.on('archiveWebinar', this.onArchiveWebinar);
  };

  handleGetData = (data: TGetData) => this.io.emit('getData', data);

  handleSetActiveWebinarId = (activeWebinarId: number) => {
    const { authenticated } = this.state;

    if (authenticated)
      this.setState({ activeWebinarId, viewers: 0 }, () => {
        WebinarService.setActiveWebinar(activeWebinarId);
        this.handleAuthenticated();
      });
    else {
      toast.warn('Er is iets mis gegaan. Log opnieuw in');
      AuthService.logout();
    }
  };

  handleArchiveWebinar = (id: IWebinar['id']) => {
    this.io.emit('archiveWebinar', id);

    this.onArchiveWebinar(id, true);
  };

  handleResetReminders = (id: IWebinar['id']) => {
    this.io.emit('resetReminders', id);
  };

  onArchiveWebinar = (id: IWebinar['id'], self = false) => {
    const { activeWebinarId } = this.state;
    const sameAsActiveWebinar = id === activeWebinarId;

    if (sameAsActiveWebinar) {
      WebinarService.removeActiveWebinar();

      if (!self)
        toast.warn(
          'Een andere gebruiker heeft jouw actieve webinar gearchiveerd',
        );
    }

    this.setState(({ webinars }) => ({
      webinars: webinars.filter((w) => w.id !== id),
      activeWebinarId: sameAsActiveWebinar ? undefined : activeWebinarId,
    }));
  };

  render() {
    const {
      state: {
        userDetails,
        connected,
        graphicsConnected,
        webinars,
        activeWebinarId,
        viewers,
        registrations,
      },
      io: socket,
    } = this;

    const activeWebinar = webinars.find(({ id }) => id === activeWebinarId);

    return (
      <div id="wrapper">
        <Switch>
          <Route
            exact
            path="/host"
            render={() => (
              <Host
                io={socket}
                connected={connected}
                activeWebinar={activeWebinar}
              />
            )}
          />
          <Route
            path="*"
            render={() => (
              <>
                <Sidebar
                  activeWebinarId={activeWebinarId}
                  disableQuestions={activeWebinar?.disableQuestions}
                  disableRegistrations={activeWebinar?.disableRegistrations}
                />
                <div id="content-wrapper" className="d-flex flex-column">
                  <div id="content">
                    <TopNav
                      webinars={webinars}
                      userDetails={userDetails}
                      connected={connected}
                      graphicsConnected={graphicsConnected}
                      activeWebinarId={activeWebinarId}
                      disableRegistrations={activeWebinar?.disableRegistrations}
                      isBullsAndBears={activeWebinar?.isBullsAndBears}
                      state={activeWebinar?.state}
                      viewers={viewers}
                      registrations={registrations}
                      handleSetActiveWebinarId={this.handleSetActiveWebinarId}
                    />
                    <Switch>
                      <Route exact path="/404" component={Error404} />
                      <Route exact path="/" component={Dashboard} />
                      <Route
                        exact
                        path="/user/security"
                        component={UserPassword}
                      />
                      {AuthService.hasRole(config.roles.webinars) && (
                        <Route
                          path="/webinars/overview"
                          render={() => (
                            <WebinarsOverview
                              io={socket}
                              connected={connected}
                              webinars={webinars}
                              onArchive={this.handleArchiveWebinar}
                              onResetReminders={this.handleResetReminders}
                            />
                          )}
                        />
                      )}
                      {AuthService.hasRole(config.roles.webinars) && (
                        <Route
                          path="/webinars/add"
                          component={WebinarForm}
                          key="webinarAdd"
                        />
                      )}
                      {AuthService.hasRole(config.roles.webinars) && (
                        <Route
                          path="/webinars/edit/:webinarId"
                          component={WebinarForm}
                          key="webinarEdit"
                        />
                      )}
                      {AuthService.hasRole(config.roles.graphics) && (
                        <Route
                          path="/graphics"
                          render={() => (
                            <Graphics
                              io={socket}
                              connected={connected}
                              activeWebinarId={activeWebinarId}
                            />
                          )}
                        />
                      )}
                      {AuthService.hasRole(config.roles.users) && (
                        <Route
                          path="/users"
                          render={() => (
                            <Users
                              io={socket}
                              // connected={connected}
                              myUserId={userDetails?.id}
                            />
                          )}
                        />
                      )}
                      {activeWebinarId &&
                        AuthService.hasRole(config.roles.questions) && (
                          <Route
                            path="/questions"
                            render={() => (
                              <Questions io={socket} connected={connected} />
                            )}
                          />
                        )}
                      {activeWebinarId &&
                        AuthService.hasRole(config.roles.statements) && (
                          <Route
                            path="/statements"
                            render={() => (
                              <Statements
                                io={socket}
                                handleGetData={this.handleGetData}
                                connected={connected}
                                graphicsConnected={graphicsConnected}
                              />
                            )}
                          />
                        )}
                      {activeWebinarId &&
                        AuthService.hasRole(config.roles.breakoutSessions) && (
                          <Route
                            path="/breakout-sessions"
                            render={() => (
                              <BreakoutSessions
                                io={socket}
                                connected={connected}
                              />
                            )}
                          />
                        )}
                      {activeWebinarId &&
                        AuthService.hasRole(config.roles.speeddates) && (
                          <Route
                            path="/speeddates"
                            render={() => (
                              <Speeddates
                                io={socket}
                                connected={connected}
                                activeWebinar={activeWebinar}
                              />
                            )}
                          />
                        )}
                      {activeWebinarId &&
                        AuthService.hasRole(config.roles.informationWindow) && (
                          <Route
                            path="/information-window"
                            render={() => (
                              <InformationWindow
                                io={socket}
                                connected={connected}
                                activeWebinar={activeWebinar}
                              />
                            )}
                          />
                        )}
                      {activeWebinarId &&
                        AuthService.hasRole(config.roles.chat) && (
                          <Route
                            path="/chat"
                            render={() => (
                              <Chat io={socket} connected={connected} />
                            )}
                          />
                        )}
                      {activeWebinarId &&
                        AuthService.hasRole(config.roles.survey) && (
                          <Route
                            path="/survey"
                            render={() => (
                              <Survey
                                io={socket}
                                connected={connected}
                                state={activeWebinar?.state}
                              />
                            )}
                          />
                        )}
                      {activeWebinarId &&
                        AuthService.hasRole(config.roles.registrations) && (
                          <Route
                            path="/registrations/upload"
                            render={() => (
                              <RegistrationsUpload
                                activeWebinarId={activeWebinarId}
                              />
                            )}
                          />
                        )}
                      {activeWebinarId &&
                        AuthService.hasRole(config.roles.registrations) && (
                          <Route
                            path="/registrations"
                            render={() => (
                              <Registrations
                                activeWebinarId={activeWebinarId}
                              />
                            )}
                          />
                        )}
                      {AuthService.hasRole(config.roles.reports) && (
                        <Route
                          path="/reports"
                          render={() => (
                            <Reports activeWebinar={activeWebinar} />
                          )}
                        />
                      )}
                      {activeWebinarId &&
                        AuthService.hasRole(config.roles.adminTools) && (
                          <Route
                            path="/admin-tools"
                            render={() => (
                              <AdminTools
                                io={socket}
                                connected={connected}
                                activeWebinar={activeWebinar}
                              />
                            )}
                          />
                        )}
                      <Redirect to="/404" />
                    </Switch>
                  </div>
                  <Footer />
                </div>
              </>
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default Content;
