import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import FontPicker from 'font-picker-react';

import { IWebinar, IWebinarSettings } from '../../../../interfaces/IWebinar';
import { EWebinarState, EWebinarVideoDelay } from '../../../../enums/EWebinar';

import HttpService from '../../../../services/HttpService';
import StorageService from '../../../../services/StorageService';

import History from '../../../../utilities/History';

import WysiwygEditor from '../../../components/WysiwygEditor';
import DateTimeRangePicker from '../../../components/DateTimeRangePicker';

import ColorPicker from './ColorPicker';
import config from '../../../../config';
import VariabelePicker from './VariabelePicker';
import WebinarLanguageSelector from './WebinarLanguageSelectorProps';

interface MatchParams {
  webinarId?: string;
}

export interface WebinarFormProps extends RouteComponentProps<MatchParams> {
  edit: boolean;
}

export interface WebinarFormState {
  webinarId?: string;
  webinar: IWebinar;
  settings: IWebinarSettings;
  file: null | File;
  copied: boolean;
  saving: boolean;
}

class WebinarForm extends React.Component<WebinarFormProps, WebinarFormState> {
  constructor(props: WebinarFormProps) {
    super(props);

    const {
      match: {
        params: { webinarId },
      },
    } = this.props;

    this.state = {
      webinarId,
      webinar: {
        id: 0,
        name: '',
        company: 'Q Broadcasting',
        start: moment().add(1, 'day').hour(12).startOf('hour').toISOString(),
        end: moment().add(1, 'day').hour(14).startOf('hour').toISOString(),
        state: EWebinarState.pre,
        videoDelay: EWebinarVideoDelay.low,
        settings: '{}',
        lang: 'nl',
        overrideStreamUrl: '',
        streamKey: '',
        registrationKey: '',
        agenda: '',
        emailConfirmation:
          '<p style="line-height: 24px; margin-bottom:15px;">Beste %FIRST_NAME%,</p><p style="line-height: 24px;margin-bottom:15px;">Wat fijn dat je je hebt aangemeld voor %WEBINAR_NAME%. Op %DATE_TIME% kun je via onderstaande button meekijken naar het webinar.</p><p style="line-height: 24px; margin-bottom:20px;">Tot snel!</p><p style="line-height: 24px">Met vriendelijke groet,<br />%COMPANY%</p>',
        emailFirstReminder:
          '<p style="line-height: 24px; margin-bottom:15px;">Beste %FIRST_NAME%,</p><p style="line-height: 24px;margin-bottom:15px;">Wat fijn dat je je hebt aangemeld voor %WEBINAR_NAME%. Morgen, %DATE_TIME%, kun je via onderstaande link meekijken.</p><p style="line-height: 24px; margin-bottom:20px;">Tot dan!</p><p style="line-height: 24px">Met vriendelijke groet,<br />%COMPANY%</p>',
        emailSecondReminder:
          '<p style="line-height: 24px; margin-bottom:15px;">Beste %FIRST_NAME%,</p><p style="line-height: 24px;margin-bottom:15px;">Hierbij nogmaals de link naar %WEBINAR_NAME%, %DATE_TIME%.</p><p style="line-height: 24px; margin-bottom:20px;">Veel kijkplezier!</p><p style="line-height: 24px">Met vriendelijke groet,<br />%COMPANY%</p>',
        disableRegistrations: false,
        disableQuestions: false,
        informationWindowActive: false,
        breakoutSessionsActive: false,
        singleViewer: true,
        maxRegistrations: null,
        isBullsAndBears: false,
      },
      settings: {
        backgroundColor: '#851f71',
        accentColor: '#008fc5',
        textColor: '#FFFFFF',
        fontFamily: 'Roboto',
        fontSize: 28,
      },
      file: null,
      copied: false,
      saving: false,
    };
  }

  async componentDidMount() {
    const { webinarId } = this.state;

    if (webinarId) {
      try {
        const { data: webinar } = await HttpService.get(
          `/webinar/${webinarId}`,
        );

        this.setState({
          webinar: { ...webinar },
          settings: JSON.parse(webinar.settings),
        });
      } catch (e) {
        if (e.response?.status === 403)
          toast.error(
            'Je hebt niet de juiste rechten om deze pagina te bekijken. Log opnieuw in en probeer het nogmaals',
          );
        if (e.response?.status === 404) History.push('/404');
      }
    }
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  handleLangChange = (lang: string) =>
    this.setState((s) => ({
      ...s,
      webinar: { ...s.webinar, lang },
    }));

  handleDateTimeChange = (id: string, date: Date) =>
    this.setState((s) => ({
      ...s,
      webinar: { ...s.webinar, [id]: date.toISOString() },
    }));

  handleInputChange = ({
    currentTarget: { id, type, checked, value },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    let setValue: string | number | boolean = value;

    if (type === 'checkbox') setValue = checked;
    else if (id === 'maxRegistrations') setValue = parseInt(value, 10);

    this.setState((s) => ({
      ...s,
      webinar: { ...s.webinar, [id]: setValue },
    }));
  };

  handleSingleViewer = (singleViewer: boolean) =>
    this.setState(({ webinar }) => ({
      webinar: { ...webinar, singleViewer },
    }));

  handleRegisterEmail = (registerEmail: boolean) =>
    this.setState(({ webinar }) => ({
      webinar: { ...webinar, registerEmail },
    }));

  handleRegisterCompany = (registerCompany: boolean) =>
    this.setState(({ webinar }) => ({
      webinar: { ...webinar, registerCompany },
    }));

  handleRegisterPhone = (registerPhone: boolean) =>
    this.setState(({ webinar }) => ({
      webinar: { ...webinar, registerPhone },
    }));

  handleDisableRegistrations = (disableRegistrations: boolean) =>
    this.setState(({ webinar }) => ({
      webinar: { ...webinar, disableRegistrations },
    }));

  handleDisableQuestions = (disableQuestions: boolean) =>
    this.setState(({ webinar }) => ({
      webinar: { ...webinar, disableQuestions },
    }));

  handleIsBullsAndBears = (isBullsAndBears: boolean) =>
    this.setState(({ webinar }) => ({
      webinar: { ...webinar, isBullsAndBears },
    }));

  handleRegistrationToPlayer = (registrationToPlayer: boolean) =>
    this.setState(({ webinar }) => ({
      webinar: { ...webinar, registrationToPlayer },
    }));

  handleEditorChange = (id: string, value: string) =>
    this.setState((s) => ({
      ...s,
      webinar: { ...s.webinar, [id]: value },
    }));

  handleVideoDelay = (videoDelay: EWebinarVideoDelay) =>
    this.setState(({ webinar }) => ({
      webinar: { ...webinar, videoDelay },
    }));

  handleFileChange = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && files.length > 0) {
      const selectedFile = files[0];

      if (!selectedFile.type.includes('image/'))
        toast.warn('Je kunt enkel afbeeldingen uploaden');
      else if (selectedFile) this.setState({ file: selectedFile });
    }
  };

  handleSettingsChange = (key: string, value: string) =>
    this.setState(({ settings }) => ({
      settings: {
        ...settings,
        [key]: key === 'fontSize' ? parseInt(value, 10) : value,
      },
    }));

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const {
      webinarId,
      webinar: { id, ...webinar },
      settings,
      file,
      saving,
    } = this.state;
    const edit = !!webinarId;

    if (saving) return;

    this.setState({ saving: true });

    if (moment(webinar.end).isBefore(webinar.start)) {
      toast.warn('De einddatum kan niet voor de startdatum liggen');
      return;
    }

    Reflect.deleteProperty(webinar, 'createdAt');
    Reflect.deleteProperty(webinar, 'updatedAt');
    Reflect.deleteProperty(webinar, 'deletedAt');
    Reflect.deleteProperty(webinar, 'logo');
    Reflect.deleteProperty(webinar, 'streamKey');
    Reflect.deleteProperty(webinar, 'registrationKey');

    const body: Omit<IWebinar, 'id'> = {
      ...webinar,
      maxRegistrations:
        webinar.maxRegistrations === 0 ? null : webinar.maxRegistrations,
      settings: JSON.stringify(settings),
    };

    let postId = id;

    if (edit) await HttpService.patch(`/webinar/${postId}`, body);
    else {
      const { data } = await HttpService.post(`/webinar`, body);
      postId = data.id;
    }

    if (file) {
      const logo = new FormData();
      logo.append('logo', file);
      await HttpService.post(`/webinar/logo/${postId}`, logo);
    }

    setTimeout(() => {
      toast.success(`${webinar.name} is succesvol opgeslagen`);
      this.setState({ saving: false });

      if (!edit) {
        History.push(`/webinars/edit/${postId}`);
      }
    }, 700);
  };

  copied = () =>
    this.setState({ copied: true }, () =>
      setTimeout(() => this.setState({ copied: false }), 1000),
    );

  setOverrideStreamUrlToDemo = () => {
    this.setState((s) => ({
      ...s,
      webinar: {
        ...s.webinar,
        overrideStreamUrl:
          'https://qbroadcasting-vod.cdn.hostin.cc/mp4:bbb_1080p_25fps.mp4/playlist.m3u8',
      },
    }));
  };

  emptyRegisterCustom = (custom: 1 | 2 | 3) => {
    this.setState((s) => ({
      ...s,
      webinar: {
        ...s.webinar,
        [`registerCustom${custom}`]: '',
      },
    }));
  };

  render() {
    const {
      webinarId,
      webinar: {
        id,
        name,
        company,
        start,
        end,
        videoDelay,
        lang,
        logo,
        registerEmail,
        registerCompany,
        registerPhone,
        registerCustom1,
        registerCustom2,
        registerCustom3,
        streamKey,
        overrideStreamUrl,
        registrationKey,
        disableRegistrations,
        disableQuestions,
        singleViewer,
        maxRegistrations,
        agenda,
        playerDescription,
        registrationToPlayer,
        emailConfirmation,
        emailFirstReminder,
        emailSecondReminder,
        isBullsAndBears,
      },
      settings,
      file,
      copied,
      saving,
    } = this.state;
    const edit = !!webinarId;
    const { endpoint } = config.webinar;

    return (
      <div className="container-fluid">
        <ReactTooltip />
        <h1 className="h3 text-gray-800">
          <span className="font-weight-bold">
            Webinar {edit ? 'bewerken' : 'toevoegen'}
          </span>
        </h1>
        <h1 className="h5 mb-4 text-gray-800">
          {edit && id !== 0 && ` ${name}`}
        </h1>
        {edit && id === 0 ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-6">
                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Instellingen
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <label htmlFor="start" className="col-sm-4">
                        Start
                      </label>
                      <div className="col-sm-8">
                        <DateTimeRangePicker
                          id="start"
                          className="form-control"
                          value={moment(start).toDate()}
                          onChange={this.handleDateTimeChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="end" className="col-sm-4">
                        Einde
                      </label>
                      <div className="col-sm-8">
                        <DateTimeRangePicker
                          id="end"
                          className="form-control"
                          value={moment(end).toDate()}
                          onChange={this.handleDateTimeChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="end" className="col-sm-4">
                        Taal
                      </label>
                      <div className="col-sm-8">
                        <WebinarLanguageSelector
                          lang={lang}
                          onChange={this.handleLangChange}
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="form-group row">
                      <label htmlFor="isBullsAndBears" className="col-sm-4">
                        Bulls & Bears
                      </label>
                      <div className="col-sm-8">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isBullsAndBears"
                            id="isBullsAndBearsOff"
                            value="off"
                            onChange={() => this.handleIsBullsAndBears(true)}
                            checked={isBullsAndBears}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isBullsAndBearsOff"
                          >
                            Ja
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isBullsAndBears"
                            id="isBullsAndBearsOn"
                            value="on"
                            onChange={() => this.handleIsBullsAndBears(false)}
                            checked={!isBullsAndBears}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isBullsAndBearsOn"
                          >
                            Nee
                          </label>
                        </div>
                        {!!isBullsAndBears && (
                          <div className="alert alert-warning mt-2 mb-0">
                            Let op! Bulls & Bears is actief bij dit webinar.
                          </div>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="form-group row">
                      <label htmlFor="disableQuestions" className="col-sm-4">
                        Vragen-module
                      </label>
                      <div className="col-sm-8">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="disableQuestions"
                            id="disableQuestionsOff"
                            value="off"
                            onChange={() => this.handleDisableQuestions(false)}
                            checked={!disableQuestions}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="disableQuestionsOff"
                          >
                            Actief
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="disableQuestions"
                            id="disableQuestionsOn"
                            value="on"
                            onChange={() => this.handleDisableQuestions(true)}
                            checked={disableQuestions}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="disableQuestionsOn"
                          >
                            Inactief
                          </label>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="form-group row">
                      <label
                        htmlFor="disableRegistrations"
                        className="col-sm-4"
                      >
                        Mogelijkheid tot registratie
                      </label>
                      <div className="col-sm-8">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="disableRegistrations"
                            id="disableRegistrationsOff"
                            value="off"
                            onChange={() =>
                              this.handleDisableRegistrations(false)
                            }
                            checked={!disableRegistrations}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="disableRegistrationsOff"
                          >
                            Actief
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="disableRegistrations"
                            id="disableRegistrationsOn"
                            value="on"
                            onChange={() =>
                              this.handleDisableRegistrations(true)
                            }
                            checked={disableRegistrations}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="disableRegistrationsOn"
                          >
                            Inactief
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className={disableRegistrations ? 'd-none' : ''}>
                      <div className="form-group row">
                        <label htmlFor="singleViewer" className="col-sm-4">
                          Single viewer
                        </label>
                        <div className="col-sm-8">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="singleViewer"
                              id="singleViewerOn"
                              value="on"
                              onChange={() => this.handleSingleViewer(true)}
                              checked={singleViewer}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="singleViewerOn"
                            >
                              Aan
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="singleViewer"
                              id="singleViewerOff"
                              value="off"
                              onChange={() => this.handleSingleViewer(false)}
                              checked={!singleViewer}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="singleViewerOff"
                            >
                              Uit
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="maxRegistrations" className="col-sm-4">
                          Maximum aantal registraties
                        </label>
                        <div className="col-sm-2">
                          <input
                            type="number"
                            className="form-control"
                            id="maxRegistrations"
                            min={0}
                            value={maxRegistrations ?? 0}
                            onChange={this.handleInputChange}
                          />
                          <small
                            id="maxRegistrationsHelp"
                            className="form-text text-muted"
                          >
                            0 = geen limiet
                          </small>
                        </div>
                      </div>
                      <hr />
                      <div className="form-group row">
                        <label
                          htmlFor="registrationToPlayer"
                          className="col-sm-4"
                        >
                          Laat zien na registratie
                        </label>
                        <div className="col-sm-8">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="registrationToPlayer"
                              id="registrationToPlayerOff"
                              value="off"
                              onChange={() =>
                                this.handleRegistrationToPlayer(false)
                              }
                              checked={!registrationToPlayer}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="registrationToPlayerOff"
                            >
                              Bedankt-pagina
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="registrationToPlayer"
                              id="registrationToPlayerOn"
                              value="on"
                              onChange={() =>
                                this.handleRegistrationToPlayer(true)
                              }
                              checked={registrationToPlayer}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="registrationToPlayerOn"
                            >
                              Player
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="form-group row">
                        <label htmlFor="singleViewer" className="col-sm-4">
                          Vraag gegevens
                        </label>
                        <div className="col-sm-8">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="registerEmail"
                              id="registerEmail"
                              value="on"
                              onChange={({ currentTarget: { checked } }) =>
                                this.handleRegisterEmail(checked)
                              }
                              checked={registerEmail}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="registerEmail"
                            >
                              E-mail
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="registerCompany"
                              id="registerCompany"
                              value="on"
                              onChange={({ currentTarget: { checked } }) =>
                                this.handleRegisterCompany(checked)
                              }
                              checked={registerCompany}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="registerCompany"
                            >
                              Bedrijfsnaam
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="registerPhone"
                              id="registerPhone"
                              value="on"
                              onChange={({ currentTarget: { checked } }) =>
                                this.handleRegisterPhone(checked)
                              }
                              checked={registerPhone}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="registerPhone"
                            >
                              Telefoonnummer
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row mb-0">
                        <label htmlFor="registerCustom1" className="col-sm-4">
                          Dynamisch veld 1
                        </label>
                        <div className="col-sm-8">
                          <div className="input-group mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="registerCustom1"
                              value={registerCustom1 ?? ''}
                              onChange={this.handleInputChange}
                            />
                            <div className="input-group-append">
                              <div className="input-group-text">
                                <button
                                  type="button"
                                  className="btn btn-link text-dark btn-sm p-0"
                                  onClick={() => this.emptyRegisterCustom(1)}
                                >
                                  <i className="fas fa-times" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {(registerCustom1 || registerCustom2) && (
                        <div className="form-group row mb-0">
                          <label htmlFor="registerCustom1" className="col-sm-4">
                            Dynamisch veld 2
                          </label>
                          <div className="col-sm-8">
                            <div className="input-group mb-2">
                              <input
                                type="text"
                                className="form-control"
                                id="registerCustom2"
                                value={registerCustom2 ?? ''}
                                onChange={this.handleInputChange}
                              />
                              <div className="input-group-append">
                                <div className="input-group-text">
                                  <button
                                    type="button"
                                    className="btn btn-link text-dark btn-sm p-0"
                                    onClick={() => this.emptyRegisterCustom(2)}
                                  >
                                    <i className="fas fa-times" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {((registerCustom1 && registerCustom2) ||
                        registerCustom3) && (
                        <div className="form-group row">
                          <label htmlFor="registerCustom1" className="col-sm-4">
                            Dynamisch veld 3
                          </label>
                          <div className="col-sm-8">
                            <div className="input-group mb-2">
                              <input
                                type="text"
                                className="form-control"
                                id="registerCustom3"
                                value={registerCustom3 ?? ''}
                                onChange={this.handleInputChange}
                              />
                              <div className="input-group-append">
                                <div className="input-group-text">
                                  <button
                                    type="button"
                                    className="btn btn-link text-dark btn-sm p-0"
                                    onClick={() => this.emptyRegisterCustom(3)}
                                  >
                                    <i className="fas fa-times" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {edit && (
                <div className="col-6">
                  <div className="col-12">
                    <div className="card shadow mb-4">
                      <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Algemeen
                        </h6>
                      </div>
                      <div className="card-body">
                        <div className="form-group row">
                          <label className="col-sm-4">ID</label>
                          <div className="col-sm-8">{id}</div>
                        </div>
                        <div
                          className={`form-group row${
                            disableRegistrations ? ' d-none' : ''
                          }`}
                        >
                          <label className="col-sm-4">Registratie-link</label>
                          <div className="col-sm-8">
                            <a
                              className="text-sm"
                              href={`${endpoint}/register/${registrationKey}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {endpoint}/register/
                              {registrationKey}
                            </a>
                          </div>
                        </div>
                        <div
                          className={`form-group row${
                            !disableRegistrations ? ' d-none' : ''
                          }`}
                        >
                          <label className="col-sm-4">Player-link</label>
                          <div className="col-sm-8">
                            <a
                              className="text-sm"
                              href={`${endpoint}/player/${registrationKey}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {endpoint}/player/
                              {registrationKey}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="card shadow mb-4">
                      <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Stream
                        </h6>
                      </div>
                      <div className="card-body">
                        <div className="form-group row">
                          <label
                            htmlFor="overrideStreamUrl"
                            className="col-sm-4"
                          >
                            Forceer stream URL
                          </label>
                          <div className="col-sm-8">
                            <div className="input-group mb-2">
                              <input
                                type="text"
                                className="form-control"
                                id="overrideStreamUrl"
                                value={overrideStreamUrl ?? ''}
                                onChange={this.handleInputChange}
                              />
                              <div className="input-group-append">
                                <div className="input-group-text">
                                  <button
                                    type="button"
                                    className="btn btn-link text-dark btn-sm p-0"
                                    onClick={this.setOverrideStreamUrlToDemo}
                                  >
                                    <i className="fas fa-fw fa-cloud-download-alt mr-2" />
                                    Demo
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {!overrideStreamUrl && (
                          <>
                            <div className="form-group row">
                              <label className="col-sm-4">
                                RTMP Stream server
                              </label>
                              <div className="col-sm-8">
                                <textarea
                                  className="form-control"
                                  id="streamServer"
                                  defaultValue="rtmp://media4.hostin.cc:1935/qbroadcasting_ld"
                                  onClick={(e) => {
                                    e.currentTarget.select();
                                    document.execCommand('copy');
                                    this.copied();
                                  }}
                                  rows={1}
                                  readOnly
                                  data-tip={
                                    copied
                                      ? 'Gekopiëerd'
                                      : 'Klik om te kopiëren'
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-4">
                                RTMP Stream name (key)
                              </label>
                              <div className="col-sm-8">
                                <textarea
                                  className="form-control"
                                  id="streamKey"
                                  defaultValue={streamKey}
                                  onClick={(e) => {
                                    e.currentTarget.select();
                                    document.execCommand('copy');
                                    this.copied();
                                  }}
                                  rows={1}
                                  readOnly
                                  data-tip={
                                    copied
                                      ? 'Gekopiëerd'
                                      : 'Klik om te kopiëren'
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-4">RTMP Username</label>
                              <div className="col-sm-8">
                                <textarea
                                  className="form-control"
                                  id="rtmp_username"
                                  defaultValue="qbroadcasting"
                                  onClick={(e) => {
                                    e.currentTarget.select();
                                    document.execCommand('copy');
                                    this.copied();
                                  }}
                                  rows={1}
                                  readOnly
                                  data-tip={
                                    copied
                                      ? 'Gekopiëerd'
                                      : 'Klik om te kopiëren'
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-4">RTMP Password</label>
                              <div className="col-sm-8">
                                <textarea
                                  className="form-control"
                                  id="rtmp_password"
                                  defaultValue="bP6Bg2Jp5t4ANPtbLZBjVbMx"
                                  onClick={(e) => {
                                    e.currentTarget.select();
                                    document.execCommand('copy');
                                    this.copied();
                                  }}
                                  rows={1}
                                  readOnly
                                  data-tip={
                                    copied
                                      ? 'Gekopiëerd'
                                      : 'Klik om te kopiëren'
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label htmlFor="videoDelay" className="col-sm-4">
                                Video delay
                              </label>
                              <div className="col-sm-8">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="videoDelay"
                                    id="videoDelayLow"
                                    value="low"
                                    onChange={() =>
                                      this.handleVideoDelay(
                                        EWebinarVideoDelay.low,
                                      )
                                    }
                                    checked={
                                      videoDelay === EWebinarVideoDelay.low
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="videoDelayLow"
                                  >
                                    Laag &plusmn; 10s
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="videoDelay"
                                    id="videoDelayMedium"
                                    value="medium"
                                    onChange={() =>
                                      this.handleVideoDelay(
                                        EWebinarVideoDelay.medium,
                                      )
                                    }
                                    checked={
                                      videoDelay === EWebinarVideoDelay.medium
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="videoDelayMedium"
                                  >
                                    Medium &plusmn; 40s
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card shadow mb-4">
                  <a
                    href="#informationCollapse"
                    className="d-block card-header py-3"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="informationCollapse"
                  >
                    <h6 className="m-0 font-weight-bold text-primary">
                      Informatie
                    </h6>
                  </a>
                  <div className="collapse" id="informationCollapse">
                    <div className="card-body">
                      <div className="form-group row">
                        <label htmlFor="name" className="col-sm-2">
                          Naam
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={name}
                            onChange={this.handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="name" className="col-sm-2">
                          Bedrijfsnaam
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            id="company"
                            value={company}
                            onChange={this.handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="agenda" className="col-sm-2">
                          Tekst op registratiepagina
                        </label>
                        <div className="col-sm-10">
                          <WysiwygEditor
                            id="agenda"
                            value={agenda ?? ''}
                            onChange={this.handleEditorChange}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="playerDescription" className="col-sm-2">
                          Tekst op player-pagina
                        </label>
                        <div className="col-sm-10">
                          <WysiwygEditor
                            id="playerDescription"
                            value={playerDescription ?? ''}
                            onChange={this.handleEditorChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card shadow mb-4">
              <a
                href="#emailContentCollapse"
                className="d-block card-header py-3"
                data-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="emailContentCollapse"
              >
                <h6 className="m-0 font-weight-bold text-primary">
                  E-mail templates
                </h6>
              </a>
              <div className="collapse" id="emailContentCollapse">
                <div className="card-body">
                  <div
                    className={`form-group row${
                      disableRegistrations ? ' d-none' : ''
                    }`}
                  >
                    <label className="col-sm-2">
                      Variabelen
                      <br />
                      <small className="font-italic">
                        Klik er op om te kopiëren
                      </small>
                    </label>
                    <div className="col-sm-10">
                      <div className="form-inline">
                        <VariabelePicker value="%FIRST_NAME%" example="Josh" />
                        <VariabelePicker
                          value="%LAST_NAME%"
                          example="de Volder"
                        />
                        <VariabelePicker
                          value="%WEBINAR_NAME%"
                          example={name}
                        />
                        <VariabelePicker
                          value="%DATE_TIME%"
                          example={moment(start).format('LLL')}
                        />
                        <VariabelePicker value="%COMPANY%" example={company} />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="emailConfirmation" className="col-sm-2">
                      Bevestigingsmail
                      <br />
                      <small className="font-italic">Na registratie</small>
                    </label>
                    <div className="col-sm-10">
                      <WysiwygEditor
                        id="emailConfirmation"
                        value={emailConfirmation ?? ''}
                        onChange={this.handleEditorChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="emailFirstReminder" className="col-sm-2">
                      Eerste reminder
                      <br />
                      <small className="font-italic">
                        Één dag van tevoren om 10:00 uur
                      </small>
                    </label>
                    <div className="col-sm-10">
                      <WysiwygEditor
                        id="emailFirstReminder"
                        value={emailFirstReminder ?? ''}
                        onChange={this.handleEditorChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="emailSecondReminder" className="col-sm-2">
                      Tweede reminder
                      <br />
                      <small className="font-italic">
                        Twee uur voor aanvang
                      </small>
                    </label>
                    <div className="col-sm-10">
                      <WysiwygEditor
                        id="emailSecondReminder"
                        value={emailSecondReminder ?? ''}
                        onChange={this.handleEditorChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card shadow mb-4">
                  <a
                    href="#identityCollapse"
                    className="d-block card-header py-3"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="identityCollapse"
                  >
                    <h6 className="m-0 font-weight-bold text-primary">
                      Huisstijl
                    </h6>
                  </a>
                  <div className="collapse" id="identityCollapse">
                    <div className="card-body">
                      <div className="form-group row mb-5">
                        <label htmlFor="agenda" className="col-sm-1">
                          Logo
                        </label>
                        <div className="col-sm-11">
                          <div className="col-5 border">
                            <div className="mb-2">
                              {file && (
                                <img
                                  className="webinar-logo"
                                  src={URL.createObjectURL(file)}
                                  alt="Logo"
                                />
                              )}
                              {logo && !file && (
                                <img
                                  className="webinar-logo"
                                  src={StorageService.logoUrl(logo)}
                                  alt="Logo"
                                />
                              )}
                            </div>
                            <div className="files">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="logo"
                                name="logo"
                                accept="image/*"
                                onChange={this.handleFileChange}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="logo"
                              >
                                {file?.name ?? 'Kies een bestand'}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="qt" className="col-sm-1">
                          Titel
                        </label>
                        <div className="col-sm-11">
                          <div className="form-inline">
                            <div className="form-group">
                              <FontPicker
                                apiKey="AIzaSyBMIorzaLQ14-Ox2J0TLy34KVAFPJe8CGI"
                                activeFontFamily={settings.fontFamily}
                                variants={['regular', '700']}
                                limit={100}
                                onChange={(fontFamily) =>
                                  this.handleSettingsChange(
                                    'fontFamily',
                                    fontFamily.family,
                                  )
                                }
                              />
                            </div>
                            <div className="form-group ml-4">
                              <div className="input-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  id="fontSize"
                                  min={15}
                                  max={50}
                                  value={settings.fontSize}
                                  onChange={({
                                    currentTarget: { value: fontSize },
                                  }) =>
                                    this.handleSettingsChange(
                                      'fontSize',
                                      fontSize,
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {settings.fontSize >= 30 && (
                        <div className="form-group row mb-0">
                          <div className="col-sm-1" />
                          <div className="col-sm-11">
                            <div className="form-group">
                              <span className="text-danger font-weight-bold">
                                LET OP: Een te grote tekstgrootte kan ervoor
                                zorgen dat de tekst niet meer in het venster
                                past. Test vooraf goed met deze instellingen!
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <div className="col-sm-11">
                          <div className="form-inline inline-colors">
                            <ColorPicker
                              text="Tekstkleur"
                              color={settings.textColor}
                              onChange={(textColor) =>
                                this.handleSettingsChange(
                                  'textColor',
                                  textColor,
                                )
                              }
                            />
                            <ColorPicker
                              text="Achtergrondkleur"
                              color={settings.backgroundColor}
                              onChange={(backgroundColor) =>
                                this.handleSettingsChange(
                                  'backgroundColor',
                                  backgroundColor,
                                )
                              }
                            />
                            <ColorPicker
                              text="Accentkleur"
                              color={settings.accentColor}
                              onChange={(accentColor) =>
                                this.handleSettingsChange(
                                  'accentColor',
                                  accentColor,
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <div
                          className="col-sm-7 apply-font"
                          style={{ fontSize: settings.fontSize - 10 }}
                        >
                          <div
                            style={{
                              color: settings.textColor,
                              backgroundColor: settings.backgroundColor,
                            }}
                          >
                            <div className="p-2">
                              <span className="font-weight-bold">
                                John Appleseed
                              </span>
                              <br />
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Aenean turpis ex, tincidunt quis tortor a,
                              egestas ornare neque.
                            </div>
                            <div
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: '75%',
                                width: '25%',
                                height: '100%',
                                backgroundColor: settings.accentColor,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={saving}
                >
                  {saving && <i className="fas fa-circle-notch fa-spin mr-2" />}
                  Webinar opslaan
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default withRouter(WebinarForm);
