import settings from './settings.json';
import { TRoles } from './types/TAuth';

interface IRolesConfig {
  [key: string]: TRoles[];
}

const env = process.env.NODE_ENV || 'development';
const deployment = process.env.REACT_APP_DEPLOYMENT || env;
const version =
  deployment === 'production' ? `v${settings.version}` : deployment;

export default {
  env,
  deployment,
  version,

  api: {
    endpoint: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3001',
    prefix: process.env.REACT_APP_API_PREFIX || '',
    token: process.env.REACT_APP_API_TOKEN || 'abc123',
  },

  webinar: {
    endpoint: process.env.REACT_APP_WEBINAR_ENDPOINT || 'http://localhost:3002',
  },

  roles: {
    users: ['admin'],
    webinars: ['admin'],
    graphics: ['admin', 'engineer'],
    reports: ['admin'],
    registrations: ['admin'],
    questions: ['admin', 'engineer', 'moderator'],
    statements: ['admin', 'engineer', 'moderator'],
    breakoutSessions: ['admin', 'engineer', 'moderator'],
    speeddates: ['admin', 'engineer', 'moderator'],
    informationWindow: ['admin', 'engineer', 'moderator'],
    chat: ['admin', 'moderator'],
    survey: ['admin', 'moderator'],
    adminTools: ['admin', 'engineer'],
    host: ['admin', 'host'],
  } as IRolesConfig,
};
