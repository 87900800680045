import * as React from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

import {
  ISurveyQuestion,
  TSurveyQuestionType,
} from '../../../interfaces/ISurvey';

import { typeName } from './SurveyTable';

export interface SurveyFormProps {
  io: SocketIOClient.Socket;
  buttonDisabled: boolean;
  edit?: ISurveyQuestion;
  handleEditInputChange: (id: string, value: string) => void;
  handleTypeInputChange: (value: TSurveyQuestionType) => void;
  saveEditSurveyQuestion: () => void;
}

const SurveyForm: React.FC<SurveyFormProps> = ({
  io,
  buttonDisabled,
  edit,
  handleEditInputChange,
  handleTypeInputChange,
  saveEditSurveyQuestion,
}) => {
  const contentToString = (content: string): string =>
    JSON.parse(content).join('\n');
  const stringToContent = (content: string): string =>
    JSON.stringify(content.split('\n'));

  const [question, setQuestion] = React.useState('');
  const [type, setType] = React.useState<TSurveyQuestionType>('text');
  const [content, setContent] = React.useState('[]');

  const reset = () => {
    setQuestion('');
    setType('text');
    setContent('');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    io.emit('addSurveyQuestion', question, type, content);

    reset();
  };

  const textChecked = (t: TSurveyQuestionType) =>
    edit ? edit.type === t : type === t;

  const types: TSurveyQuestionType[] = [
    'text',
    'rank5',
    'rank10',
    'multiplechoice',
  ];

  return (
    <div className="card shadow mb-4">
      <a
        href="#addSurveyQuestion"
        className={`d-block card-header py-3${edit ? '' : ' collapsed'}`}
        data-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="addSurveyQuestion"
      >
        <h6 className="m-0 font-weight-bold text-primary">
          Enquêtevraag {edit ? 'bewerken' : 'toevoegen'}{' '}
          {!edit && (
            <small className="text-muted font-italic">
              Klik om toe te voegen
            </small>
          )}
        </h6>
      </a>
      <div className={`collapse${edit ? ' show' : ''}`} id="addSurveyQuestion">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group row">
              <label htmlFor="question" className="col-sm-2">
                Vraag
              </label>
              <div className="col-sm-10">
                <input
                  className="form-control"
                  id="question"
                  name="question"
                  value={edit?.question ?? question}
                  onChange={({ currentTarget: { value } }) =>
                    edit
                      ? handleEditInputChange('question', value)
                      : setQuestion(value)
                  }
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="withChat" className="col-sm-2">
                Type
              </label>
              <div className="col-sm-10">
                <ButtonGroup toggle defaultValue={edit ? edit.type : type}>
                  {types.map((t) => (
                    <ToggleButton
                      key={t}
                      type="radio"
                      variant="secondary"
                      name="radio"
                      value={t}
                      checked={textChecked(t)}
                      onChange={() =>
                        edit ? handleTypeInputChange(t) : setType(t)
                      }
                    >
                      {typeName(t)}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </div>
            </div>
            {(edit?.type ?? type) === 'multiplechoice' && (
              <div className="form-group row">
                <label htmlFor="withChat" className="col-sm-2">
                  Antwoorden
                  <br />
                  <small>
                    {JSON.parse(content).filter((i: string) => !!i).length}{' '}
                    totaal
                  </small>
                </label>
                <div className="col-sm-10">
                  <textarea
                    className="form-control"
                    id="answers"
                    name="answers"
                    value={contentToString(edit?.content ?? content)}
                    onChange={({ currentTarget: { value } }) =>
                      edit
                        ? handleEditInputChange(
                            'content',
                            stringToContent(value),
                          )
                        : setContent(stringToContent(value))
                    }
                    placeholder="Zet ieder antwoord op een nieuwe regel"
                    rows={5}
                  />
                </div>
              </div>
            )}
            <div className="form-group">
              {edit ? (
                <>
                  <button
                    type="button"
                    className="btn btn-success mr-2"
                    disabled={!edit.question || buttonDisabled}
                    onClick={saveEditSurveyQuestion}
                  >
                    Opslaan
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    disabled={!question || buttonDisabled}
                  >
                    Toevoegen
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => reset()}
                    disabled={!question}
                  >
                    Reset
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SurveyForm;
