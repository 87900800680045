import jwtDecode from 'jwt-decode';

import { IJWTContent } from '../interfaces/IUser';
import { TRoles } from '../types/TAuth';

import History from '../utilities/History';

import HttpService from './HttpService';
import WebinarService from './WebinarService';

export default class AuthService {
  static getToken(): string | null {
    return localStorage.getItem('token');
  }

  static isAuthenticated(): boolean {
    const token = AuthService.getToken();
    if (!token) return false;

    try {
      const details: IJWTContent = jwtDecode(token);
      if (details.expires < Math.floor(new Date().getTime() / 1000))
        return false;
      return true;
    } catch (e) {
      return false;
    }
  }

  static getUserDetails(): IJWTContent | undefined {
    const token = localStorage.getItem('token');

    if (token) return jwtDecode(token) as IJWTContent;
    return undefined;
  }

  static async login({
    email,
    password,
    totp,
  }: {
    email: string;
    password: string;
    totp: string;
  }): Promise<void> {
    const {
      data: { token },
    } = await HttpService.post('/auth/login', { email, password, totp });

    localStorage.setItem('token', token);
  }

  static logout(): void {
    WebinarService.removeActiveWebinar();
    localStorage.removeItem('token');

    History.push('/');
  }

  static hasRole(role: TRoles | TRoles[]): boolean {
    const roles = AuthService.getUserDetails()?.roles;

    if (!roles) return false;

    if (Array.isArray(role)) return roles.some((r) => role.includes(r));
    if (roles.includes(role)) return true;

    return false;
  }
}
