import * as React from 'react';
import { HeaderType } from 'react-bs-datatable/lib/helpers/types';
import { toast } from 'react-toastify';

import { IRegistration } from '../../../../interfaces/IRegistration';
import { IBreakoutSessionForLinking } from '../../../../interfaces/IBreakoutSession';

import HttpService from '../../../../services/HttpService';
import LinkBreakoutSessionsModal from './LinkBreakoutSessionsModal';

import RegistrationsTable from './RegistrationsTable';
import RegistrationsTableHeader from './RegistrationsTableHeader';

export interface RegistrationsProps {
  activeWebinarId: number;
}

export interface RegistrationsState {
  registrations?: IRegistration[];
  checkedRegistrations: number[];
  breakoutSessions?: IBreakoutSessionForLinking[];
  showLinkModal: boolean;
}

class Registrations extends React.Component<
  RegistrationsProps,
  RegistrationsState
> {
  private headers: HeaderType[] = [];

  constructor(props: RegistrationsProps) {
    super(props);

    this.state = {
      registrations: undefined,
      checkedRegistrations: [],
      breakoutSessions: undefined,
      showLinkModal: false,
    };
  }

  componentDidMount() {
    this.getRegistrationsData();
  }

  componentDidUpdate({
    activeWebinarId: prevActiveWebinarId,
  }: RegistrationsProps) {
    const { activeWebinarId } = this.props;

    if (activeWebinarId !== prevActiveWebinarId) this.getRegistrationsData();
  }

  getRegistrationsData = async (): Promise<void> => {
    const { activeWebinarId: webinarId } = this.props;

    this.setState(
      {
        registrations: undefined,
        breakoutSessions: undefined,
        checkedRegistrations: [],
      },
      async () => {
        try {
          const {
            data: registrations,
          }: {
            data: IRegistration[];
          } = await HttpService.get('/registration/all', {
            params: { webinarId },
          });

          setTimeout(() => this.setState({ registrations }), 500);
        } catch (e) {
          if (e.response?.status === 404)
            toast.error(
              `Het webinar met ID ${webinarId} kan niet worden gevonden`,
            );
          else toast.error('Er is iets mis gegaan');
        }
      },
    );
  };

  getBreakoutSessionsData = async (): Promise<void> => {
    const { activeWebinarId: webinarId } = this.props;

    this.setState(
      {
        breakoutSessions: undefined,
      },
      async () => {
        try {
          const {
            data: breakoutSessions,
          }: {
            data: IBreakoutSessionForLinking[];
          } = await HttpService.get('/breakout-session/for-linking', {
            params: { webinarId },
          });

          this.setState({ breakoutSessions });
        } catch (e) {
          if (e.response?.status === 404)
            toast.error(
              `Het webinar met ID ${webinarId} kan niet worden gevonden`,
            );
          else toast.error('Er is iets mis gegaan');
        }
      },
    );
  };

  checkRegistration = (id: number, checked: boolean): void => {
    const { checkedRegistrations } = this.state;

    if (checked && !checkedRegistrations.includes(id))
      checkedRegistrations.push(id);
    else if (!checked && checkedRegistrations.includes(id))
      checkedRegistrations.splice(checkedRegistrations.indexOf(id), 1);

    this.setState({ checkedRegistrations });
  };

  checkAll = (_id: number, checked: boolean): void => {
    const { registrations = [] } = this.state;
    let checkedRegistrations: number[] = [];

    if (checked)
      checkedRegistrations = registrations.reduce(
        (p, c) => p.concat(c.id),
        [] as number[],
      );

    this.setState({ checkedRegistrations });
  };

  linkModalState = (state?: boolean): void =>
    this.setState(({ showLinkModal }) => ({
      showLinkModal: state ?? !showLinkModal,
    }));

  handleModalClose = () => {
    this.linkModalState(false);
    this.getRegistrationsData();
  };

  render() {
    const {
      registrations,
      checkedRegistrations,
      breakoutSessions,
      showLinkModal,
    } = this.state;

    return (
      <>
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800 font-weight-bold">
            Registraties
          </h1>

          <LinkBreakoutSessionsModal
            show={showLinkModal}
            handleClose={this.handleModalClose}
            registrations={checkedRegistrations}
            breakoutSessions={breakoutSessions}
          />

          <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <RegistrationsTableHeader
                registrations={registrations}
                checkedRegistrations={checkedRegistrations}
                getRegistrations={this.getRegistrationsData}
                openLinkModal={() => {
                  this.getBreakoutSessionsData();
                  this.linkModalState(true);
                }}
              />
            </div>
            <div className="card-body">
              <RegistrationsTable
                registrations={registrations}
                checkedRegistrations={checkedRegistrations}
                checkRegistration={this.checkRegistration}
                checkAll={this.checkAll}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Registrations;
