/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import config from '../../../config';

import { IStatement } from '../../../interfaces/IStatement';

import AuthService from '../../../services/AuthService';

import AnswerInput from './AnswerInput';

export interface StatementCardProps {
  statement: IStatement;
  activeStatementId: number | undefined;
  locked: boolean;
  graphicsConnected: boolean;
  setStatementGraphics: (
    activeStatementId: number,
    active: boolean,
    fullscreen?: boolean,
  ) => void;
  onEdit: (statementId: number, locked: boolean) => void;
  onSave: (statement: IStatement) => void;
  onDelete: (id: IStatement['id']) => void;
  handleStatementActive: (id: number, active: boolean) => void;
}

export interface StatementCardState {
  selfStatement: IStatement;
  edit: boolean;
}

class StatementCard extends React.Component<
  StatementCardProps,
  StatementCardState
> {
  constructor(props: StatementCardProps) {
    super(props);

    const { statement } = this.props;

    this.state = {
      selfStatement: { ...statement },
      edit: false,
    };
  }

  answerColor = (key: number): string => {
    if (key === 0) return 'success';
    if (key === 1) return 'warning';
    if (key === 2) return 'danger';
    if (key === 3) return 'info';
    if (key === 4) return 'dark';
    return 'primary';
  };

  delete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const {
      onDelete,
      statement: { id },
    } = this.props;

    onDelete(id);
  };

  save = () =>
    this.setState(
      ({ edit }) => {
        const {
          onEdit,
          statement: { id },
        } = this.props;

        onEdit(id, !edit);

        return { edit: !edit };
      },
      () => {
        const { selfStatement, edit } = this.state;
        const { onSave } = this.props;

        if (!edit) onSave(selfStatement);
      },
    );

  handleStatementChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
    this.setState(({ selfStatement }) => ({
      selfStatement: { ...selfStatement, statement: value },
    }));

  handleAnswerChange = ({
    currentTarget: { name, value },
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const {
      selfStatement: { answers },
    } = this.state;
    const answerIndex = parseInt(name.replace('answer', ''), 10);

    if (!value && typeof answers[answerIndex] !== 'undefined')
      answers.splice(answerIndex);
    else if (value) answers[answerIndex] = value;

    this.setState(({ selfStatement }) => ({
      selfStatement: { ...selfStatement, answers },
    }));
  };

  render() {
    const {
      statement: liveStatement,
      locked,
      setStatementGraphics,
      activeStatementId,
      handleStatementActive,
      graphicsConnected,
    } = this.props;
    const { edit, selfStatement } = this.state;

    const s = edit ? selfStatement : liveStatement;
    const { id, active, statement, answers, votes } = s;

    const totalVotes = votes.reduce((p, c) => p + c, 0);

    let borderColor = 'primary';
    if (active) borderColor = 'danger';
    else if (locked || edit) borderColor = 'secondary';

    return (
      <div className="col-4">
        <div className={`card shadow mb-4 border-left-${borderColor}`}>
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">
              <button
                type="button"
                className={`btn btn-${
                  active ? 'danger' : 'success'
                } btn-sm mr-2`}
                onClick={(e) => {
                  e.preventDefault();
                  handleStatementActive(id, !active);
                }}
                disabled={
                  locked ||
                  edit ||
                  (activeStatementId !== undefined && activeStatementId !== id)
                }
              >
                <i className="fas fa-broadcast-tower mr-2" />
                Maak {active && 'in'}actief
              </button>
              <span className="float-right">
                {AuthService.hasRole(config.roles.graphics) && (
                  <>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm mr-1"
                      onClick={() => setStatementGraphics(id, true, false)}
                      disabled={locked || edit || !graphicsConnected}
                    >
                      <i className="fas fa-compress mr-1" />
                      Klein
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm mr-1"
                      onClick={() => setStatementGraphics(id, true, true)}
                      disabled={locked || edit || !graphicsConnected}
                    >
                      <i className="fas fa-compress mr-1" />
                      Groot
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm mr-3"
                      onClick={() => setStatementGraphics(1, false, false)}
                      disabled={locked || edit || !graphicsConnected}
                    >
                      <i className="fas fa-times mr-1" />
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  className="btn btn-info btn-sm mr-1"
                  onClick={this.save}
                  disabled={
                    active ||
                    locked ||
                    (activeStatementId !== undefined &&
                      activeStatementId !== id)
                  }
                >
                  <i className={`fas fa-fw fa-${edit ? 'save' : 'edit'}`} />
                </button>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={this.delete}
                  disabled={
                    locked ||
                    edit ||
                    active ||
                    (activeStatementId !== undefined &&
                      activeStatementId !== id)
                  }
                >
                  <i className="fas fa-trash" />
                </button>
              </span>
            </h6>
          </div>
          <div className="card-body">
            {edit ? (
              <>
                <div className="form-group">
                  <label style={{ width: '100%' }} htmlFor="statement">
                    Stelling
                    <input
                      className="form-control"
                      id="statement"
                      name="statement"
                      value={statement}
                      onChange={this.handleStatementChange}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <label style={{ width: '100%' }} htmlFor="reviewedQuestion">
                    Antwoorden
                    <AnswerInput
                      handleInputChange={this.handleAnswerChange}
                      name="answer0"
                      value={answers[0]}
                      placeholder="Antwoord 1"
                      required
                    />
                    <AnswerInput
                      handleInputChange={this.handleAnswerChange}
                      name="answer1"
                      value={answers[1]}
                      placeholder="Antwoord 2"
                      required
                    />
                    {answers[1] && (
                      <AnswerInput
                        handleInputChange={this.handleAnswerChange}
                        name="answer2"
                        value={answers[2]}
                        placeholder="Antwoord 3"
                      />
                    )}
                    {answers[2] && (
                      <AnswerInput
                        handleInputChange={this.handleAnswerChange}
                        name="answer3"
                        value={answers[3]}
                        placeholder="Antwoord 4"
                      />
                    )}
                    {answers[3] && (
                      <AnswerInput
                        handleInputChange={this.handleAnswerChange}
                        name="answer4"
                        value={answers[4]}
                        placeholder="Antwoord 5"
                      />
                    )}
                    {answers[4] && (
                      <AnswerInput
                        handleInputChange={this.handleAnswerChange}
                        name="answer5"
                        value={answers[5]}
                        placeholder="Antwoord 6"
                      />
                    )}
                  </label>
                </div>
              </>
            ) : (
              <>
                <p>
                  <span className="text-gray-800 text-lg border-left-info pl-2 font-italic">
                    {statement}
                  </span>
                </p>
                <hr />
                <p>
                  <span className="text-gray-600">
                    {totalVotes} keer gestemd
                  </span>
                </p>
                {answers
                  // .sort((a, b) => b.votes - a.votes)
                  .map((answer, key) => {
                    const percentage =
                      Math.round((votes[key] / totalVotes) * 100) || 0;

                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={key}>
                        <div className="row">
                          <div className="col-10">
                            <h4 className="small font-weight-bold">
                              {answer}{' '}
                            </h4>
                          </div>
                          <div className="col-2 text-right">
                            <span className="small">
                              {votes[key]} ({percentage}%)
                            </span>
                          </div>
                        </div>
                        <div className="progress mb-4">
                          <div
                            className={`progress-bar bg-${this.answerColor(
                              key,
                            )}`}
                            role="progressbar"
                            style={{ width: `${percentage}%` }}
                            aria-valuenow={percentage}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default StatementCard;
