import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import config from '../../../config';

import { IBreakoutSession } from '../../../interfaces/IBreakoutSession';

export interface BreakoutSessionHostModalProps {
  hostBreakoutSession?: IBreakoutSession;
}

const BreakoutSessionHostModal: React.SFC<BreakoutSessionHostModalProps> = ({
  hostBreakoutSession,
}) => {
  const {
    webinar: { endpoint },
  } = config;
  const hostUrl = `${endpoint}/host/${hostBreakoutSession?.identifier}/${hostBreakoutSession?.hostToken}`;

  const [copied, setCopied] = React.useState(false);

  return (
    <div id="modal-breakout-session-host" className="modal fade" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Breakout-sessie Host URL voor{' '}
              <span className="font-weight-bold">
                {hostBreakoutSession?.name}
              </span>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ReactTooltip place="top" type="dark" effect="float">
              {copied ? 'Gekopiëerd' : 'Klik om te kopiëren'}
            </ReactTooltip>
            <textarea
              className="form-control"
              id="streamServer"
              value={hostUrl}
              onClick={(e) => {
                e.currentTarget.select();
                document.execCommand('copy');

                setCopied(true);
                setTimeout(() => setCopied(false), 1000);
              }}
              rows={2}
              readOnly
              data-tip
            />
            <br />
          </div>
          <div className="modal-footer">
            <a
              className="btn btn-link mr-auto"
              href={hostUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              Open in een nieuw tabblad
            </a>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
            >
              Sluiten
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakoutSessionHostModal;
