import * as React from 'react';
import { toast } from 'react-toastify';
import slugify from 'slugify';
import moment from 'moment';

import { IWebinar } from '../../../interfaces/IWebinar';

import HttpService from '../../../services/HttpService';

import FormCheck from './FormCheck';

export interface ReportsProps {
  activeWebinar?: IWebinar;
}

const Reports: React.FC<ReportsProps> = ({ activeWebinar }) => {
  const [disableRegistrations, setDisableRegistrations] = React.useState(false);

  const [registrations, setRegistrations] = React.useState(true);
  const [deviceInfo, setDeviceInfo] = React.useState(true);
  const [questions, setQuestions] = React.useState(true);
  const [statements, setStatements] = React.useState(true);
  const [viewerstats, setViewerstats] = React.useState(true);
  const [survey, setSurvey] = React.useState(true);

  const [requesting, setRequesting] = React.useState(false);

  const exportReport = async () => {
    try {
      if (!activeWebinar)
        return toast.error(
          'Er is iets mis gegaan. Ververs de pagina en probeer het opnieuw',
        );

      const content = [] as string[];

      if (registrations) {
        content.push('registrations');

        if (deviceInfo) content.push('deviceInfo');
      }
      if (questions) content.push('questions');
      if (statements) content.push('statements');
      if (viewerstats) content.push('viewerstats');
      if (survey) content.push('survey');

      if (content.length === 0)
        return toast.warn('Selecteer ten minste één onderdeel');

      setRequesting(true);

      const { data } = await HttpService.get('/report/download/excel', {
        params: { webinarId: activeWebinar.id, content },
        responseType: 'blob',
      });

      const now = moment();
      const filename = slugify(
        `${activeWebinar.name.trim()}_${now.format('YYYY-MM-DD')}_${now.format(
          'HH-mm-ss',
        )}`,
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${filename}.xlsx`);

      document.body.appendChild(link);

      link.click();

      setTimeout(() => setRequesting(false), 500);

      return undefined;
    } catch (e) {
      setTimeout(() => setRequesting(false), 1000);

      toast.error('Er is iets mis gegaan. Probeer het later opnieuw');

      return undefined;
    }
  };

  React.useEffect(() => {
    if (
      activeWebinar &&
      activeWebinar?.disableRegistrations &&
      !activeWebinar?.isBullsAndBears
    ) {
      setDisableRegistrations(true);
      setRegistrations(false);
    } else if (
      activeWebinar &&
      activeWebinar.disableRegistrations &&
      !activeWebinar.isBullsAndBears
    )
      setDisableRegistrations(false);
  }, [activeWebinar]);

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">Rapportage</h1>

      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Exporteren</h6>
            </div>
            <div className="card-body">
              Selecteer de onderdelen om te exporteren
              <hr />
              <FormCheck
                id="registrations"
                checked={registrations}
                onChange={(c) => setRegistrations(c)}
                text="Aanmeldingen"
                disabled={disableRegistrations}
              />
              <FormCheck
                className="ml-4"
                id="deviceInfo"
                checked={deviceInfo}
                onChange={(c) => setDeviceInfo(c)}
                text="Apparaatinfo"
                disabled={disableRegistrations || !registrations}
              />
              <FormCheck
                id="questions"
                checked={questions}
                onChange={(c) => setQuestions(c)}
                text="Vragen"
              />
              <FormCheck
                id="statements"
                checked={statements}
                onChange={(c) => setStatements(c)}
                text="Stellingen"
              />
              <FormCheck
                id="viewerstats"
                checked={viewerstats}
                onChange={(c) => setViewerstats(c)}
                text="Aantal kijkers"
              />
              <FormCheck
                id="survey"
                checked={survey}
                onChange={(c) => setSurvey(c)}
                text="Enquête"
              />
              {/* <FormCheck
                id="devicestats"
                checked={false}
                onChange={(c) => setRegistrations(c)}
                text="Device-statistieken"
                disabled
              /> */}
              <hr />
              <button
                type="button"
                className="btn btn-primary"
                disabled={requesting || !activeWebinar}
                onClick={() => exportReport()}
              >
                <i
                  className={`fas ${
                    requesting ? 'fa-spinner fa-spin' : 'fa-file-download'
                  } fa-fw mr-2`}
                />
                Exporteer Excel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
