import * as React from 'react';
import { Editor } from '@tinymce/tinymce-react';

export interface WysiwygEditorProps {
  id: string;
  value: string;
  height?: number;
  onChange: (id: string, value: string) => void;
}

const WysiwygEditor: React.SFC<WysiwygEditorProps> = ({
  id,
  value,
  height,
  onChange,
}) => {
  return (
    <Editor
      id={id}
      value={value}
      init={{
        height: height ?? 300,
        menubar: false,
        branding: false,
        toolbar: `formatselect | bold italic forecolor backcolor |
      alignleft aligncenter alignright alignjustify |
      bullist numlist outdent indent | link table image | removeformat | help`,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
      }}
      onEditorChange={(v) => onChange(id, v)}
    />
  );
};

export default WysiwygEditor;
