import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import 'moment/locale/nl';

import 'jquery/dist/jquery';
import 'jquery-easing/dist/jquery.easing.1.3.umd';
import 'bootstrap/dist/js/bootstrap.min';

import StyleLoader from './styles/StyleLoader';

import App from './App';

import History from './utilities/History';

ReactDOM.render(
  <StyleLoader>
    <React.StrictMode>
      <Router history={History}>
        <App />
      </Router>
    </React.StrictMode>
  </StyleLoader>,
  document.getElementById('root'),
);
