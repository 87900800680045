import * as React from 'react';

import { IWebinar } from '../../../interfaces/IWebinar';
import {
  IQuestion,
  TQuestionsOrder,
  TQuestionStage,
  TUpdatedQuestion,
} from '../../../interfaces/IQuestion';

import Question from './Question';
import Header from './Header';

export interface HostProps {
  io: SocketIOClient.Socket;
  connected: boolean;
  activeWebinar: IWebinar | undefined;
}

export interface HostState {
  questions: IQuestion[];
  questionsOrder: TQuestionsOrder;
  questionCount: number;
}

class Host extends React.Component<HostProps, HostState> {
  constructor(props: HostProps) {
    super(props);

    const questionCount = localStorage.getItem('hostQuestionCount');

    this.state = {
      questions: [],
      questionsOrder: {
        incoming: [],
        edit: [],
        approved: [],
      },
      questionCount: questionCount ? parseInt(questionCount, 10) : 2,
    };
  }

  componentDidMount() {
    document.body.classList.add('bg-black');

    const { io } = this.props;

    io.on(
      'questions',
      (questions: IQuestion[], questionsOrder: TQuestionsOrder) => {
        this.setState({
          questions,
          questionsOrder,
        });
      },
    );

    io.on('questionsOrder', (questionsOrder: TQuestionsOrder) =>
      this.setState({ questionsOrder }),
    );

    io.on('updateQuestion', this.onUpdateQuestion);
    io.on('liveQuestion', this.onLiveQuestion);
    io.on('deleteQuestion', this.onDeleteQuestion);

    setTimeout(() => io.emit('getData', 'questions'), 500);
  }

  componentWillUnmount() {
    document.body.classList.remove('bg-black');

    const { io } = this.props;

    io.off('questions');
    io.off('questionsOrder');
    io.off('updateQuestion');
    io.off('liveQuestion');
    io.off('deleteQuestion');
  }

  onUpdateQuestion = ({
    id,
    reviewedQuestion,
    remark,
  }: TUpdatedQuestion): void =>
    this.setState(({ questions }) => ({
      questions: questions.map((q) => {
        if (q.id === id) return { ...q, reviewedQuestion, remark };

        return q;
      }),
    }));

  onDeleteQuestion = (id: IQuestion['id']) => {
    this.setState(({ questions }) => ({
      questions: questions.filter((q) => q.id !== id),
    }));
  };

  onLiveQuestion = (id: IQuestion['id'], onAir: boolean): void => {
    this.setState(({ questions }) => ({
      questions: questions.map((q) => {
        if (q.id === id) return { ...q, onAir };
        return q;
      }),
    }));
  };

  handleQuestionCount = (way: 'up' | 'down') =>
    this.setState(({ questionCount: currentQuestionCount }) => {
      let questionCount = currentQuestionCount;

      if (way === 'up') questionCount += 1;
      else if (questionCount === 1) questionCount = 1;
      else questionCount -= 1;

      localStorage.setItem('hostQuestionCount', questionCount.toString());

      return { questionCount };
    });

  render() {
    const { activeWebinar, connected } = this.props;

    if (!activeWebinar) return null;

    const { name: webinarName } = activeWebinar;
    const { questions, questionsOrder, questionCount } = this.state;

    const stage: TQuestionStage = 'approved';
    const filteredQuestions = questions
      .filter((q) => questionsOrder.approved.includes(q.id))
      .sort(
        (a, b) =>
          questionsOrder.approved.indexOf(a.id) -
          questionsOrder.approved.indexOf(b.id),
      )
      .map((q, order) => ({ ...q, stage, order }))
      .slice(0, questionCount);

    return (
      <div className="p-4 text-white w-100 host-page">
        <Header
          title={webinarName}
          connected={connected}
          questionCount={questionCount}
          handleQuestionCount={this.handleQuestionCount}
        />
        <hr className="mb-4" style={{ borderTop: '1px solid gray' }} />
        <div className="row">
          {filteredQuestions.length > 0 ? (
            filteredQuestions.map((question) => (
              <Question question={question} key={question.id} />
            ))
          ) : (
            <div className="col-12">
              <h2 className="h2 font-weight-bold">Geen vragen beschikbaar</h2>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Host;
