import * as React from 'react';
import { toast } from 'react-toastify';

import HttpService from '../../../../services/HttpService';

import History from '../../../../utilities/History';

import PasswordInput from './PasswordInput';

export type UserPasswordProps = Record<string, unknown>;

export interface UserPasswordState {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  error?: string;
  totpQRCode?: string;
}

class UserPassword extends React.Component<
  UserPasswordProps,
  UserPasswordState
> {
  constructor(props: UserPasswordProps) {
    super(props);

    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { currentPassword, newPassword, confirmPassword } = this.state;

    if (newPassword !== confirmPassword)
      toast.error('Je nieuwe wachtwoord is niet gelijk aan de bevestiging');
    else {
      this.setState({ error: undefined });

      try {
        await HttpService.patch(`/user/password`, {
          currentPassword,
          newPassword,
        });

        toast.success('Je wachtwoord is succesvol opgeslagen');
        setTimeout(() => History.push('/'), 100);
      } catch (_e) {
        toast.error(
          'Er is iets fout gegaan. Check je huidige wachtwoord en probeer het opnieuw.',
        );
      }
    }
  };

  handleInputChange = ({
    currentTarget: { id, value },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { newPassword } = this.state;
    let error: string | undefined;

    if (id === 'confirmPassword' && newPassword !== value)
      error = 'De wachtwoorden zijn niet gelijk';

    this.setState((s) => ({ ...s, error, [id]: value }));
  };

  getTOTPQRCode = async (): Promise<void> => {
    try {
      const {
        data: { totpQRCode },
      }: { data: { totpQRCode: string } } = await HttpService.get(
        '/auth/totp-qr-code',
      );

      this.setState({ totpQRCode });
    } catch (e) {
      if (e.response?.status === 403)
        toast.error(
          'Je hebt niet de juiste rechten om deze pagina te bekijken. Log opnieuw in en probeer het nogmaals',
        );
      if (e.response?.status === 404)
        toast.error(
          'Tweestapsverificatie staat niet aan op je account. Vraag een beheerder om dit te activeren',
        );
    }
  };

  render() {
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      error,
      totpQRCode,
    } = this.state;

    return (
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800 font-weight-bold">
          Accountbeveiliging
        </h1>

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Wachtwoord
                </h6>
              </div>
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <PasswordInput
                      id="currentPassword"
                      value={currentPassword}
                      placeholder="Voer je huidige wachtwoord in"
                      onInputChange={this.handleInputChange}
                    />
                  </div>
                  <hr />
                  <div className="form-group">
                    <PasswordInput
                      id="newPassword"
                      value={newPassword}
                      placeholder="Voer je nieuwe wachtwoord in"
                      onInputChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <PasswordInput
                      id="confirmPassword"
                      value={confirmPassword}
                      placeholder="Bevestig je nieuwe wachtwoord"
                      onInputChange={this.handleInputChange}
                    />
                  </div>
                  {error && (
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      <strong>Oeps!</strong> {error}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  )}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={!!error}
                  >
                    Opslaan
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Authenticator QR-code
                </h6>
              </div>
              <div className="card-body">
                {totpQRCode ? (
                  <>
                    <img src={totpQRCode} alt="QR-code" />
                  </>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.getTOTPQRCode()}
                  >
                    <i className="fas fa-qrcode fa-fw mr-2" />
                    QR-code ophalen
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserPassword;
