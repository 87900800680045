import * as React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';

import { IBreakoutSession } from '../../../interfaces/IBreakoutSession';
import BreakoutSessionForm from './BreakoutSessionForm';

import BreakoutSessionsOptions from './BreakoutSessionsOptions';
import BreakoutSessionsTable from './BreakoutSessionsTable';

export interface BreakoutSessionsProps {
  io: SocketIOClient.Socket;
  connected: boolean;
}

export interface BreakoutSessionsState {
  breakoutSessions: IBreakoutSession[];
  breakoutSessionsActive: boolean;
  breakoutSessionsCountdown?: moment.Moment;
  edit?: IBreakoutSession;
}

class BreakoutSessions extends React.Component<
  BreakoutSessionsProps,
  BreakoutSessionsState
> {
  constructor(props: BreakoutSessionsProps) {
    super(props);

    this.state = {
      breakoutSessions: [],
      breakoutSessionsActive: false,
    };
  }

  componentDidMount() {
    const { io } = this.props;

    io.on('breakoutSessions', this.onBreakoutSessions);
    io.on('breakoutSessionsActive', this.onBreakoutSessionsActive);
    io.on('breakoutSessionActive', this.onBreakoutSessionActive);
    io.on('deleteBreakoutSession', this.onDeleteBreakoutSession);
    io.on('updateBreakoutSession', this.onUpdateBreakoutSession);
    io.on('breakoutSessionCountdown', this.onBreakoutSessionCountdown);

    io.emit('getData', 'breakoutSessions');
  }

  componentWillUnmount() {
    const { io } = this.props;

    io.off('breakoutSessions');
    io.off('breakoutSessionsActive');
    io.off('breakoutSessionActive');
    io.off('deleteBreakoutSession');
    io.off('updateBreakoutSession');
  }

  handleBreakoutSessionsActive = () => {
    const { io } = this.props;
    const { breakoutSessionsActive } = this.state;

    io.emit('breakoutSessionsActive', !breakoutSessionsActive);

    this.setState({ breakoutSessionsCountdown: undefined });
  };

  handleBreakoutSessionActive = (id: number, active: boolean) => {
    const { io } = this.props;

    io.emit('breakoutSessionActive', id, active);
  };

  handleSetBreakoutSessionsEnd = (offset?: number) => {
    const { io } = this.props;

    io.emit('setBreakoutSessionCountdown', offset);
  };

  handleDeleteBreakoutSession = async (id: number, name: string) => {
    const { io } = this.props;
    const ReactSwal = withReactContent(Swal);

    const { isConfirmed } = await ReactSwal.fire({
      icon: 'warning',
      html: <p>Weet je zeker dat je {name} wil verwijderen?</p>,
      showConfirmButton: true,
      confirmButtonText: 'Ja',
      confirmButtonColor: '#eeb244',
      showDenyButton: true,
      denyButtonText: 'Nee',
      denyButtonColor: '#aaa',
    });

    if (isConfirmed) {
      io.emit('deleteBreakoutSession', id);

      ReactSwal.fire({
        icon: 'success',
        title: <p>{name} is verwijderd</p>,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  handleEditBreakoutSession = (id?: number) =>
    this.setState(({ breakoutSessions }) => ({
      edit: breakoutSessions.find(({ id: bosId }) => bosId === id),
    }));

  onBreakoutSessions = (
    breakoutSessions: IBreakoutSession[],
    breakoutSessionsActive: boolean,
    endsIn?: number,
  ) => {
    this.setState({
      breakoutSessions,
      breakoutSessionsActive,
      breakoutSessionsCountdown: endsIn ? moment().add(endsIn, 's') : undefined,
    });
  };

  onBreakoutSessionCountdown = (offset?: number) =>
    this.setState({
      breakoutSessionsCountdown: offset ? moment().add(offset, 's') : undefined,
    });

  onBreakoutSessionsActive = (breakoutSessionsActive: boolean) =>
    this.setState({ breakoutSessionsActive, edit: undefined });

  onBreakoutSessionActive = (id: number, active: boolean) => {
    this.setState(({ breakoutSessions }) => ({
      breakoutSessions: breakoutSessions.map((bos) =>
        bos.id === id ? { ...bos, active } : bos,
      ),
    }));
  };

  onDeleteBreakoutSession = (id: number) =>
    this.setState(({ breakoutSessions }) => ({
      breakoutSessions: breakoutSessions.filter(
        ({ id: bosId }) => bosId !== id,
      ),
    }));

  onUpdateBreakoutSession = (
    id: number,
    name: string,
    description: string,
    withHost: boolean,
    withChat: boolean,
    limit: number,
  ) =>
    this.setState(({ breakoutSessions }) => ({
      breakoutSessions: breakoutSessions.map((bos) => {
        if (bos.id === id)
          return { ...bos, name, description, withHost, withChat, limit };

        return bos;
      }),
    }));

  handleEditInputChange = (id: string, value: string | number | boolean) => {
    const { edit } = this.state;

    if (edit) {
      const newEdit = { ...edit, [id]: value };

      this.setState({ edit: newEdit });
    }
  };

  saveEditBreakoutSession = () => {
    const { io } = this.props;
    const { edit } = this.state;

    if (edit) {
      const { id, name, description, withHost, withChat, limit } = edit;

      io.emit(
        'updateBreakoutSession',
        id,
        name,
        description,
        withHost,
        withChat,
        limit,
      );

      this.setState({ edit: undefined });
    }
  };

  render() {
    const { io, connected } = this.props;
    const {
      breakoutSessions,
      breakoutSessionsActive,
      breakoutSessionsCountdown,
      edit,
    } = this.state;

    return (
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800 font-weight-bold">
          Breakout-sessies
          <br />
          <small className=" h6 text-muted">
            Deze pagina accepteert enkel wijzigingen indien er geen
            breakout-sessies live staan
          </small>
        </h1>
        <div className="row">
          <div className="col-12 col-xl-6">
            <BreakoutSessionForm
              io={io}
              buttonDisabled={breakoutSessionsActive || !connected}
              edit={edit}
              handleEditInputChange={this.handleEditInputChange}
              saveEditBreakoutSession={this.saveEditBreakoutSession}
            />
          </div>
          <div className="col" />
          <div className="col-12 col-xl-4">
            <BreakoutSessionsOptions
              breakoutSessions={breakoutSessions}
              breakoutSessionsActive={breakoutSessionsActive}
              connected={connected}
              breakoutSessionsCountdown={breakoutSessionsCountdown}
              handleBreakoutSessionsActive={this.handleBreakoutSessionsActive}
              handleSetBreakoutSessionsEnd={this.handleSetBreakoutSessionsEnd}
            />
          </div>
        </div>
        <BreakoutSessionsTable
          breakoutSessions={breakoutSessions}
          buttonDisabled={breakoutSessionsActive || !connected}
          edit={edit}
          handleBreakoutSessionActive={this.handleBreakoutSessionActive}
          handleDeleteBreakoutSession={this.handleDeleteBreakoutSession}
          handleEditBreakoutSession={this.handleEditBreakoutSession}
        />
      </div>
    );
  }
}

export default BreakoutSessions;
