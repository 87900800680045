import * as React from 'react';
import { toast } from 'react-toastify';

import config from '../config';

import HttpService from '../services/HttpService';

const ChatControl: React.FC = () => {
  const { token } = config.api;

  const getChatActive = React.useCallback(
    async (stream: string): Promise<boolean> => {
      try {
        const {
          data: { active },
        }: { data: { active: boolean } } = await HttpService.get(
          `/chat/active?domain=quizzzit&stream=${stream}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        return active;
      } catch (e) {
        if (
          e.response?.status &&
          e.response?.status >= 400 &&
          e.response?.status < 500
        )
          toast.error(
            'Er is iets fout gegaan bij het ophalen van de chatstatus',
          );

        return false;
      }
    },
    [token],
  );

  const [chatOneActive, setChatOneActive] = React.useState(false);
  const [chatTwoActive, setChatTwoActive] = React.useState(false);
  const [chatThreeActive, setChatThreeActive] = React.useState(false);

  const check = React.useCallback(async () => {
    setChatOneActive(
      await getChatActive('0b54810c-3d8b-423c-8d67-5d698a46b71e'),
    );
    setChatTwoActive(
      await getChatActive('7653e4d8-ed79-4e53-bc60-d52c75785b78'),
    );
    setChatThreeActive(
      await getChatActive('84f18098-5825-4e1a-a058-85de7ffbcae1'),
    );
  }, [getChatActive]);

  const setChat = async (active: 'true' | 'false', stream: string) => {
    await HttpService.post(
      `/chat/active?domain=quizzzit&stream=${stream}&active=${active}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } },
    );

    check();
  };

  React.useEffect(() => {
    const interval = setInterval(() => check(), 2500);
    check();
    return () => clearInterval(interval);
  }, [check]);

  const ActiveBadge = ({ active }: { active: boolean }) => (
    <small>
      <span
        className={`badge badge-pill badge-${active ? 'success' : 'danger'}`}
      >
        {active ? 'Actief' : 'Niet actief'}
      </span>
    </small>
  );

  return (
    <div className="container vh-100">
      <div className="my-auto">
        <div className="row vh-100 text-center">
          <div className="col-12 col-lg-6 my-auto">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title font-weight-bold mb-4">
                  Quizzzit Stream 1
                  <br />
                  <ActiveBadge active={chatOneActive} />
                </h5>
                <button
                  type="button"
                  className="btn btn-success mr-4"
                  onClick={() =>
                    setChat('true', '0b54810c-3d8b-423c-8d67-5d698a46b71e')
                  }
                  disabled={chatOneActive}
                >
                  Activeer chat
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() =>
                    setChat('false', '0b54810c-3d8b-423c-8d67-5d698a46b71e')
                  }
                  disabled={!chatOneActive}
                >
                  Deactiveer chat
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 my-auto">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title font-weight-bold mb-4">
                  Quizzzit Stream 2
                  <br />
                  <ActiveBadge active={chatTwoActive} />
                </h5>
                <button
                  type="button"
                  className="btn btn-success mr-4"
                  onClick={() =>
                    setChat('true', '7653e4d8-ed79-4e53-bc60-d52c75785b78')
                  }
                  disabled={chatTwoActive}
                >
                  Activeer chat
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() =>
                    setChat('false', '7653e4d8-ed79-4e53-bc60-d52c75785b78')
                  }
                  disabled={!chatTwoActive}
                >
                  Deactiveer chat
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title font-weight-bold mb-4">
                  Quizzzit Stream 3
                  <br />
                  <ActiveBadge active={chatThreeActive} />
                </h5>
                <button
                  type="button"
                  className="btn btn-success mr-4"
                  onClick={() =>
                    setChat('true', '84f18098-5825-4e1a-a058-85de7ffbcae1')
                  }
                  disabled={chatThreeActive}
                >
                  Activeer chat
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() =>
                    setChat('false', '84f18098-5825-4e1a-a058-85de7ffbcae1')
                  }
                  disabled={!chatThreeActive}
                >
                  Deactiveer chat
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatControl;
