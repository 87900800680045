import * as React from 'react';

import { IBreakoutSession } from '../../../interfaces/IBreakoutSession';

export interface BreakoutSessionFormProps {
  io: SocketIOClient.Socket;
  buttonDisabled: boolean;
  edit?: IBreakoutSession;
  handleEditInputChange: (id: string, value: string | number | boolean) => void;
  saveEditBreakoutSession: () => void;
}

const BreakoutSessionForm: React.SFC<BreakoutSessionFormProps> = ({
  io,
  buttonDisabled,
  edit,
  handleEditInputChange,
  saveEditBreakoutSession,
}) => {
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [limit, setLimit] = React.useState(0);
  const [withHost, setWithHost] = React.useState(false);
  const [withChat, setWithChat] = React.useState(true);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    io.emit('addBreakoutSession', name, description, withHost, withChat, limit);

    setName('');
    setDescription('');
  };

  const reset = () => {
    setName('');
    setDescription('');
    setLimit(0);
  };

  return (
    <div className="card shadow mb-4">
      <a
        href="#addBreakoutSession"
        className={`d-block card-header py-3${edit ? '' : ' collapsed'}`}
        data-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="addBreakoutSession"
      >
        <h6 className="m-0 font-weight-bold text-primary">
          Breakout-sessie {edit ? 'bewerken' : 'toevoegen'}{' '}
          {!edit && (
            <small className="text-muted font-italic">
              Klik om toe te voegen
            </small>
          )}
        </h6>
      </a>
      <div className={`collapse${edit ? ' show' : ''}`} id="addBreakoutSession">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group row">
              <label htmlFor="name" className="col-sm-2">
                Naam
              </label>
              <div className="col-sm-10">
                <input
                  className="form-control"
                  id="name"
                  name="name"
                  value={edit?.name ?? name}
                  onChange={({ currentTarget: { id, value } }) =>
                    edit ? handleEditInputChange(id, value) : setName(value)
                  }
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="description" className="col-sm-2">
                Omschrijving
              </label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  rows={4}
                  id="description"
                  name="description"
                  value={edit?.description ?? description}
                  onChange={({ currentTarget: { id, value } }) =>
                    edit
                      ? handleEditInputChange(id, value)
                      : setDescription(value)
                  }
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="withHost" className="col-sm-2">
                Host
              </label>
              <div className="col-sm-10">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="withHost"
                    id="withHostOff"
                    value="off"
                    onChange={({ currentTarget: { checked } }) => {
                      if (checked === true)
                        if (edit) handleEditInputChange('withHost', false);
                        else setWithHost(false);
                    }}
                    checked={edit ? edit.withHost === false : !withHost}
                  />
                  <label className="form-check-label" htmlFor="withHostOff">
                    Zonder host
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="withHost"
                    id="withHostOn"
                    value="on"
                    onChange={({ currentTarget: { checked } }) => {
                      if (checked === true)
                        if (edit) handleEditInputChange('withHost', true);
                        else setWithHost(true);
                    }}
                    checked={edit ? edit.withHost === true : withHost}
                  />
                  <label className="form-check-label" htmlFor="withHostOn">
                    Met host
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="withChat" className="col-sm-2">
                Chat
              </label>
              <div className="col-sm-10">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="withChat"
                    id="withChatOff"
                    value="off"
                    onChange={({ currentTarget: { checked } }) => {
                      if (checked === true)
                        if (edit) handleEditInputChange('withChat', true);
                        else setWithChat(true);
                    }}
                    checked={edit ? edit.withChat === true : withChat}
                  />
                  <label className="form-check-label" htmlFor="withChatOff">
                    Aan
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="withChat"
                    id="withChatOn"
                    value="on"
                    onChange={({ currentTarget: { checked } }) => {
                      if (checked === true)
                        if (edit) handleEditInputChange('withChat', false);
                        else setWithChat(false);
                    }}
                    checked={edit ? edit.withChat === false : !withChat}
                  />
                  <label className="form-check-label" htmlFor="withChatOn">
                    Uit
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="limit" className="col-sm-2">
                Max. deelnemers
                <small id="limitHelp" className="form-text text-muted">
                  0 = geen limiet
                </small>{' '}
              </label>
              <div className="col-sm-2">
                <input
                  className="form-control"
                  type="number"
                  id="limit"
                  name="limit"
                  placeholder="Limiet"
                  min={0}
                  value={edit?.limit ?? limit}
                  onChange={({ currentTarget: { id, value } }) =>
                    edit
                      ? handleEditInputChange(id, parseInt(value, 10))
                      : setLimit(parseInt(value, 10))
                  }
                />
              </div>
            </div>
            <div className="form-group">
              {edit ? (
                <>
                  <button
                    type="button"
                    className="btn btn-success mr-2"
                    disabled={!edit.name || buttonDisabled}
                    onClick={saveEditBreakoutSession}
                  >
                    Opslaan
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    disabled={!name || buttonDisabled}
                  >
                    Toevoegen
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => reset()}
                    disabled={!name && !description}
                  >
                    Reset
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BreakoutSessionForm;
