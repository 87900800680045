import * as React from 'react';

export interface VariabelePickerProps {
  value: string;
  example: string;
}

const VariabelePicker: React.FC<VariabelePickerProps> = ({
  value,
  example,
}) => (
  <div className="form-group mb-2 mr-3">
    <input
      type="text"
      className="form-control-plaintext text-center border border-secondary"
      value={value}
      data-tip={`Voorbeeld: ${example}`}
      readOnly
      onClick={(e) => {
        e.currentTarget.select();
        document.execCommand('copy');
      }}
    />
  </div>
);

export default VariabelePicker;
