import * as React from 'react';

export interface RowCheckProps {
  className?: string;
  id: number;
  checked: boolean;
  disabled?: boolean;
  onChange: (id: number, checked: boolean) => void;
}

const RowCheck: React.FC<RowCheckProps> = ({
  className,
  id,
  checked,
  disabled = false,
  onChange,
}) => {
  return (
    <div className="w-100 text-center">
      <button
        type="button"
        className={`btn btn-link m-0 p-0 ${className}`.trim()}
        onClick={() => onChange(id, !checked)}
        disabled={disabled}
      >
        <i
          className={`${
            checked ? 'text-primary fas fa-check-' : 'text-secondary far fa-'
          }square fa-fw`}
          style={{ fontSize: '1.25rem' }}
        />
      </button>
    </div>
  );
};

export default RowCheck;
