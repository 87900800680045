import * as React from 'react';
import _ from 'lodash';

export interface RegistrationsSelectHeadersProps {
  className?: string;
  headers: string[];
  onSelectedHeaders: (
    selectedHeaders: { field: string; value: string }[],
  ) => void;
}

export const registrationsFields = {
  firstname: 'Voornaam',
  lastname: 'Achternaam',
  email: 'E-mailadres',
  phone: 'Telefoonnummer',
  company: 'Bedrijf',
  custom1: 'Dynamisch veld 1',
  custom2: 'Dynamisch veld 2',
  custom3: 'Dynamisch veld 3',
};

const RegistrationsSelectHeaders: React.FC<RegistrationsSelectHeadersProps> = ({
  className,
  headers,
  onSelectedHeaders,
}) => {
  const [selectedHeaders, setSelectedHeaders] = React.useState<
    { field: string; value: string }[]
  >([]);

  const select = (field: string, value: string) => {
    let result = [...selectedHeaders];
    const index = selectedHeaders.findIndex((v) => v.field === field);

    if (index > -1) {
      if (value === '-') result.splice(index, 1);
      else result[index] = { field, value };
    } else result = selectedHeaders.concat({ field, value });

    setSelectedHeaders(result);
    onSelectedHeaders(result);
  };

  const item = (id: string, value: string) => (
    <div className="form-group row">
      <div className="col-3">
        <label htmlFor={`select-${id}`}>{value}</label>
      </div>
      <div className="col-9">
        <select
          className="form-control"
          id={`select-${id}`}
          onChange={({ currentTarget: { value: v } }) => select(id, v)}
        >
          <option>-</option>
          {headers.map((v, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <option value={v} key={i}>
              {v}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  return (
    <div className={className ?? ''}>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Velden koppelen</h6>
        </div>
        <div className="card-body">
          {_.map(registrationsFields, (v, f) => (
            <div key={f}>{item(f, v)}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RegistrationsSelectHeaders;
