export enum EWebinarState {
  pre = 'pre',
  live = 'live',
  post = 'post',
}

export enum EWebinarVideoDelay {
  low = 'low',
  medium = 'medium',
}
