import * as React from 'react';

export interface ChatProps {
  io: SocketIOClient.Socket;
  connected: boolean;
}

export interface ChatState {
  chatActive?: boolean;
}

class Chat extends React.Component<ChatProps, ChatState> {
  constructor(props: ChatProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { io } = this.props;

    io.on('chatActive', this.onChatActive);

    io.emit('getData', 'chat');
  }

  componentWillUnmount() {
    const { io } = this.props;

    io.off('chatActive');
  }

  onChatActive = (chatActive: boolean) =>
    setTimeout(() => this.setState({ chatActive }), 0);

  chatActive = (active: boolean) => {
    const { io } = this.props;

    io.emit('chatActive', active);
  };

  render() {
    const { chatActive } = this.state;
    const { connected } = this.props;

    const ChatButton = ({
      active = false,
      disabled = false,
      className,
    }: {
      active?: boolean;
      disabled?: boolean;
      className?: string;
    }) => (
      <button
        type="button"
        className={`btn btn-${
          active ? 'success' : 'danger'
        } btn-icon-split btn-lg${className ? ` ${className}` : ''} mb-2`}
        onClick={() => this.chatActive(active)}
        disabled={disabled}
      >
        <span className="icon text-white-75">
          <i
            className={`fas fa-${active ? 'comment-dots' : 'comment-slash'}`}
          />
        </span>
        <span className="text">{active ? 'A' : 'Dea'}ctiveer chat</span>
      </button>
    );

    return (
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800 font-weight-bold">Chat</h1>

        <div className="row mb-3">
          <div className="col-12 col-md-6 text-center">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Bediening</h6>
              </div>
              <div className="card-body">
                <ChatButton
                  className="mx-3"
                  active
                  disabled={(chatActive ?? true) || !connected}
                />
                <ChatButton disabled={(!chatActive ?? true) || !connected} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Chat;
