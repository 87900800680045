import * as React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl';

export interface DateTimeRangePickerProps {
  id: string;
  className?: string;
  value: Date;
  onChange: (id: string, date: Date) => void;
}

const DateTimeRangePicker: React.SFC<DateTimeRangePickerProps> = ({
  id,
  className,
  value,
  onChange,
}) => {
  registerLocale('nl', nl);

  return (
    <DatePicker
      id={id}
      className={className}
      selected={value}
      locale="nl"
      showTimeSelect
      timeFormat="p"
      timeIntervals={15}
      dateFormat="Pp"
      onChange={(date) => onChange(id, date as Date)}
    />
  );
};

export default DateTimeRangePicker;
