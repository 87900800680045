import * as React from 'react';

import { EWebinarState } from '../../../enums/EWebinar';
import { IWebinar } from '../../../interfaces/IWebinar';

export interface AdminToolsProps {
  io: SocketIOClient.Socket;
  connected: boolean;
  activeWebinar: IWebinar | undefined;
}

export type AdminToolsState = Record<string, unknown>;

class AdminTools extends React.Component<AdminToolsProps, AdminToolsState> {
  refreshViewerPages = () => {
    const { io } = this.props;

    io.emit('refreshViewerPages');
  };

  updateWebinarState = (state: EWebinarState) => {
    const { io } = this.props;

    io.emit('updateWebinarState', state);
  };

  render() {
    const { activeWebinar } = this.props;

    return (
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800 font-weight-bold">Admin-tools</h1>

        <div className="row">
          <div className="col-12 col-md-5">
            <div className="alert alert-danger" role="alert">
              <i className="fas fa-exclamation-circle" />{' '}
              <span className="font-weight-bold">Pas op!</span> Dit zijn
              krachtige tools. Gebruik ze alleen als je weet wat je doet.
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12 col-md-6 text-center">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Status van het webinar
                </h6>
              </div>
              <div className="card-body">
                <button
                  type="button"
                  className={`btn btn-${
                    activeWebinar?.state === EWebinarState.pre
                      ? 'success'
                      : 'secondary'
                  } btn-icon-split btn-lg mr-3`}
                  onClick={() => this.updateWebinarState(EWebinarState.pre)}
                >
                  <span className="icon text-white-50">
                    <i className="fas fa-sync" />
                  </span>
                  <span className="text">Pre-webinar</span>
                </button>
                <button
                  type="button"
                  className={`btn btn-${
                    activeWebinar?.state === EWebinarState.live
                      ? 'danger'
                      : 'secondary'
                  } btn-icon-split btn-lg mr-3`}
                  onClick={() => this.updateWebinarState(EWebinarState.live)}
                >
                  <span className="icon text-white-50">
                    <i className="fas fa-sync" />
                  </span>
                  <span className="text">Live</span>
                </button>
                <button
                  type="button"
                  className={`btn btn-${
                    activeWebinar?.state === EWebinarState.post
                      ? 'success'
                      : 'secondary'
                  } btn-icon-split btn-lg mr-3`}
                  onClick={() => this.updateWebinarState(EWebinarState.post)}
                >
                  <span className="icon text-white-50">
                    <i className="fas fa-sync" />
                  </span>
                  <span className="text">Post-webinar</span>
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 text-center">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Noodknoppen
                </h6>
              </div>
              <div className="card-body">
                <button
                  type="button"
                  className="btn btn-primary btn-icon-split btn-lg"
                  onClick={this.refreshViewerPages}
                >
                  <span className="icon text-white-50">
                    <i className="fas fa-sync" />
                  </span>
                  <span className="text">Refresh viewer pages</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminTools;
