import * as React from 'react';
import { toast } from 'react-toastify';

import { IGraphicsClient } from '../../../interfaces/IGraphicsClient';

export interface GraphicsProps {
  io: SocketIOClient.Socket;
  connected: boolean;
  activeWebinarId?: number;
}

export interface GraphicsState {
  graphicsClients: IGraphicsClient[];
}

class Graphics extends React.Component<GraphicsProps, GraphicsState> {
  constructor(props: GraphicsProps) {
    super(props);

    this.state = { graphicsClients: [] };
  }

  componentDidMount() {
    const { io } = this.props;

    io.on('graphicsClients', (graphicsClients: IGraphicsClient[]) =>
      this.setState({ graphicsClients }),
    );

    io.on(
      'graphicsClientRegistration',
      (identifier: string, connected: boolean) =>
        this.setGraphicsClientConnected(identifier, connected),
    );

    io.emit('getData', 'graphicsClients');
  }

  componentWillUnmount() {
    const { io } = this.props;

    io.off('graphicsClients');
  }

  setGraphicsClientConnected = (identifier: string, connected: boolean): void =>
    this.setState((s) => ({
      ...s,
      graphicsClients: s.graphicsClients.map((graphicsClient) => {
        if (graphicsClient.identifier === identifier) {
          const updatedGraphicsClient = { ...graphicsClient };
          updatedGraphicsClient.connected = connected;
          return updatedGraphicsClient;
        }

        return graphicsClient;
      }),
    }));

  attachGraphicsClientToActiveWebinar = (identifier: string) => {
    const { activeWebinarId } = this.props;
    if (!activeWebinarId) {
      toast.error('Je hebt nog geen actieve webinar geselecteerd');
      return;
    }

    const { io } = this.props;
    io.emit('attachGraphicsClientToWebinar', identifier, activeWebinarId);
  };

  render() {
    const { graphicsClients } = this.state;
    const { activeWebinarId } = this.props;

    return (
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800 font-weight-bold">Graphics</h1>
        <div className="row">
          {graphicsClients.map(
            ({ id, name, identifier, connected, Webinar }) => (
              <div className="col-4" key={id}>
                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">
                      {name}
                      <div className="float-right">
                        <span
                          className={`badge badge-${
                            connected ? 'success' : 'danger'
                          }`}
                        >
                          {connected ? 'Verbonden' : 'Niet verbonden'}
                        </span>
                      </div>
                    </h6>
                  </div>
                  <div className="card-body">
                    <p>
                      <span className="text-gray-800">
                        <span className="font-weight-bold">Identifier:</span>{' '}
                        {identifier}
                      </span>
                    </p>
                    <p>
                      <span className="text-gray-800">
                        <span className="font-weight-bold">Webinar:</span>{' '}
                        {Webinar ? Webinar.name : 'Geen webinar toegewezen'}
                      </span>
                    </p>
                    <p>
                      <button
                        type="button"
                        className={`btn ${
                          Webinar?.id === activeWebinarId
                            ? 'btn-success'
                            : 'btn-info'
                        }`}
                        onClick={() =>
                          this.attachGraphicsClientToActiveWebinar(identifier)
                        }
                        disabled={!activeWebinarId}
                      >
                        {Webinar?.id === activeWebinarId
                          ? 'Verstuur huisstijl opnieuw'
                          : 'Koppel aan actieve webinar'}
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    );
  }
}

export default Graphics;
