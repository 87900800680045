import * as React from 'react';
import { HeaderType } from 'react-bs-datatable/lib/helpers/types';

import { ISurveyQuestion } from '../../../interfaces/ISurvey';

import DataTable from '../../components/DataTable';

export interface SurveyTableProps {
  surveyQuestions: ISurveyQuestion[];
  edit?: ISurveyQuestion;
  buttonDisabled: boolean;
  handleMove: (direction: 'up' | 'down', id: number) => void;
  handleEditSurveyQuestion: (id?: number) => void;
  handleDeleteSurveyQuestion: (id: number) => void;
}

export const typeName = (type: ISurveyQuestion['type']): string => {
  if (type === 'rank5') return 'Rank 1 t/m 5';
  if (type === 'rank10') return 'Rank 1 t/m 10';
  if (type === 'multiplechoice') return 'Meerkeuze';

  return 'Invulveld';
};

const SurveyTable: React.FC<SurveyTableProps> = ({
  surveyQuestions,
  edit,
  buttonDisabled,
  handleMove,
  handleEditSurveyQuestion,
  handleDeleteSurveyQuestion,
}) => {
  const MoveButtons = ({ id }: { id: number }) => {
    const canMoveUp = surveyQuestions.findIndex((q) => q.id === id) !== 0;
    const canMoveDown =
      surveyQuestions.findIndex((q) => q.id === id) !==
      surveyQuestions.length - 1;

    return (
      <>
        <button
          type="button"
          className="btn btn-outline-secondary btn-sm mr-1"
          onClick={() => handleMove('up', id)}
          disabled={!!edit || !canMoveUp || buttonDisabled}
        >
          <i className="fas fa-fw fa-arrow-up" />
        </button>
        <button
          type="button"
          className="btn btn-outline-secondary btn-sm mr-1"
          onClick={() => handleMove('down', id)}
          disabled={!!edit || !canMoveDown || buttonDisabled}
        >
          <i className="fas fa-fw fa-arrow-down" />
        </button>
      </>
    );
  };

  const EditButton = ({ id }: { id: number }) => (
    <button
      type="button"
      className="btn btn-primary btn-sm mr-1"
      onClick={() => {
        if (!edit) handleEditSurveyQuestion(id);
        else handleEditSurveyQuestion();
      }}
      disabled={!!edit || buttonDisabled}
    >
      <i className="fas fa-fw fa-edit" />
    </button>
  );

  const DeleteButton = ({ id }: { id: number }) => (
    <button
      type="button"
      className="btn btn-danger btn-sm mr-1"
      onClick={() => handleDeleteSurveyQuestion(id)}
      disabled={!!edit || buttonDisabled}
    >
      <i className="fas fa-fw fa-trash" />
    </button>
  );

  const headers: HeaderType[] = [
    {
      title: 'Vraag',
      prop: 'question',
      filterable: true,
    },
    {
      title: 'Type',
      prop: 'type',
      filterable: true,
      cell: ({ type }: ISurveyQuestion) => typeName(type),
    },
    {
      title: 'Opties',
      prop: 'options',
      cell: ({ id }: ISurveyQuestion) => (
        <>
          <MoveButtons id={id} />
          <EditButton id={id} />
          <DeleteButton id={id} />
        </>
      ),
      cellProps: { className: 'text-center', style: { width: 200 } },
    },
  ];

  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Overzicht enquêtevragen
        </h6>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <DataTable
            headers={headers}
            data={surveyQuestions}
            noResults="Er zijn geen enquêtevragen gevonden voor dit webinar"
          />
        </div>
      </div>
    </div>
  );
};

export default SurveyTable;
