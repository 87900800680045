import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { IWebinar } from '../../../../interfaces/IWebinar';

export interface WebinarCardProps {
  webinar: IWebinar;
  onArchive: (id: IWebinar['id']) => void;
  onResetReminders: (id: IWebinar['id']) => void;
}

const WebinarCard: React.SFC<WebinarCardProps> = ({
  webinar: { id, name, company, start, end },
  onArchive,
  onResetReminders,
}) => {
  const ReactSwal = withReactContent(Swal);

  return (
    <div className="col-12 col-lg-4">
      <div className="card shadow mb-4" key={id}>
        <div className="card-header py-3">
          <h6 className="m-0 text-primary">
            {name}
            <br />
            <span className="font-weight-bold">{company}</span>
          </h6>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-3">
              <span className="font-weight-bold">Start:</span>
            </div>
            <div className="col-9">
              {moment(start).format('dddd DD MMMM YYYY / HH:mm')} uur
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-3">
              <span className="font-weight-bold">Einde:</span>
            </div>
            <div className="col-9">
              {moment(end).format('dddd DD MMMM YYYY / HH:mm')} uur
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Link
                className="btn btn-info btn-sm mr-2"
                to={`/webinars/edit/${id}`}
              >
                <i className="fas fa-edit fa-fw" /> Bewerken
              </Link>
              <div className="float-right">
                <button
                  type="button"
                  className="btn btn-warning btn-sm mr-2"
                  onClick={async () => {
                    const { isConfirmed } = await ReactSwal.fire({
                      icon: 'info',
                      html: (
                        <p>
                          Weet je zeker dat je de reminders voor {name} wil
                          resetten?
                        </p>
                      ),
                      showConfirmButton: true,
                      confirmButtonText: 'Ja',
                      confirmButtonColor: '#008fc5',
                      showDenyButton: true,
                      denyButtonText: 'Nee',
                      denyButtonColor: '#aaa',
                    });

                    if (isConfirmed) {
                      onResetReminders(id);

                      ReactSwal.fire({
                        icon: 'success',
                        title: <p>Reminders voor {name} zijn gereset</p>,
                        showConfirmButton: false,
                        timer: 2000,
                      });
                    }
                  }}
                >
                  <i className="fas fa-undo fa-fw" /> Reset reminders
                </button>

                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={async () => {
                    const { isConfirmed } = await ReactSwal.fire({
                      icon: 'info',
                      html: <p>Weet je zeker dat je {name} wil archiveren?</p>,
                      showConfirmButton: true,
                      confirmButtonText: 'Ja',
                      confirmButtonColor: '#008fc5',
                      showDenyButton: true,
                      denyButtonText: 'Nee',
                      denyButtonColor: '#aaa',
                    });

                    if (isConfirmed) {
                      onArchive(id);

                      ReactSwal.fire({
                        icon: 'success',
                        title: <p>{name} is gearchiveerd</p>,
                        showConfirmButton: false,
                        timer: 2000,
                      });
                    }
                  }}
                >
                  <i className="fas fa-archive fa-fw" /> Archiveren
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarCard;
