import React from 'react';

import config from '../config';

export interface StyleLoaderProps {
  children: React.ReactNode;
}

const ProductionStyle = React.lazy(
  () => import('../styles/environment/production'),
);
const StagingStyle = React.lazy(() => import('../styles/environment/staging'));
const DefaultStyle = React.lazy(() => import('../styles/environment/default'));

const StyleLoader: React.FC<StyleLoaderProps> = ({ children }) => {
  const { deployment } = config;

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const style = params.get('style');

  const isProduction = style === 'production' || deployment === 'production';
  const isStaging = style === 'staging' || deployment === 'staging';

  return (
    <>
      <React.Suspense fallback={<></>}>
        {isProduction && <ProductionStyle />}
        {isStaging && <StagingStyle />}
        {!isProduction && !isStaging && <DefaultStyle />}
        {children}
      </React.Suspense>
    </>
  );
};

export default StyleLoader;
