import * as React from 'react';
import { NavLink, Link, Route } from 'react-router-dom';

import config from '../../config';

import AuthService from '../../services/AuthService';

import SidebarLink, { SidebarLinkSendProps } from './SidebarLink';

import logo from '../../assets/images/logo.png';

export interface SidebarProps {
  activeWebinarId: number | undefined;
  disableQuestions?: boolean;
  disableRegistrations?: boolean;
}

const Sidebar: React.SFC<SidebarProps> = ({
  activeWebinarId,
  disableQuestions,
  disableRegistrations,
}) => {
  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      <Link
        className="sidebar-brand d-flex align-items-center justify-content-center"
        to="/"
      >
        <img src={logo} alt="Q Broadcasting" />
      </Link>

      <hr className="sidebar-divider my-0" />

      <NavLink
        exact
        className="nav-item"
        component={({ children, className, href }: SidebarLinkSendProps) => (
          <SidebarLink icon="fa-home" className={className} href={href}>
            {children}
          </SidebarLink>
        )}
        to="/"
      >
        Dashboard
      </NavLink>

      {AuthService.hasRole(config.roles.webinars) && (
        <Route
          path="/webinars"
          // eslint-disable-next-line react/no-children-prop
          children={({ match }) => (
            <li className={`nav-item${match ? ' active' : ''}`}>
              <button
                type="button"
                className={`btn btn-link nav-link${match ? '' : ' collapsed'}`}
                data-toggle="collapse"
                data-target="#navWebinars"
                aria-expanded="true"
                aria-controls="navWebinars"
              >
                <i className="fas fa-fw fa-broadcast-tower" />
                <span>Webinars</span>
              </button>
              <div
                id="navWebinars"
                className={`collapse${match ? ' show' : ''}`}
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <NavLink
                    className="collapse-item"
                    to="/webinars/overview"
                    exact
                  >
                    Overzicht
                  </NavLink>
                  <NavLink className="collapse-item" to="/webinars/add" exact>
                    Toevoegen
                  </NavLink>
                </div>
              </div>
            </li>
          )}
        />
      )}

      {AuthService.hasRole(config.roles.graphics) && (
        <NavLink
          exact
          className="nav-item"
          component={({ children, className, href }: SidebarLinkSendProps) => (
            <SidebarLink icon="fa-tv" className={className} href={href}>
              {children}
            </SidebarLink>
          )}
          to="/graphics"
        >
          Graphics
        </NavLink>
      )}

      {AuthService.hasRole(config.roles.users) && (
        <NavLink
          exact
          className="nav-item"
          component={({ children, className, href }: SidebarLinkSendProps) => (
            <SidebarLink icon="fa-user" className={className} href={href}>
              {children}
            </SidebarLink>
          )}
          to="/users"
        >
          Gebruikers
        </NavLink>
      )}

      {activeWebinarId !== undefined && (
        <>
          <hr className="sidebar-divider my-0" />
          {!disableQuestions && AuthService.hasRole(config.roles.questions) && (
            <NavLink
              exact
              className="nav-item"
              component={({
                children,
                className,
                href,
              }: SidebarLinkSendProps) => (
                <SidebarLink
                  icon="fa-question"
                  className={className}
                  href={href}
                >
                  {children}
                </SidebarLink>
              )}
              to="/questions"
            >
              Vragen
            </NavLink>
          )}

          {AuthService.hasRole(config.roles.statements) && (
            <NavLink
              exact
              className="nav-item"
              component={({
                children,
                className,
                href,
              }: SidebarLinkSendProps) => (
                <SidebarLink
                  icon="fa-chart-bar"
                  className={className}
                  href={href}
                >
                  {children}
                </SidebarLink>
              )}
              to="/statements"
            >
              Stellingen
            </NavLink>
          )}

          {AuthService.hasRole(config.roles.breakoutSessions) && (
            <NavLink
              exact
              className="nav-item"
              component={({
                children,
                className,
                href,
              }: SidebarLinkSendProps) => (
                <SidebarLink icon="fa-users" className={className} href={href}>
                  {children}
                </SidebarLink>
              )}
              to="/breakout-sessions"
            >
              Breakout-sessies
            </NavLink>
          )}

          {AuthService.hasRole(config.roles.speeddates) && (
            <NavLink
              exact
              className="nav-item"
              component={({
                children,
                className,
                href,
              }: SidebarLinkSendProps) => (
                <SidebarLink
                  icon="fa-user-clock"
                  className={className}
                  href={href}
                >
                  {children}
                </SidebarLink>
              )}
              to="/speeddates"
            >
              Speeddates
            </NavLink>
          )}

          {AuthService.hasRole(config.roles.informationWindow) && (
            <NavLink
              exact
              className="nav-item"
              component={({
                children,
                className,
                href,
              }: SidebarLinkSendProps) => (
                <SidebarLink
                  icon="fa-info-circle"
                  className={className}
                  href={href}
                >
                  {children}
                </SidebarLink>
              )}
              to="/information-window"
            >
              Informatievenster
            </NavLink>
          )}

          {AuthService.hasRole(config.roles.survey) && (
            <NavLink
              exact
              className="nav-item"
              component={({
                children,
                className,
                href,
              }: SidebarLinkSendProps) => (
                <SidebarLink
                  icon="fa-star-half-alt"
                  className={className}
                  href={href}
                >
                  {children}
                </SidebarLink>
              )}
              to="/survey"
            >
              Enquête
            </NavLink>
          )}

          {AuthService.hasRole(config.roles.chat) && (
            <NavLink
              exact
              className="nav-item"
              component={({
                children,
                className,
                href,
              }: SidebarLinkSendProps) => (
                <SidebarLink
                  icon="fa-comments"
                  className={className}
                  href={href}
                >
                  {children}
                </SidebarLink>
              )}
              to="/chat"
            >
              Chat
            </NavLink>
          )}

          {AuthService.hasRole(config.roles.host) && (
            <>
              <hr className="sidebar-divider my-0" />
              <NavLink
                exact
                className="nav-item"
                component={({
                  children,
                  className,
                  href,
                }: SidebarLinkSendProps) => (
                  <SidebarLink
                    icon="fa-align-left"
                    className={className}
                    href={href}
                  >
                    {children}
                  </SidebarLink>
                )}
                to="/host"
              >
                Presentator
              </NavLink>
            </>
          )}

          {(AuthService.hasRole(config.roles.adminTools) ||
            AuthService.hasRole(config.roles.reports)) && (
            <hr className="sidebar-divider my-0" />
          )}

          {!disableRegistrations &&
            AuthService.hasRole(config.roles.registrations) && (
              <Route
                path="/registrations"
                // eslint-disable-next-line react/no-children-prop
                children={({ match }) => (
                  <li className={`nav-item${match ? ' active' : ''}`}>
                    <button
                      type="button"
                      className={`btn btn-link nav-link${
                        match ? '' : ' collapsed'
                      }`}
                      data-toggle="collapse"
                      data-target="#navRegistrations"
                      aria-expanded="true"
                      aria-controls="navRegistrations"
                    >
                      <i className="fas fa-fw fa-user-friends" />
                      <span>Registraties</span>
                    </button>
                    <div
                      id="navRegistrations"
                      className={`collapse${match ? ' show' : ''}`}
                      aria-labelledby="headingTwo"
                      data-parent="#accordionSidebar"
                    >
                      <div className="bg-white py-2 collapse-inner rounded">
                        <NavLink
                          className="collapse-item"
                          to="/registrations"
                          exact
                        >
                          Overzicht
                        </NavLink>
                        <NavLink
                          className="collapse-item"
                          to="/registrations/upload"
                          exact
                        >
                          Uploaden
                        </NavLink>
                      </div>
                    </div>
                  </li>
                )}
              />
            )}

          {AuthService.hasRole(config.roles.reports) && (
            <NavLink
              exact
              className="nav-item"
              component={({
                children,
                className,
                href,
              }: SidebarLinkSendProps) => (
                <SidebarLink
                  icon="fa-file-alt"
                  className={className}
                  href={href}
                >
                  {children}
                </SidebarLink>
              )}
              to="/reports"
            >
              Rapportage
            </NavLink>
          )}

          {AuthService.hasRole(config.roles.adminTools) && (
            <NavLink
              exact
              className="nav-item"
              component={({
                children,
                className,
                href,
              }: SidebarLinkSendProps) => (
                <SidebarLink icon="fa-wrench" className={className} href={href}>
                  {children}
                </SidebarLink>
              )}
              to="/admin-tools"
            >
              Admin-tools
            </NavLink>
          )}
        </>
      )}

      {/* <hr className="sidebar-divider" />

      <div className="sidebar-heading">Interface</div>

      <li className="nav-item">
        <button
          className="btn btn-link nav-link collapsed"
          data-toggle="collapse"
          data-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
        >
          <i className="fas fa-fw fa-cog"></i>
          <span>Components</span>
        </button>
        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Custom Components:</h6>
            <NavLink className="collapse-item" to="/404">
              404 page
            </NavLink>
            <a className="collapse-item" href="cards.html">
              Cards
            </a>
          </div>
        </div>
      </li>

      <li className="nav-item">
        <button
          className="btn btn-link nav-link collapsed"
          data-toggle="collapse"
          data-target="#collapseUtilities"
          aria-expanded="true"
          aria-controls="collapseUtilities"
        >
          <i className="fas fa-fw fa-wrench"></i>
          <span>Utilities</span>
        </button>
        <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Custom Utilities:</h6>
            <a className="collapse-item" href="utilities-color.html">
              Colors
            </a>
            <a className="collapse-item" href="utilities-border.html">
              Borders
            </a>
            <a className="collapse-item" href="utilities-animation.html">
              Animations
            </a>
            <a className="collapse-item" href="utilities-other.html">
              Other
            </a>
          </div>
        </div>
      </li>

      <hr className="sidebar-divider" />

      <div className="sidebar-heading">Addons</div>

      <li className="nav-item">
        <button
          className="btn btn-link nav-link collapsed"
          data-toggle="collapse"
          data-target="#collapsePages"
          aria-expanded="true"
          aria-controls="collapsePages"
        >
          <i className="fas fa-fw fa-folder"></i>
          <span>Pages</span>
        </button>
        <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Login Screens:</h6>
            <a className="collapse-item" href="login.html">
              Login
            </a>
            <a className="collapse-item" href="register.html">
              Register
            </a>
            <a className="collapse-item" href="forgot-password.html">
              Forgot Password
            </a>
            <div className="collapse-divider"></div>
            <h6 className="collapse-header">Other Pages:</h6>
            <a className="collapse-item" href="404.html">
              404 Page
            </a>
            <a className="collapse-item" href="blank.html">
              Blank Page
            </a>
          </div>
        </div>
      </li>

      <li className="nav-item">
        <a className="nav-link" href="charts.html">
          <i className="fas fa-fw fa-chart-area"></i>
          <span>Charts</span>
        </a>
      </li>

      <li className="nav-item">
        <a className="nav-link" href="tables.html">
          <i className="fas fa-fw fa-table"></i>
          <span>Tables</span>
        </a>
      </li> */}
    </ul>
  );
};

export default Sidebar;
