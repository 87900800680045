import * as React from 'react';
import { ChromePicker } from 'react-color';
import Color from 'color';

export interface ColorPickerProps {
  text?: string;
  color: string;
  onChange: (color: string) => void;
}

const ColorPicker: React.SFC<ColorPickerProps> = ({
  text,
  color,
  onChange,
}) => {
  const [focus, setFocus] = React.useState(false);
  let isDark = false;

  try {
    isDark = Color(color).isDark();
  } catch (e) {
    //
  }

  return (
    <div className="mr-4">
      {text && (
        <small id="qt" className="form-text text-muted">
          {text}
        </small>
      )}
      <div className="input-group">
        <div className="input-group-prepend mb-2">
          <span className="input-group-text" id="inputGroupPrepend">
            <button
              className="btn btn-link text-dark btn-sm p-0"
              type="button"
              onClick={() => setFocus(!focus)}
            >
              <i
                className={`fas fa-fw fa-${focus ? 'times' : 'eye-dropper'}`}
              />
            </button>
          </span>
        </div>
        <input
          type="text"
          style={{
            color: isDark ? 'white' : 'black',
            backgroundColor: color,
          }}
          className="form-control"
          value={color}
          onChange={({ currentTarget: { value: c } }) => onChange(c)}
          onFocus={() => setFocus(true)}
          required
        />
      </div>
      {focus && (
        <ChromePicker
          color={color}
          onChange={({ hex }) => onChange(hex)}
          disableAlpha
        />
      )}
    </div>
  );
};

export default ColorPicker;
