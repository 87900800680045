import React from 'react';
import moment from 'moment';

import { IQuestion } from '../../../interfaces/IQuestion';

export interface QuestionCardProps {
  question: IQuestion;
  locked: boolean;
  currentEdit: number | null;
  otherLive: boolean;
  setCurrentEdit: (number: number | null) => void;
  onEdit: (quedtionId: number, locked: boolean) => void;
  onSave: (question: IQuestion) => void;
  onDelete: (id: IQuestion['id']) => void;
  onLive: (id: IQuestion['id'], live: boolean) => void;
  onAnswerQuestion: (id?: IQuestion['id']) => void;
}

export interface QuestionCardState {
  selfQuestion: IQuestion;
  edit: boolean;
  wasLive?: string;
}

class QuestionCard extends React.Component<
  QuestionCardProps,
  QuestionCardState
> {
  wasLiveTimer?: ReturnType<typeof setInterval>;

  constructor(props: QuestionCardProps) {
    super(props);

    const { question } = this.props;

    this.state = {
      selfQuestion: { ...question },
      edit: false,
    };
  }

  componentDidMount() {
    this.handleWasLive();
    this.wasLiveTimer = setInterval(() => this.handleWasLive(), 10000);
  }

  componentDidUpdate({ question: { onAir: prevOnAir } }: QuestionCardProps) {
    const {
      question: { onAir: currentOnAir },
    } = this.props;

    if (prevOnAir !== currentOnAir) this.handleWasLive();
  }

  componentWillUnmount() {
    if (this.wasLiveTimer) clearInterval(this.wasLiveTimer);
  }

  handleWasLive = () => {
    const {
      question: { wasLive },
    } = this.props;

    if (wasLive) this.setState({ wasLive: moment(wasLive).fromNow() });
  };

  handleInputChange = ({
    currentTarget: { name, value },
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
    this.setState(({ selfQuestion }) => ({
      selfQuestion: { ...selfQuestion, [name]: value },
    }));

  save = () =>
    this.setState(
      ({ edit }) => {
        const {
          onEdit,
          question: { id },
        } = this.props;

        onEdit(id, !edit);

        return { edit: !edit };
      },
      () => {
        const { selfQuestion, edit } = this.state;
        const { setCurrentEdit, onSave } = this.props;

        if (edit) {
          setCurrentEdit(selfQuestion.id);
        } else {
          setCurrentEdit(null);
          onSave(selfQuestion);
        }
      },
    );

  delete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const {
      onDelete,
      question: { id },
    } = this.props;

    onDelete(id);
  };

  handleLive = (): void => {
    const {
      question: { id, onAir },
      onLive,
    } = this.props;

    onLive(id, !onAir);
  };

  render() {
    const { selfQuestion, edit, wasLive } = this.state;
    const {
      question: liveQuestion,
      currentEdit,
      otherLive,
      locked,
      onAnswerQuestion,
    } = this.props;

    const q = edit ? selfQuestion : liveQuestion;
    const {
      id,
      onAir,
      createdAt,
      stage,
      name,
      reviewedName,
      question,
      reviewedQuestion,
      remark,
      answer,
    } = q;

    return (
      <div
        className={`card${
          onAir
            ? ' border-left-danger'
            : locked
            ? ' border-left-secondary'
            : stage === 'incoming'
            ? ' border-left-primary'
            : stage === 'edit'
            ? ' border-left-warning'
            : ' border-left-success'
        } shadow`}
      >
        <div
          className={`card-header${onAir ? ' bg-gradient-danger' : ''}${
            onAir ? ' text-gray-100' : ''
          }`}
        >
          <span className="font-weight-bold">
            {reviewedName ?? name}
            {((reviewedQuestion && reviewedQuestion !== question) ||
              (reviewedName && reviewedName !== name)) && (
              <span className="badge badge-warning ml-2">Bewerkt</span>
            )}
          </span>
          <span className="float-right">
            {stage === 'edit' && (
              <>
                {edit &&
                  ((reviewedQuestion && reviewedQuestion !== question) ||
                    (reviewedName && reviewedName !== name)) && (
                    <button
                      type="button"
                      className="btn btn-link btn-sm mr-2 p-0"
                      onClick={() =>
                        this.setState(({ selfQuestion: s }) => ({
                          selfQuestion: {
                            ...s,
                            reviewedName: null,
                            reviewedQuestion: null,
                          },
                        }))
                      }
                      disabled={locked}
                    >
                      <i className="fas fa-undo" />
                    </button>
                  )}
                {(!currentEdit || currentEdit === id) && (
                  <button
                    type="button"
                    className="btn btn-link btn-sm mr-2 p-0"
                    onClick={this.save}
                    disabled={locked}
                  >
                    <i className={`fas fa-fw fa-${edit ? 'save' : 'edit'}`} />
                  </button>
                )}
              </>
            )}
            {/* {stage !== 'approved' && ( */}
            {stage !== 'approved' && (
              <button
                type="button"
                className="btn btn-link btn-sm text-secondary mr-2 p-0"
                onClick={() => onAnswerQuestion(id)}
                data-toggle="modal"
                data-target="#modal-answer-question"
                disabled={onAir || locked}
              >
                <i className="fas fa-reply text-primary" />
              </button>
            )}
            <button
              type="button"
              className="btn btn-link btn-sm text-secondary mr-2 p-0"
              onClick={this.delete}
              disabled={onAir || locked}
            >
              <i className="fas fa-trash text-primary" />
            </button>
            {/*  )} */}
            {stage === 'approved' && (
              <button
                type="button"
                className={`btn btn-${
                  onAir ? 'danger' : 'success'
                } btn-sm mr-2`}
                onClick={this.handleLive}
                disabled={otherLive}
              >
                <i className="fas fa-tv" /> Live
              </button>
            )}
            {moment(createdAt).format('HH:mm')}
          </span>
        </div>
        <div className="card-body py-3">
          {edit ? (
            <>
              <div className="form-group">
                <label style={{ width: '100%' }} htmlFor="reviewedName">
                  Naam:
                  <input
                    className="form-control"
                    id="reviewedName"
                    name="reviewedName"
                    value={reviewedName ?? name}
                    onChange={this.handleInputChange}
                  />
                </label>
              </div>
              <div className="form-group">
                <label style={{ width: '100%' }} htmlFor="reviewedQuestion">
                  Vraag:
                  <textarea
                    className="form-control"
                    rows={3}
                    id="reviewedQuestion"
                    name="reviewedQuestion"
                    value={reviewedQuestion ?? question}
                    onChange={this.handleInputChange}
                  />
                </label>
              </div>
              <div className="alert alert-warning my-2 p-2">
                <div className="form-group">
                  <label style={{ width: '100%' }} htmlFor="remark">
                    Opmerking:
                    <textarea
                      className="form-control"
                      rows={3}
                      id="remark"
                      name="remark"
                      value={remark}
                      onChange={this.handleInputChange}
                    />
                  </label>
                </div>
              </div>
            </>
          ) : (
            <>
              {reviewedQuestion ?? question}
              {remark && (
                <div className="alert alert-warning my-2 p-2 small">
                  <span className="font-weight-bold">Opmerking:</span> {remark}
                </div>
              )}
            </>
          )}
          {answer && (
            <div className="alert alert-success my-2 p-2 small">
              <span className="font-weight-bold">Antwoord:</span> {answer}
            </div>
          )}
          {!onAir && wasLive && (
            <div>
              <span className="badge badge-danger">{wasLive} live</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default QuestionCard;
