import * as React from 'react';
import { HeaderType } from 'react-bs-datatable/lib/helpers/types';

import { IBreakoutSession } from '../../../interfaces/IBreakoutSession';

import DataTable from '../../components/DataTable';

import BreakoutSessionHost from './BreakoutSessionHostModal';

export interface BreakoutSessionsTableProps {
  breakoutSessions: IBreakoutSession[];
  buttonDisabled?: boolean;
  edit?: IBreakoutSession;
  handleBreakoutSessionActive: (id: number, active: boolean) => void;
  handleDeleteBreakoutSession: (id: number, name: string) => void;
  handleEditBreakoutSession: (id?: number) => void;
}

const BreakoutSessionsTable: React.SFC<BreakoutSessionsTableProps> = ({
  breakoutSessions,
  buttonDisabled,
  edit,
  handleBreakoutSessionActive,
  handleDeleteBreakoutSession,
  handleEditBreakoutSession,
}) => {
  const [
    hostBreakoutSession,
    setHostBreakoutSession,
  ] = React.useState<IBreakoutSession>();

  const EditButton = ({ id }: { id: number }) => (
    <button
      type="button"
      className="btn btn-primary btn-sm mr-1"
      onClick={() => {
        if (!edit) handleEditBreakoutSession(id);
        else handleEditBreakoutSession();
      }}
      disabled={!!edit || buttonDisabled}
    >
      <i className="fas fa-fw fa-edit" />
    </button>
  );

  const DeleteButton = ({ id, name }: { id: number; name: string }) => (
    <button
      type="button"
      className="btn btn-danger btn-sm mr-1"
      onClick={() => handleDeleteBreakoutSession(id, name)}
      disabled={buttonDisabled}
    >
      <i className="fas fa-fw fa-trash" />
    </button>
  );

  const HostButton = ({
    breakoutSession,
  }: {
    breakoutSession: IBreakoutSession;
  }) => (
    <button
      type="button"
      className="btn btn-info btn-sm"
      onClick={() => setHostBreakoutSession(breakoutSession)}
      data-toggle="modal"
      data-target="#modal-breakout-session-host"
      disabled={false}
    >
      <i className="fas fa-fw fa-users-cog" />
    </button>
  );

  const headers: HeaderType[] = [
    {
      title: 'Actief',
      prop: 'active',
      cell: ({ id, active }: IBreakoutSession) => (
        <button
          type="button"
          className="btn btn-link btn-sm p-0"
          onClick={() => handleBreakoutSessionActive(id, !active)}
        >
          <i
            className={`fas fa-fw fa-circle text-${
              active ? 'success' : 'danger'
            } h5`}
          />
        </button>
      ),
      cellProps: {
        className: 'text-center',
        style: { width: 100 },
      },
    },
    {
      title: 'Naam',
      prop: 'name',
      filterable: true,
      cellProps: { className: 'w-25' },
    },
    {
      title: 'Omschrijving',
      prop: 'description',
      filterable: true,
      cell: ({ description }: IBreakoutSession) => description || '-',
    },
    {
      title: 'Deelnemers',
      prop: 'limit',
      cell: ({ limit }: IBreakoutSession) => `${limit || 'Geen limiet'}`,
      cellProps: { style: { width: 150 } },
    },
    {
      title: 'Opties',
      prop: 'options',
      cell: ({ id, name, withHost, ...breakoutSession }: IBreakoutSession) => (
        <>
          <EditButton id={id} />
          <DeleteButton id={id} name={name} />
          {withHost && (
            <HostButton
              breakoutSession={{ ...breakoutSession, id, name, withHost }}
            />
          )}
        </>
      ),
      cellProps: { className: 'text-center', style: { width: 200 } },
    },
  ];

  return (
    <>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            Overzicht breakout-sessies
          </h6>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <DataTable
              headers={headers}
              data={breakoutSessions}
              noResults="Er zijn geen breakout-sessies gevonden voor dit webinar"
            />
          </div>
        </div>
      </div>
      <BreakoutSessionHost hostBreakoutSession={hostBreakoutSession} />
    </>
  );
};

export default BreakoutSessionsTable;
