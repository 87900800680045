import * as React from 'react';

import config from '../../config';

export type FooterProps = Record<string, unknown>;

const Footer: React.SFC<FooterProps> = () => {
  const now = new Date();
  const year =
    now.getFullYear() > 2020 ? `2020 - ${now.getFullYear()}` : `2020`;
  const { version } = config;

  return (
    <footer className="sticky-footer bg-white">
      <div className="container my-auto">
        <div className="copyright text-center my-auto">
          <span>
            &copy; {year}{' '}
            <a
              href="https://www.hostin.cc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              HostIn
            </a>{' '}
            voor{' '}
            <a
              href="https://www.qbroadcasting.tv/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Q Broadcasting
            </a>{' '}
            | Build <strong>{version}</strong>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
