import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { ToastContainer } from 'react-toastify';

import Content from './content';
import AuthService from './services/AuthService';
import Login from './auth/Login';
import ResetPassword from './auth/ResetPassword';
import ChatControl from './ChatControl';

export type AppProps = Record<string, unknown>;

const App: React.SFC<AppProps> = () => {
  return (
    <>
      <CookieConsent
        buttonText="Ik snap het"
        cookieName="qba_consent"
        location="bottom"
        sameSite="strict"
      >
        We plaatsen functionele cookies om deze website goed te laten werken. Er
        worden geen gegevens opgeslagen in deze cookies.
      </CookieConsent>
      <ToastContainer />
      <Switch>
        <Route
          path="/chat-control/cf1bc707-4451-48d5-892f-ec877291c9cb"
          component={ChatControl}
        />
        <Route
          path="/login"
          render={() => {
            if (AuthService.isAuthenticated()) return <Redirect to="/" />;
            return <Login />;
          }}
        />
        <Route
          path="/reset-password"
          render={() => {
            if (AuthService.isAuthenticated()) return <Redirect to="/" />;
            return <ResetPassword />;
          }}
        />
        <Route
          path="/"
          render={() => {
            if (!AuthService.isAuthenticated()) return <Redirect to="/login" />;
            return <Content />;
          }}
        />
      </Switch>
    </>
  );
};

export default App;
