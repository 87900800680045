import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { IBreakoutSessionForLinking } from '../../../../interfaces/IBreakoutSession';

import HttpService from '../../../../services/HttpService';

export interface LinkBreakoutSessionsModalProps {
  show: boolean;
  handleClose: () => void;
  registrations: number[];
  breakoutSessions?: IBreakoutSessionForLinking[];
}

const LinkBreakoutSessionsModal: React.FC<LinkBreakoutSessionsModalProps> = ({
  show,
  handleClose,
  registrations,
  breakoutSessions,
}) => {
  const [sure, setSure] = React.useState(false);
  const [linking, setLinking] = React.useState(false);
  const [type, setType] = React.useState<'link' | 'unlink'>('link');
  const [selected, setSelected] = React.useState<IBreakoutSessionForLinking[]>(
    [],
  );

  const link = async () => {
    if (!sure) return setSure(true);

    try {
      const body = {
        type,
        registrations,
        breakoutSessions: selected.reduce(
          (p, c) => p.concat(c.id),
          [] as number[],
        ),
      };

      setSure(false);
      setLinking(true);

      await HttpService.post('/registration/breakout-sessions', body);

      const ReactSwal = withReactContent(Swal);

      ReactSwal.fire({
        icon: 'success',
        title: (
          <p>
            Breakout-sessie{selected.length !== 1 && 's'}{' '}
            {type === 'link' ? 'gekoppeld aan' : 'ontkoppeld van'} registratie
            {registrations.length !== 1 && 's'}
          </p>
        ),
        showConfirmButton: false,
        timer: 1500,
      });

      handleClose();
      setLinking(false);
      setSelected([]);
      setType('link');

      return undefined;
    } catch (e) {
      return toast.error('Er is iets mis gegaan');
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Breakout-sessies (ont)koppelen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          {breakoutSessions ? (
            breakoutSessions.length > 0 ? (
              <Typeahead
                id="breakout-sessions"
                multiple
                selected={selected}
                onChange={setSelected}
                options={breakoutSessions}
                labelKey="name"
                placeholder="Selecteer breakout-sessies"
                disabled={linking}
              />
            ) : (
              <span>
                Er zijn geen breakout-sessies gevonden voor dit webinar
              </span>
            )
          ) : (
            <div className="h2 text-center">
              <i className="fas fa-spinner fa-spin fa-fw" />
            </div>
          )}
        </div>
        <div className="form-group">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="linkBreakoutSessions"
              id="linkBreakoutSessions"
              value="on"
              onChange={() => setType('link')}
              checked={type === 'link'}
              disabled={linking}
            />
            <label className="form-check-label" htmlFor="linkBreakoutSessions">
              Koppelen
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="unlinkBreakoutSessions"
              id="unlinkBreakoutSessions"
              value="off"
              onChange={() => setType('unlink')}
              checked={type === 'unlink'}
              disabled={linking}
            />
            <label
              className="form-check-label"
              htmlFor="unlinkBreakoutSessions"
            >
              Ontkoppelen
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          type="button"
          data-dismiss="modal"
          onClick={() => {
            setSelected([]);
            handleClose();
          }}
          disabled={linking}
        >
          Sluiten
        </button>
        <button
          className={`btn btn-${sure ? 'success' : 'primary'}`}
          type="button"
          onClick={() => link()}
          disabled={!breakoutSessions || selected.length === 0 || linking}
        >
          {linking ? (
            <i className="fas fa-spinner fa-spin fa-fw" />
          ) : (
            <>
              <i className="fas fa-link fa-fw mr-2" />
              {sure
                ? 'Zeker weten?'
                : `${type === 'link' ? 'K' : 'Ontk'}oppel ${
                    registrations.length
                  } registratie${registrations.length === 1 ? '' : 's'} ${
                    type === 'link' ? 'aan' : 'van'
                  } ${selected.length} sessie${
                    selected.length === 1 ? '' : 's'
                  }`}
            </>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default LinkBreakoutSessionsModal;
