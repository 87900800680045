import * as React from 'react';

export interface PasswordInputProps {
  id: string;
  value: string;
  placeholder?: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PasswordInput: React.SFC<PasswordInputProps> = ({
  id,
  value,
  placeholder,
  onInputChange,
}) => {
  const [show, setShow] = React.useState(false);

  return (
    <div className="input-group">
      <input
        type={show ? 'text' : 'password'}
        className="form-control"
        id={id}
        value={value}
        onChange={onInputChange}
        placeholder={placeholder}
        required
        autoComplete="current-password"
      />
      <div className="input-group-append">
        <div className="input-group-text">
          <button
            type="button"
            className="btn btn-link text-dark btn-sm p-0"
            onClick={() => setShow(!show)}
            tabIndex={-1}
          >
            <i className={`fas fa-fw fa-eye${show ? '-slash' : ''}`} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;
