import * as React from 'react';

import { IQuestion } from '../../../interfaces/IQuestion';

export interface QuestionProps {
  question: IQuestion;
}

const Question: React.SFC<QuestionProps> = ({
  question: { name, reviewedName, question, reviewedQuestion, remark },
}) => {
  return (
    <div className="col-12 mb-5">
      <h1 className="h1 font-weight-bold">{reviewedName ?? name}</h1>
      <h2 className="h2">{reviewedQuestion ?? question}</h2>
      {remark && (
        <div className="badge badge-dark py-2 px-2 display-2">
          <h5 className="h5 mb-0">
            <i className="fas fa-info-circle mr-1" /> {remark}
          </h5>
        </div>
      )}
    </div>
  );
};

export default Question;
