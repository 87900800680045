import * as React from 'react';
import Datatable from 'react-bs-datatable';

import {
  HeaderType,
  OnRowClick,
  SortType,
  TableClasses,
} from 'react-bs-datatable/lib/helpers/types';

export interface DataTableProps {
  headers: HeaderType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  classes?: TableClasses;
  rowsPerPage?: number;
  rowsPerPageOption?: number[];
  noResults?: string;
  initialSort?: SortType;
  onRowClick?: OnRowClick;
}

const DataTable: React.FC<DataTableProps> = ({
  headers,
  data,
  classes,
  rowsPerPage,
  rowsPerPageOption,
  noResults,
  initialSort,
  onRowClick,
}) => {
  return (
    <Datatable
      tableHeaders={headers}
      tableBody={data}
      classes={{
        ...classes,
        filterCol: 'mb-4',
        table: 'table-bordered table-striped',
        thead: 'font-weight-bold',
      }}
      rowsPerPage={rowsPerPage ?? 10}
      rowsPerPageOption={rowsPerPageOption ?? undefined}
      labels={{
        first: '<<',
        prev: '<',
        next: '>',
        last: '>>',
        filterPlaceholder: 'Zoeken...',
        noResults: noResults ?? 'Er is niets gevonden',
        show: 'Laat ',
        entries: ' resultaten zien',
      }}
      initialSort={initialSort}
      onRowClick={onRowClick}
    />
  );
};

export default DataTable;
