import * as React from 'react';
import ReactCountdown from 'react-countdown';
import moment from 'moment';

import { IBreakoutSession } from '../../../interfaces/IBreakoutSession';

export interface BreakoutSessionsOptionsProps {
  breakoutSessions: IBreakoutSession[];
  breakoutSessionsActive: boolean;
  connected: boolean;
  breakoutSessionsCountdown?: moment.Moment;
  handleBreakoutSessionsActive: () => void;
  handleSetBreakoutSessionsEnd: (offset?: number) => void;
}

const BreakoutSessionsOptions: React.SFC<BreakoutSessionsOptionsProps> = ({
  breakoutSessions,
  breakoutSessionsActive,
  connected,
  breakoutSessionsCountdown,
  handleBreakoutSessionsActive,
  handleSetBreakoutSessionsEnd,
}) => {
  const activeButtonDisabled =
    !connected ||
    breakoutSessions.length === 0 ||
    !breakoutSessions.some(({ active }) => active);
  const noActiveBreakoutSessions =
    breakoutSessions.length !== 0 &&
    !breakoutSessions.some(({ active }) => active);
  const oneActiveBreakoutSession =
    breakoutSessions.filter(({ active }) => active).length === 1;

  const [countdown, setCountdown] = React.useState('00:01:00');

  const countdownRef = React.useRef<ReactCountdown>(null);

  React.useEffect(() => {
    if (countdownRef.current) countdownRef.current.start();
  });

  const handleStartCountdown = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const diff = moment(`2000-01-01 ${countdown}`).diff(
      moment(`2000-01-01 00:00:00`),
      'seconds',
    );

    handleSetBreakoutSessionsEnd(diff);
  };

  const handleStopCountdown = () => handleSetBreakoutSessionsEnd(undefined);

  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">Opties</h6>
      </div>
      <div className="card-body">
        <div className="text-center">
          <button
            type="button"
            className={`btn btn-${
              breakoutSessionsActive ? 'danger' : 'success'
            } btn-block`}
            onClick={handleBreakoutSessionsActive}
            disabled={activeButtonDisabled}
          >
            <i className="fas fa-broadcast-tower mr-2" />
            {noActiveBreakoutSessions
              ? 'Selecteer breakout-sessies om live te zetten'
              : `${!breakoutSessionsActive ? 'Start' : 'Stop'} breakout-sessie${
                  oneActiveBreakoutSession ? '' : 's'
                }`}
          </button>
          <small className="text-muted">
            Dit is een krachtige tool. Let goed op wat je doet
          </small>
        </div>
        <hr />
        <div className="row">
          <div className="col-12 col-lg-6">
            <h5 className="h5 font-weight-bold">Start aftelklok</h5>
            <form onSubmit={handleStartCountdown}>
              <input
                className="form-control text-center"
                type="time"
                min="00:00:00"
                max="12:00:00"
                step="1"
                value={countdown}
                onChange={({ currentTarget: { value } }) => setCountdown(value)}
                disabled={!breakoutSessionsActive || activeButtonDisabled}
              />
              <div className="row no-gutters mt-2">
                <div className="col pr-1">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={!breakoutSessionsActive || activeButtonDisabled}
                  >
                    {breakoutSessionsCountdown ? 'Update' : 'Start'}
                  </button>
                </div>
                <div className="col pl-2">
                  <button
                    type="button"
                    className="btn btn-danger btn-block"
                    disabled={
                      !breakoutSessionsActive ||
                      activeButtonDisabled ||
                      !breakoutSessionsCountdown
                    }
                    onClick={handleStopCountdown}
                  >
                    Stop
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 col-lg-6">
            <h5 className="h5 font-weight-bold">Huidige aftelklok</h5>
            <h1 className="h1 bos-countdown">
              {!breakoutSessionsActive ? (
                '--:--:--'
              ) : (
                <ReactCountdown
                  ref={countdownRef}
                  date={
                    breakoutSessionsCountdown
                      ? breakoutSessionsCountdown.toDate()
                      : moment().toDate()
                  }
                  renderer={({ formatted: { hours, minutes, seconds } }) =>
                    `${hours}:${minutes}:${seconds}`
                  }
                />
              )}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakoutSessionsOptions;
