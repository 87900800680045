import * as React from 'react';
import { toast } from 'react-toastify';

import AnswerInput from './AnswerInput';

export interface AddStatementProps {
  io: SocketIOClient.Socket;
  connected: boolean;
}

export interface AddStatementState {
  statement: string;
  answer1: string;
  answer2: string;
  answer3: string;
  answer4: string;
  answer5: string;
  answer6: string;
}

class AddStatement extends React.Component<
  AddStatementProps,
  AddStatementState
> {
  constructor(props: AddStatementProps) {
    super(props);

    this.state = {
      statement: '',
      answer1: '',
      answer2: '',
      answer3: '',
      answer4: '',
      answer5: '',
      answer6: '',
    };
  }

  onAddStatement = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { connected } = this.props;
    if (!connected) return;

    const {
      statement,
      answer1,
      answer2,
      answer3,
      answer4,
      answer5,
      answer6,
    } = this.state;

    const answers = [];
    if (answer1) answers.push(answer1);
    if (answer2) answers.push(answer2);
    if (answer3) answers.push(answer3);
    if (answer4) answers.push(answer4);
    if (answer5) answers.push(answer5);
    if (answer6) answers.push(answer6);

    const { io } = this.props;
    io.emit('addStatement', statement, answers);

    toast.success('De stelling is toegevoegd!');

    this.setState({
      statement: '',
      answer1: '',
      answer2: '',
      answer3: '',
      answer4: '',
      answer5: '',
      answer6: '',
    });
  };

  handleInputChange = ({
    currentTarget: { name, value },
  }: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState((s) => ({ ...s, [name]: value }));

  render() {
    const {
      statement,
      answer1,
      answer2,
      answer3,
      answer4,
      answer5,
      answer6,
    } = this.state;
    const { connected } = this.props;

    return (
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            Stelling toevoegen
          </h6>
        </div>
        <div className="card-body">
          <form onSubmit={this.onAddStatement}>
            <div className="form-group">
              <label style={{ width: '100%' }} htmlFor="statement">
                Stelling
                <input
                  type="text"
                  className="form-control text-gray-900"
                  id="statement"
                  name="statement"
                  value={statement}
                  onChange={this.handleInputChange}
                  placeholder="Voer hier de stelling in"
                  required
                />
              </label>
            </div>
            {statement && (
              <div className="form-group">
                <label style={{ width: '100%' }} htmlFor="answer1">
                  Antwoorden
                  <AnswerInput
                    handleInputChange={this.handleInputChange}
                    id="answer1"
                    name="answer1"
                    value={answer1}
                    placeholder="Antwoord 1"
                    required
                  />
                  {(answer1 ||
                    answer2 ||
                    answer3 ||
                    answer4 ||
                    answer5 ||
                    answer6) && (
                    <AnswerInput
                      handleInputChange={this.handleInputChange}
                      name="answer2"
                      value={answer2}
                      placeholder="Antwoord 2"
                      required
                    />
                  )}
                  {(answer2 || answer3 || answer4 || answer5 || answer6) && (
                    <AnswerInput
                      handleInputChange={this.handleInputChange}
                      name="answer3"
                      value={answer3}
                      placeholder="Antwoord 3"
                    />
                  )}
                  {(answer3 || answer4 || answer5 || answer6) && (
                    <AnswerInput
                      handleInputChange={this.handleInputChange}
                      name="answer4"
                      value={answer4}
                      placeholder="Antwoord 4"
                    />
                  )}
                  {(answer4 || answer5 || answer6) && (
                    <AnswerInput
                      handleInputChange={this.handleInputChange}
                      name="answer5"
                      value={answer5}
                      placeholder="Antwoord 5"
                    />
                  )}
                  {(answer5 || answer6) && (
                    <AnswerInput
                      handleInputChange={this.handleInputChange}
                      name="answer6"
                      value={answer6}
                      placeholder="Antwoord 6"
                    />
                  )}
                </label>
              </div>
            )}
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!connected}
              >
                Voeg deze stelling toe
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default AddStatement;
