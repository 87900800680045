import * as React from 'react';

export interface RegistrationsErrorsProps {
  errors: string[];
}

const RegistrationsErrors: React.FC<RegistrationsErrorsProps> = ({
  errors,
}) => {
  return (
    <div className="card shadow mb-4">
      <div
        className={`card-header py-3${
          errors.length === 0 ? '' : ' bg-gradient-danger text-white'
        }`}
      >
        Fouten
      </div>
      <div className="card-body">
        {errors.length === 0
          ? 'Geen fouten gevonden'
          : errors.map((e, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i}>{e}</div>
            ))}
      </div>
    </div>
  );
};

export default RegistrationsErrors;
