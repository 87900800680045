import * as React from 'react';

import { IWebinar } from '../../../interfaces/IWebinar';

import WysiwygEditor from '../../components/WysiwygEditor';

export interface InformationWindowProps {
  io: SocketIOClient.Socket;
  connected: boolean;
  activeWebinar?: IWebinar;
}

export interface InformationWindowState {
  informationWindowContent: string;
  changes: boolean;
}

class InformationWindow extends React.Component<
  InformationWindowProps,
  InformationWindowState
> {
  constructor(props: InformationWindowProps) {
    super(props);

    this.state = {
      informationWindowContent: '',
      changes: false,
    };
  }

  componentDidUpdate({
    activeWebinar: prevActiveWebinar,
  }: InformationWindowProps) {
    const { activeWebinar } = this.props;

    if (!prevActiveWebinar && activeWebinar)
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        informationWindowContent: activeWebinar.informationWindowContent ?? '',
      });
  }

  handleEditorChange = (id: string, value: string) =>
    this.setState((s) => ({
      ...s,
      [id]: value,
      changes: true,
    }));

  handleSave = () => {
    const { informationWindowContent } = this.state;
    const { io } = this.props;

    io.emit('updateInformationWindowContent', informationWindowContent);

    this.setState({ changes: false });
  };

  handleInformationWindowActive = () => {
    const { io, activeWebinar } = this.props;

    if (activeWebinar) {
      const { informationWindowActive } = activeWebinar;

      io.emit('updateInformationWindowActive', !informationWindowActive);
    }
  };

  render() {
    const { informationWindowContent, changes } = this.state;
    const { activeWebinar } = this.props;

    return (
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800 font-weight-bold">
          Informatievenster
        </h1>

        {activeWebinar && (
          <div className="row">
            <div className="col-12">
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <WysiwygEditor
                        id="informationWindowContent"
                        value={informationWindowContent}
                        onChange={this.handleEditorChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-primary mr-3"
                      disabled={!changes}
                      onClick={this.handleSave}
                    >
                      Wijzigingen opslaan
                    </button>
                    <button
                      type="button"
                      className={`btn btn-${
                        changes
                          ? 'secondary'
                          : activeWebinar.informationWindowActive
                          ? 'danger'
                          : 'success'
                      }`}
                      onClick={this.handleInformationWindowActive}
                      disabled={changes}
                    >
                      {changes ? (
                        <>
                          <i className="fas fa-arrow-left mr-2" />
                          Sla eerst wijzigingen op
                        </>
                      ) : (
                        <>
                          <i className="fas fa-broadcast-tower mr-2" />
                          Maak informatievenster{' '}
                          {activeWebinar.informationWindowActive ? 'in' : ''}
                          actief
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default InformationWindow;
