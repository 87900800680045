import * as React from 'react';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

export interface RegistrationsDropzoneProps {
  onRegistrations: (
    registrations: { [key: string]: unknown }[],
    headers: string[],
  ) => void;
}

const RegistrationsDropzone: React.FC<RegistrationsDropzoneProps> = ({
  onRegistrations,
}) => {
  const handleFileChange = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && files.length > 0) {
      const file = files[0];
      const allowed = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ];

      if (!allowed.includes(file.type))
        toast.warn('Je kunt enkel Excel-spreadsheets uploaden');
      else if (file) {
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = (evt) => {
          const result = evt.target?.result;

          if (!result) {
            toast.error(
              'Er is iets fout gegaan bij het lezen van het bestand. Probeer het opnieuw',
            );
          } else {
            const wb = XLSX.read(result, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, {
              blankrows: false,
              header: 1,
              defval: null,
            }) as string[][];

            const header = data.shift();

            if (header) {
              const registrations = data.reduce((p, c) => {
                const registration: { [key: string]: unknown } = {};

                c.forEach((value, index) => {
                  let v = value;
                  const head = header[index];

                  if (typeof v === 'string') v = v.trim();
                  if (head) registration[head] = v || undefined;
                });

                return p.concat(registration);
              }, [] as { [key: string]: unknown }[]);

              const fHeaders = header.filter((v) => !!v);
              const duplicate = fHeaders.find(
                (v, i) => fHeaders.indexOf(v) !== i,
              );

              if (duplicate)
                toast.error(
                  `De kolom-titel ${duplicate} mag niet meer dan één keer voorkomen in het Excel-document. Pas de kolom-titel aan en probeer het opnieuw`,
                );
              else onRegistrations(registrations, fHeaders);
            } else
              toast.error(
                'Er is iets fout gegaan bij het lezen van het bestand. Probeer het opnieuw',
              );
          }
        };

        reader.onerror = () =>
          toast.error(
            'Er is iets fout gegaan bij het lezen van het bestand. Probeer het opnieuw',
          );
      }
    }
  };

  return (
    <div className="files">
      <input
        type="file"
        className="custom-file-input"
        id="registrations"
        name="registrations"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleFileChange}
        // eslint-disable-next-line no-return-assign
        onClick={(e) => (e.currentTarget.value = '')}
      />
      <label className="custom-file-label mt-2 mx-3" htmlFor="registrations">
        Kies een Excel-bestand met registraties
      </label>
    </div>
  );
};

export default RegistrationsDropzone;
