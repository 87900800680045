import * as React from 'react';

export interface AddQuestionCardProps {
  io: SocketIOClient.Socket;
  connected: boolean;
}

const AddQuestionCard: React.SFC<AddQuestionCardProps> = ({
  io,
  connected,
}) => {
  const [name, setName] = React.useState('');
  const [question, setQuestion] = React.useState('');
  const [stage, setStage] = React.useState('incoming');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    io.emit('addQuestion', name, question, stage);

    setName('');
    setQuestion('');
  };

  return (
    <div className="card shadow mb-4">
      <a
        href="#collapseCardExample"
        className="d-block card-header py-3"
        data-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="collapseCardExample"
      >
        <h6 className="m-0 font-weight-bold text-primary">Vraag toevoegen</h6>
      </a>
      <div className="collapse" id="collapseCardExample">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                className="form-control"
                id="name"
                name="name"
                placeholder="Naam"
                value={name}
                onChange={({ currentTarget: { value } }) => setName(value)}
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                rows={3}
                id="question"
                name="question"
                placeholder="Vraag"
                value={question}
                onChange={({ currentTarget: { value } }) => setQuestion(value)}
              />
            </div>
            <div className="form-group">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="stage"
                  id="incoming"
                  checked={stage === 'incoming'}
                  onChange={({ currentTarget: { checked } }) => {
                    if (checked) setStage('incoming');
                  }}
                />
                <label className="form-check-label" htmlFor="incoming">
                  Inbox
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="stage"
                  id="edit"
                  checked={stage === 'edit'}
                  onChange={({ currentTarget: { checked } }) => {
                    if (checked) setStage('edit');
                  }}
                />
                <label className="form-check-label" htmlFor="edit">
                  Voorselectie
                </label>
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!name || !question || !connected}
              >
                Toevoegen
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddQuestionCard;
