import * as React from 'react';

import { ISurveyQuestion } from '../../../interfaces/ISurvey';
import { EWebinarState } from '../../../enums/EWebinar';

export interface SurveyOptionsProps {
  surveyQuestions: ISurveyQuestion[];
  connected: boolean;
  state?: EWebinarState;
  onSendSurvey: () => void;
}

const SurveyOptions: React.FC<SurveyOptionsProps> = ({
  surveyQuestions,
  connected,
  state,
  onSendSurvey,
}) => {
  const [surveySent, setSurveySent] = React.useState(false);
  const statePre = state === EWebinarState.pre;
  const activeButtonDisabled =
    !connected || surveyQuestions.length === 0 || statePre;

  const sendSurvey = () => {
    setSurveySent(true);
    onSendSurvey();

    setTimeout(() => setSurveySent(false), 500);
  };

  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">Opties</h6>
      </div>
      <div className="card-body">
        <div className="text-center">
          <button
            type="button"
            className={`btn btn-${
              statePre ? 'secondary' : surveySent ? 'danger' : 'success'
            } btn-block`}
            onClick={() => sendSurvey()}
            disabled={activeButtonDisabled}
          >
            <i className="fas fa-broadcast-tower mr-2" />
            {statePre
              ? 'Webinar-state moet live of post zijn'
              : 'Verstuur enquête'}
          </button>
          <small className="text-muted">
            De enquête wordt éénmaal verstuurd naar alle actieve kijkers.
          </small>
        </div>
      </div>
    </div>
  );
};

export default SurveyOptions;
