import * as React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import SelectSearch, { SelectSearchOption } from 'react-select-search';

import config from '../../config';

import { IJWTContent } from '../../interfaces/IUser';
import { IWebinar } from '../../interfaces/IWebinar';
import { EWebinarState } from '../../enums/EWebinar';

import AuthService from '../../services/AuthService';

export interface TopNavProps {
  webinars: IWebinar[];
  activeWebinarId?: number;
  userDetails: IJWTContent | undefined;
  connected: boolean;
  graphicsConnected: boolean;
  disableRegistrations?: boolean;
  isBullsAndBears?: boolean;
  state: EWebinarState | undefined;
  viewers: number;
  registrations: number;
  handleSetActiveWebinarId: (activeWebinarId: number) => void;
}

export interface TopNavState {
  piet: number;
}

class TopNav extends React.Component<TopNavProps, TopNavState> {
  render() {
    const {
      webinars,
      activeWebinarId,
      userDetails,
      connected,
      graphicsConnected,
      disableRegistrations,
      isBullsAndBears,
      state,
      viewers,
      registrations,
      handleSetActiveWebinarId,
    } = this.props;

    const handleLogout = () => {
      toast.success('Je bent succesvol uitgelogd');
      AuthService.logout();
    };

    return (
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <ul className="navbar-nav">
          <li className="nav-item dropdown no-arrow mx-1">
            <span className="nav-link dropdown-toggle small text-gray-600">
              <SelectSearch
                options={webinars.reduce((options, { id, name }) => {
                  options.push({ value: `${id}`, name });

                  return options;
                }, [] as SelectSearchOption[])}
                search
                value={`${activeWebinarId}` ?? undefined}
                placeholder="Kies een actieve webinar"
                onChange={(id) =>
                  handleSetActiveWebinarId(
                    parseInt((id as unknown) as string, 10),
                  )
                }
              />
            </span>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          {activeWebinarId && (
            <>
              <li className="nav-item dropdown no-arrow mx-1">
                <span className="nav-link dropdown-toggle text-gray-600">
                  <span
                    className={`badge badge-${
                      state === EWebinarState.pre ||
                      state === EWebinarState.post
                        ? 'success'
                        : 'danger'
                    }`}
                  >
                    {state?.toUpperCase()}
                  </span>
                </span>
              </li>
              <li className="nav-item dropdown no-arrow mx-1">
                <span className="nav-link dropdown-toggle small text-gray-600">
                  <span className="font-weight-bold mx-1">{viewers}</span>kijker
                  {viewers !== 1 && 's'}
                </span>
              </li>
              {(!disableRegistrations || isBullsAndBears) && (
                <li className="nav-item dropdown no-arrow mx-1">
                  <span className="nav-link dropdown-toggle small text-gray-600">
                    <span className="font-weight-bold mx-1">
                      {registrations}
                    </span>
                    aanmelding
                    {registrations !== 1 && 'en'}
                  </span>
                </li>
              )}
            </>
          )}
          {AuthService.hasRole(config.roles.graphics) && activeWebinarId && (
            <li className="nav-item dropdown no-arrow mx-1">
              <span className="nav-link dropdown-toggle small text-gray-600">
                <span>Graphics</span>
                {!connected ? (
                  <>
                    <div className="connection-disabled" />
                  </>
                ) : graphicsConnected ? (
                  <>
                    <div className="connected" />
                  </>
                ) : (
                  <>
                    <div className="disconnected" />
                  </>
                )}
              </span>
            </li>
          )}
          <li className="nav-item dropdown no-arrow mx-1">
            <span className="nav-link dropdown-toggle small text-gray-600">
              {connected ? (
                <>
                  <span>Verbonden met server</span>
                  <div className="connected" />
                </>
              ) : (
                <>
                  <span>Niet verbonden met server</span>
                  <div className="disconnected" />
                </>
              )}
            </span>
          </li>

          <div className="topbar-divider d-none d-sm-block" />

          <li className="nav-item dropdown no-arrow">
            <button
              type="button"
              className="btn btn-link nav-link dropdown-toggle"
              id="userDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-lg-inline text-gray-600 small">
                {userDetails
                  ? `${userDetails.firstname} ${userDetails.lastname}`
                  : ''}
              </span>
            </button>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <Link className="dropdown-item" to="/user/security">
                <i className="fas fa-lock fa-sm fa-fw mr-2 text-gray-400" />
                Accountbeveiliging
              </Link>
              <div className="dropdown-divider" />
              <button
                type="button"
                className="btn btn-link btn-sm dropdown-item"
                onClick={handleLogout}
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                Uitloggen
              </button>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

export default TopNav;
