import * as React from 'react';

export interface InputTextProps {
  inputClassName?: string;
  id: string;
  label?: string;
  type?: string;
  placeholder?: string;
  help?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

const InputText: React.FC<InputTextProps> = ({
  inputClassName,
  id,
  label,
  type = 'text',
  placeholder,
  help,
  value,
  onChange,
  onBlur,
}) => {
  return (
    <div className="form-group">
      {label && <label htmlFor={id}>{label}</label>}
      <input
        type={type}
        className={`form-control${inputClassName ? ` ${inputClassName}` : ''}`}
        id={id}
        aria-describedby={help ? `${id}Help` : undefined}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
        onBlur={onBlur}
      />
      {help && (
        <small id={`${id}Help`} className="form-text text-muted">
          {help}
        </small>
      )}
    </div>
  );
};

export default InputText;
