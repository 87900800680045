import * as React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { IQuestion } from '../../../interfaces/IQuestion';

export interface AnswerQuestionModalProps {
  question?: IQuestion;
  handleAnswerQuestion: (id: number, answer: string) => void;
}

const AnswerQuestionModal: React.SFC<AnswerQuestionModalProps> = ({
  question,
  handleAnswerQuestion,
}) => {
  const questionText = question?.question ?? question?.reviewedQuestion;
  const ReactSwal = withReactContent(Swal);

  const [answer, setAnswer] = React.useState('');

  const send = () =>
    setTimeout(() => {
      if (question) handleAnswerQuestion(question.id, answer);

      setAnswer('');

      ReactSwal.fire({
        icon: 'success',
        title: <p>Je antwoord is verstuurd</p>,
        showConfirmButton: false,
        timer: 2000,
      });
    }, 300);

  return (
    <div id="modal-answer-question" className="modal fade" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Beantwoord {question?.name ?? ''}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              <span className="font-weight-bold">Vraag:</span>{' '}
              {questionText ?? ''}
            </p>
            <div className="form-group">
              <textarea
                className="form-control"
                rows={3}
                id="answer"
                name="answer"
                placeholder="Schrijf hier je antwoord"
                value={answer}
                onChange={({ currentTarget: { value } }) => setAnswer(value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              disabled={!answer}
              onClick={send}
            >
              Verzenden
            </button>
            <button type="button" className="btn btn-dark" data-dismiss="modal">
              Sluiten
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerQuestionModal;
