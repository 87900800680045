import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { IRole, IUser } from '../../../interfaces/IUser';

import InputText from '../../components/InputText';

export interface UserModalProps {
  show: boolean;
  user?: IUser;
  users: IUser[];
  roles: IRole[];
  onSave: (
    firstname: string,
    lastname: string,
    email: string,
    roles: IRole[],
    userId?: number,
  ) => void;
  onHide: () => void;
  onToggleUserRole: (userId: number, roleId: number) => void;
}

const UserModal: React.FC<UserModalProps> = ({
  show,
  user,
  users,
  roles,
  onHide,
  onSave,
  onToggleUserRole,
}) => {
  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [userRoles, setUserRoles] = React.useState<IRole[]>([]);
  const [emailError, setEmailError] = React.useState(false);

  React.useEffect(() => {
    if (user) {
      setFirstname(user.firstname);
      setLastname(user.lastname);
      setEmail(user.email);
      setUserRoles(user.Roles.map(({ id, name }) => ({ id, name })));
    }
  }, [user]);

  const reset = () => {
    setFirstname('');
    setLastname('');
    setEmail('');
    setUserRoles([]);
  };

  const hide = () => {
    onHide();
    setTimeout(reset, 500);
  };

  const save = () => {
    onSave(firstname, lastname, email, userRoles, user?.id);

    if (!user)
      toast.success(
        `De gebruiker ${firstname} ${lastname} is aangemaakt. Er is een wachtwoord toegestuurd`,
      );

    hide();
  };

  const toggleUserRole = (roleId: number) => {
    if (user) onToggleUserRole(user?.id ?? 0, roleId);

    const hasRole = userRoles.some(({ id }) => id === roleId);

    if (hasRole) setUserRoles(userRoles.filter(({ id }) => id !== roleId));
    else setUserRoles([...userRoles, { id: roleId, name: 'Role' }]);
  };

  const validEmail = (override?: string) => {
    const ve = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return ve.test(override ?? email);
  };

  const checkEmail = (override?: string) =>
    setEmailError(!validEmail(override));

  const emailExists = () =>
    !user && users.some(({ email: uEmail }) => uEmail === email);

  const disabled = () => {
    if (!firstname || !lastname || !email || userRoles.length === 0)
      return true;

    if (!validEmail()) return true;
    if (emailExists()) return true;

    return false;
  };

  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {user
            ? `${user.firstname} ${user.lastname} bewerken`
            : 'Gebruiker toevoegen'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputText
          id="firstname"
          label="Voornaam"
          value={firstname}
          onChange={(v) => setFirstname(v)}
        />
        <InputText
          id="lastname"
          label="Achternaam"
          value={lastname}
          onChange={(v) => setLastname(v)}
        />
        <InputText
          inputClassName={
            emailError || emailExists() ? 'border-danger' : undefined
          }
          id="email"
          label="E-mailadres"
          type="email"
          value={email}
          onChange={(v) => {
            setEmail(v);
            if (emailError) checkEmail(v);
          }}
          onBlur={() => checkEmail()}
        />
        {roles.map((role) => {
          const className = userRoles.some((r) => r.id === role.id)
            ? 'success'
            : 'secondary';

          return (
            <button
              type="button"
              className={`btn btn-link btn-sm border-${className} text-${className} role-badge mr-2`}
              onClick={(e) => {
                e.preventDefault();

                toggleUserRole(role.id);
              }}
              key={role.id}
            >
              {role.name}
            </button>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        {emailExists() && (
          <span className="badge badge-danger">
            Er is al een gebruiker met dit e-mailadres
          </span>
        )}
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => save()}
          disabled={disabled()}
        >
          <i className="fas fa-save mr-2" />
          Opslaan
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserModal;
