import * as React from 'react';

export type DashboardProps = Record<string, unknown>;

const Dashboard: React.SFC<DashboardProps> = () => {
  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">Dashboard</h1>
      <h1 className="h5 mb-4 text-gray-800">
        Om te starten: Selecteer boven in het scherm een webinar
      </h1>
    </div>
  );
};

export default Dashboard;
