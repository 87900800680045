export default class WebinarService {
  static getActiveWebinarId(): number | undefined {
    const activeWebinarId = localStorage.getItem('activeWebinar');

    if (!activeWebinarId) return undefined;

    return parseInt(activeWebinarId, 10);
  }

  static setActiveWebinar(activeWebinarId: number): void {
    localStorage.setItem('activeWebinar', `${activeWebinarId}`);
  }

  static removeActiveWebinar(): void {
    localStorage.removeItem('activeWebinar');
  }
}
