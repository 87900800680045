import * as React from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import { IUser, IRole } from '../../../interfaces/IUser';

import { TRoles } from '../../../types/TAuth';
import UserModal from './UserModal';

export interface UsersProps {
  io: SocketIOClient.Socket;
  myUserId: number | undefined;
}

export interface UsersState {
  users: IUser[];
  roles: IRole[];
  edit?: IUser;
  showUserModal: boolean;
}

class Users extends React.Component<UsersProps, UsersState> {
  constructor(props: UsersProps) {
    super(props);

    this.state = {
      users: [],
      roles: [],
      showUserModal: false,
    };
  }

  componentDidMount() {
    const { io } = this.props;

    io.on('usersAndRoles', (users: IUser[], roles: IRole[]) =>
      this.setState({
        users: this.sortUsers(users),
        roles,
      }),
    );

    io.on('updatedUser', (updatedUser: IUser) =>
      this.setState(({ users }) => ({
        users: this.sortUsers(
          users.map((user) =>
            user.id === updatedUser.id ? updatedUser : user,
          ),
        ),
      })),
    );

    io.on('addUser', (user: IUser) =>
      this.setState(({ users }) => ({
        users: this.sortUsers([...users, user]),
      })),
    );

    io.emit('getData', 'usersAndRoles');
  }

  componentWillUnmount() {
    const { io } = this.props;

    io.off('usersAndRoles');
    io.off('updatedUser');
    io.off('addUser');
  }

  handleSave = (
    firstname: string,
    lastname: string,
    email: string,
    roles: IRole[],
    userId?: number,
  ): void => {
    const { io } = this.props;

    io.emit(
      'saveUser',
      { firstname, lastname, email },
      roles.map(({ id }) => id),
      userId,
    );
  };

  onToggleUserRole = (userId: number, roleId: number) => {
    const { roles } = this.state;
    const { myUserId } = this.props;
    const role = roles.find(({ id }) => id === roleId);

    if (userId === myUserId && role?.name === ('admin' as TRoles))
      return toast.warn(
        'Je kunt jezelf geen admin-rechten ontnemen. Dit dient een andere administrator voor je te doen',
      );

    const { io } = this.props;

    io.emit('toggleUserRole', userId, roleId);

    return undefined;
  };

  onToggleUserDisable = (userId: number) => {
    const { myUserId } = this.props;
    if (userId === myUserId)
      return toast.warn(
        'Je kunt je eigen gebruiker niet uitschakelen. Dit dient een andere administrator voor je te doen',
      );

    const { io } = this.props;

    io.emit('toggleUserDisable', userId);

    return undefined;
  };

  handleResetUserPassword = (userId: number) => {
    const { io } = this.props;

    io.emit('resetUserPassword', userId);

    toast.success(
      'Het wachtwoord van de gebruiker is gereset en per e-mail verstuurd.',
    );
  };

  sortUsers = (users: IUser[]): IUser[] =>
    users
      .sort((a, b) => a.firstname.localeCompare(b.firstname))
      .sort((a, b) => (a.disabled ? 1 : 0) - (b.disabled ? 1 : 0));

  handleEditUser = (id: number): void => {
    const { users } = this.state;
    const edit = users.find(({ id: uId }) => id === uId);

    if (edit) this.setState({ showUserModal: true, edit });
  };

  userModalState = (state?: boolean): void =>
    this.setState(({ showUserModal }) => ({
      showUserModal: state ?? !showUserModal,
    }));

  handleModalClose = () => {
    this.userModalState(false);

    setTimeout(() => this.setState({ edit: undefined }), 500);
  };

  render() {
    const { users, roles, edit, showUserModal } = this.state;

    return (
      <>
        <div className="container-fluid">
          <div className="d-flex">
            <h1 className="h3 mb-4 text-gray-800 font-weight-bold">
              Gebruikers
            </h1>
            <div className="ml-auto">
              <button
                className="btn btn-primary btn-sm ml-3"
                type="button"
                onClick={() => this.userModalState(true)}
              >
                <i className="fas fa-user-plus mr-2" />
                Gebruiker toevoegen
              </button>
            </div>
          </div>

          <div className="row">
            {users.length === 0
              ? 'Er zijn geen gebruikers gevonden'
              : users.map(
                  ({
                    id,
                    firstname,
                    lastname,
                    email,
                    lastLogin,
                    disabled,
                    totpSecret,
                    Roles,
                  }) => {
                    const lastLoginMoment = moment(lastLogin);

                    return (
                      <div className="col-4" key={id}>
                        <div className="card shadow mb-4">
                          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-primary">
                              {firstname} {lastname}
                            </h6>
                            <div className="ml-auto">
                              {disabled && (
                                <span className="badge badge-danger mr-2">
                                  Uitgeschakeld
                                </span>
                              )}
                            </div>
                            <div className="dropdown no-arrow">
                              <button
                                type="button"
                                className="btn btn-link btn-sm p-0 dropdown-toggle"
                                id="userOptions"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400" />
                              </button>
                              <div
                                className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                aria-labelledby="userOptions"
                              >
                                <button
                                  type="button"
                                  className="btn btn-link btn-sm dropdown-item"
                                  onClick={() => this.handleEditUser(id)}
                                >
                                  <i className="fa fa-user-edit mr-2" />
                                  Bewerken
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link btn-sm dropdown-item"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.onToggleUserDisable(id);
                                  }}
                                >
                                  <i
                                    className={`fa fa-user${
                                      disabled ? '-check' : '-slash'
                                    } mr-2`}
                                  />
                                  {disabled ? 'In' : 'Uit'}schakelen
                                </button>
                                <div className="dropdown-divider" />
                                <button
                                  type="button"
                                  className="btn btn-link btn-sm dropdown-item"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.handleResetUserPassword(id);
                                  }}
                                >
                                  <i className="fa fa-key mr-2" />
                                  Wachtwoord resetten
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            <p className="mb-1">
                              <span className="font-weight-bold">
                                E-mail adres:
                              </span>{' '}
                              {email}
                            </p>
                            <p className="mb-2">
                              <span className="font-weight-bold">
                                Laatste login:
                              </span>{' '}
                              {lastLogin
                                ? `${lastLoginMoment.format(
                                    'DD MMMM YYYY',
                                  )} om ${lastLoginMoment.format('HH:mm')}`
                                : '-'}
                            </p>
                            <p className="mb-0">
                              {roles.map((role) => {
                                const className = Roles.some(
                                  (userRole) => userRole.id === role.id,
                                )
                                  ? 'success'
                                  : 'secondary';

                                return (
                                  <span
                                    key={role.id}
                                    className={`badge badge-${className} role-badge mr-2`}
                                  >
                                    {role.name}
                                  </span>
                                );
                              })}
                            </p>
                            {!totpSecret && (
                              <>
                                <hr />
                                <div className="alert alert-danger mb-0 p-2 text-center">
                                  <span className="font-weight-bold">
                                    Multi-factor authentication
                                  </span>{' '}
                                  staat niet aan bij deze gebruiker.
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  },
                )}
          </div>
        </div>

        <UserModal
          show={showUserModal}
          user={edit}
          users={users}
          roles={roles}
          onSave={this.handleSave}
          onHide={this.handleModalClose}
          onToggleUserRole={this.onToggleUserRole}
        />
      </>
    );
  }
}

export default Users;
