import * as React from 'react';
import moment from 'moment';
import ReactCountdown from 'react-countdown';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { ISpeeddate } from '../../../interfaces/ISpeeddate';
import { IWebinar } from '../../../interfaces/IWebinar';

export interface SpeeddatesProps {
  io: SocketIOClient.Socket;
  connected: boolean;
  activeWebinar?: IWebinar;
}
export interface SpeeddatesState {
  speeddates?: ISpeeddate | null;
  nextRoundDate?: moment.Moment | null;
  endDate?: moment.Moment | null;
  form: Omit<ISpeeddate, 'activeParticipants'>;
}

class Speeddates extends React.Component<SpeeddatesProps, SpeeddatesState> {
  constructor(props: SpeeddatesProps) {
    super(props);

    this.state = {
      form: {
        roundLength: 60,
        roundsRemaining: 5,
        participantsPerSpeeddate: 2,
        uniqueCouples: true,
      },
    };
  }

  componentDidMount() {
    const { io } = this.props;

    io.on('speeddates', this.onSpeeddates);
    io.on('speeddateUpdate', this.onSpeeddateUpdate);
    io.on('speeddateRoundsRemaining', this.onSpeeddateUpdate);
    io.on('speeddateParticipants', this.onSpeeddateParticipants);

    io.emit('getData', 'speeddates');
  }

  componentWillUnmount() {
    const { io } = this.props;

    io.off('speeddates');
    io.off('speeddateUpdate');
    io.off('speeddateRoundsRemaining');
    io.off('speeddateParticipants');
  }

  onSpeeddates = (
    speeddates: ISpeeddate | null,
    secondsToNextRound?: number,
    secondsToEnd?: number,
  ) => {
    let nextRoundDate;
    let endDate;

    if (secondsToNextRound)
      nextRoundDate = moment().add(secondsToNextRound, 's');
    if (secondsToEnd) endDate = moment().add(secondsToEnd, 's');

    this.setState({ speeddates, nextRoundDate, endDate });
  };

  onSpeeddateUpdate = (
    roundsRemaining: number,
    secondsToNextRound: number,
    secondsToEnd: number,
  ): void => {
    const { speeddates } = this.state;
    const nextRoundDate = moment().add(secondsToNextRound, 's');
    const endDate = moment().add(secondsToEnd, 's');

    if (speeddates)
      this.setState({
        nextRoundDate,
        endDate,
        speeddates: { ...speeddates, roundsRemaining },
      });
    else this.setState({ nextRoundDate, endDate });
  };

  onSpeeddateParticipants = (activeParticipants: number) =>
    this.setState(({ speeddates }) => {
      if (speeddates) {
        const s = { ...speeddates };

        s.activeParticipants = activeParticipants;

        return { speeddates: s };
      }

      return { speeddates };
    });

  onCountdownCompleted = () => this.setState({ nextRoundDate: null });

  handleInputChange = ({
    currentTarget: { id, value },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    let setValue: string | number | boolean = value;

    const isInteger = [
      'roundsRemaining',
      'roundLength',
      'participantsPerSpeeddate',
    ];

    if (isInteger.includes(id)) setValue = parseInt(value, 10);

    if (id === 'participantsPerSpeeddate' && setValue < 3)
      this.setState((s) => ({
        ...s,
        form: { ...s.form, uniqueCouples: true },
      }));

    this.setState((s) => ({
      ...s,
      form: { ...s.form, [id]: setValue },
    }));
  };

  handleUniqueCouples = (uniqueCouples: boolean) =>
    this.setState(({ form }) => ({
      form: { ...form, uniqueCouples },
    }));

  handleToggle = async () => {
    const { speeddates, form } = this.state;
    const { io } = this.props;

    if (speeddates) {
      const ReactSwal = withReactContent(Swal);

      const { isConfirmed } = await ReactSwal.fire({
        icon: 'info',
        html: (
          <p>
            Weet je zeker dat je de speeddates na de huidige ronde wil stoppen?
          </p>
        ),
        showConfirmButton: true,
        confirmButtonText: 'Ja',
        confirmButtonColor: '#008fc5',
        showDenyButton: true,
        denyButtonText: 'Nee',
        denyButtonColor: '#aaa',
      });

      if (!isConfirmed) return;
    }

    io.emit('speeddatesActive', speeddates ? false : { ...form });
  };

  handleAddRounds = (roundsToAdd: number) => {
    const { io } = this.props;

    io.emit('speeddatesAddRounds', roundsToAdd);
  };

  render() {
    const {
      speeddates,
      nextRoundDate,
      endDate,
      form: {
        roundLength,
        roundsRemaining,
        participantsPerSpeeddate,
        uniqueCouples,
      },
    } = this.state;

    const { connected } = this.props;
    const disabled = !connected || speeddates === undefined;
    const endTime = moment()
      .startOf('hour')
      .add(roundsRemaining * (roundLength + 5), 's')
      .format('mm:ss');

    return (
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800 font-weight-bold">Speeddates</h1>
        <div className="row">
          {/* <div className="col-12 col-lg-4">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Controls</h6>
              </div>
              <div className="card-body text-center">
                <button
                  type="button"
                  className={`btn btn-${
                    speeddates ? 'danger' : 'success'
                  } btn-block`}
                  onClick={this.handleToggle}
                  disabled={disabled || speeddates?.roundsRemaining === 0}
                >
                  <i className="fas fa-broadcast-tower mr-2" />
                  {speeddates?.roundsRemaining === 0
                    ? 'Laatste ronde is bezig'
                    : `${speeddates ? 'Beëindig' : 'Start'} speeddates`}
                </button>
              </div>
            </div>
          </div> */}
          <div className="col-12 col-lg-4">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Controls</h6>
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <label htmlFor="roundLength" className="col-sm-7">
                    Lengte van een ronde
                  </label>
                  <div className="col-sm-5">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="roundLength"
                        min={10}
                        value={roundLength ?? 0}
                        onChange={this.handleInputChange}
                        disabled={disabled || !!speeddates}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">seconden</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <label htmlFor="roundsRemaining" className="col-sm-7">
                    Aantal rondes
                  </label>
                  <div className="col-sm-5">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="roundsRemaining"
                        min={1}
                        value={roundsRemaining ?? 0}
                        onChange={this.handleInputChange}
                        disabled={disabled || !!speeddates}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          ronde{roundsRemaining !== 1 ? 's' : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <label
                    htmlFor="participantsPerSpeeddate"
                    className="col-sm-7"
                  >
                    Deelnemers per speeddate
                  </label>
                  <div className="col-sm-5">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="participantsPerSpeeddate"
                        min={2}
                        value={participantsPerSpeeddate ?? 0}
                        onChange={this.handleInputChange}
                        disabled={disabled || !!speeddates}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">deelnemers</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <label htmlFor="uniqueCouples" className="col-sm-7">
                    Unieke koppels
                  </label>
                  <div className="col-sm-5">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="uniqueCouples"
                        id="uniqueCouplesOff"
                        value="off"
                        onChange={() => this.handleUniqueCouples(true)}
                        checked={uniqueCouples}
                        disabled={
                          disabled ||
                          !!speeddates ||
                          participantsPerSpeeddate < 3
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="uniqueCouplesOff"
                      >
                        Ja
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="uniqueCouples"
                        id="uniqueCouplesOn"
                        value="on"
                        onChange={() => this.handleUniqueCouples(false)}
                        checked={!uniqueCouples}
                        disabled={
                          disabled ||
                          !!speeddates ||
                          participantsPerSpeeddate < 3
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="uniqueCouplesOn"
                      >
                        Nee
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-0">
                  <button
                    type="button"
                    className={`btn btn-${
                      speeddates ? 'danger' : 'success'
                    } btn-block`}
                    onClick={this.handleToggle}
                    disabled={disabled || speeddates?.roundsRemaining === 0}
                  >
                    <i className="fas fa-broadcast-tower mr-2" />
                    {speeddates?.roundsRemaining === 0
                      ? 'Laatste ronde is bezig'
                      : `${speeddates ? 'Beëindig' : 'Start'} de speeddates ${
                          speeddates
                            ? 'na deze ronde '
                            : `(tijdsduur: ${!speeddates ? endTime : ''})`
                        }`}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Status</h6>
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <label htmlFor="roundLength" className="col-sm-8">
                    Status speeddates
                  </label>
                  <div className="col-sm-4">
                    <span
                      className={`badge badge-${
                        speeddates ? 'success' : 'danger'
                      }`}
                    >
                      {speeddates ? 'Actief' : 'Inactief'}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-8">Tijd tot volgende ronde</label>
                  <div className="col-sm-4">
                    {nextRoundDate ? (
                      <ReactCountdown
                        date={nextRoundDate.toDate()}
                        onComplete={this.onCountdownCompleted}
                        renderer={({ formatted: { minutes, seconds } }) =>
                          `${minutes}:${seconds}`
                        }
                      />
                    ) : nextRoundDate === null &&
                      speeddates?.roundsRemaining !== 0 ? (
                      'wordt gestart...'
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-8">Tijd tot einde</label>
                  <div className="col-sm-4">
                    {endDate ? (
                      <ReactCountdown
                        date={endDate.toDate()}
                        onComplete={this.onCountdownCompleted}
                        renderer={({ formatted: { minutes, seconds } }) =>
                          `${minutes}:${seconds}`
                        }
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-8">Resterende aantal rondes</label>
                  <div className="col-sm-4">
                    {speeddates ? speeddates.roundsRemaining : '-'}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-8">Aantal deelnemers</label>
                  <div className="col-sm-4">
                    {speeddates
                      ? `${speeddates.activeParticipants} deelnemer${
                          speeddates.activeParticipants !== 1 ? 's' : ''
                        }`
                      : '-'}
                  </div>
                </div>
                <div className="form-group mb-0">
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <button
                        type="button"
                        className="btn btn-secondary btn-block"
                        onClick={() => this.handleAddRounds(-1)}
                        disabled={
                          !speeddates || speeddates.roundsRemaining === 1
                        }
                      >
                        <i className="fas fa-minus mr-2" />
                        Verwijder één ronde
                      </button>
                    </div>
                    <div className="col-12 col-lg-6">
                      <button
                        type="button"
                        className="btn btn-info btn-block"
                        onClick={() => this.handleAddRounds(1)}
                        disabled={!speeddates}
                      >
                        <i className="fas fa-plus mr-2" />
                        Voeg één ronde toe
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Speeddates;
