import * as React from 'react';

export interface AnswerInputProps {
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  name: string;
  value: string;
  placeholder: string;
  required?: boolean;
}

const AnswerInput: React.SFC<AnswerInputProps> = ({
  handleInputChange,
  id,
  name,
  value,
  placeholder,
  required = false,
}) => {
  return (
    <div className="input-group mb-2">
      {/* <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="fas fa-times" />
        </span>
      </div> */}
      <input
        type="text"
        className="form-control text-gray-900"
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        required={required}
      />
    </div>
  );
};

export default AnswerInput;
