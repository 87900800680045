import * as React from 'react';
import SelectSearch, { SelectSearchOption } from 'react-select-search';

interface WebinarLanguageSelectorProps {
  lang: string;
  onChange: (lang: string) => void;
}

const options: SelectSearchOption[] = [
  { name: 'Nederlands', value: 'nl' },
  { name: 'Engels', value: 'en' },
];

const WebinarLanguageSelector: React.FunctionComponent<WebinarLanguageSelectorProps> = ({
  lang,
  onChange,
}) => {
  return (
    <div>
      <SelectSearch
        options={options}
        value={lang}
        placeholder="Kies de taal"
        search
        onChange={(value) => onChange((value as unknown) as string)}
      />
    </div>
  );
};

export default WebinarLanguageSelector;
