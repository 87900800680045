import * as React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { EWebinarState } from '../../../enums/EWebinar';

import {
  ISurveyQuestion,
  TSurveyQuestionType,
} from '../../../interfaces/ISurvey';
import SurveyForm from './SurveyForm';
import SurveyOptions from './SurveyOptions';

import SurveyTable from './SurveyTable';

export interface SurveyProps {
  io: SocketIOClient.Socket;
  connected: boolean;
  state?: EWebinarState;
}

export interface SurveyState {
  surveyQuestions: ISurveyQuestion[];
  surveyQuestionsOrder: number[];
  edit?: ISurveyQuestion;
}

class Survey extends React.Component<SurveyProps, SurveyState> {
  constructor(props: SurveyProps) {
    super(props);

    this.state = {
      surveyQuestions: [],
      surveyQuestionsOrder: [],
    };
  }

  componentDidMount() {
    const { io } = this.props;

    io.on('surveyQuestions', this.onSurveyQuestions);
    io.on('surveyQuestionsOrder', this.onSurveyQuestionsOrder);
    io.on('updateSurveyQuestion', this.onUpdateSurveyQuestion);
    // io.on('breakoutSessionsActive', this.onBreakoutSessionsActive);
    // io.on('breakoutSessionActive', this.onBreakoutSessionActive);
    // io.on('deleteBreakoutSession', this.onDeleteBreakoutSession);
    // io.on('updateBreakoutSession', this.onUpdateBreakoutSession);
    // io.on('breakoutSessionCountdown', this.onBreakoutSessionCountdown);

    io.emit('getData', 'surveyQuestions');
  }

  componentWillUnmount() {
    const { io } = this.props;

    io.off('surveyQuestions');
    io.off('surveyQuestionsOrder');
    io.off('updateSurveyQuestion');
  }

  onSurveyQuestions = (
    surveyQuestions: ISurveyQuestion[],
    surveyQuestionsOrder: number[],
  ) => this.setState({ surveyQuestions, surveyQuestionsOrder });

  onSurveyQuestionsOrder = (surveyQuestionsOrder: number[]) =>
    this.setState({ surveyQuestionsOrder });

  onUpdateSurveyQuestion = (surveyQuestion: ISurveyQuestion) =>
    this.setState(({ surveyQuestions }) => ({
      surveyQuestions: surveyQuestions.map((s) => {
        if (s.id === surveyQuestion.id) return surveyQuestion;
        return s;
      }),
    }));

  handleEditInputChange = (id: string, value: string) => {
    const { edit } = this.state;

    if (edit) {
      const newEdit = { ...edit, [id]: value };

      this.setState({ edit: newEdit });
    }
  };

  handleTypeInputChange = (type: TSurveyQuestionType) =>
    this.handleEditInputChange('type', type);

  handleEditSurveyQuestion = (id?: number) =>
    this.setState(({ surveyQuestions }) => ({
      edit: surveyQuestions.find((q) => q.id === id),
    }));

  handleMove = (direction: 'up' | 'down', id: number) => {
    const { io } = this.props;

    io.emit('moveSurveyQuestion', direction, id);
  };

  handleDeleteSurveyQuestion = async (id: number) => {
    const { io } = this.props;
    const ReactSwal = withReactContent(Swal);

    const { isConfirmed } = await ReactSwal.fire({
      icon: 'warning',
      html: <p>Weet je zeker dat je deze vraag wil verwijderen?</p>,
      showConfirmButton: true,
      confirmButtonText: 'Ja',
      confirmButtonColor: '#eeb244',
      showDenyButton: true,
      denyButtonText: 'Nee',
      denyButtonColor: '#aaa',
    });

    if (isConfirmed) {
      io.emit('deleteSurveyQuestion', id);

      ReactSwal.fire({
        icon: 'success',
        title: <p>De vraag is verwijderd</p>,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  saveEditSurveyQuestion = () => {
    const { io } = this.props;
    const { edit } = this.state;

    if (edit) {
      const { id, question, type, content } = edit;

      io.emit('updateSurveyQuestion', id, question, type, content as string);

      this.setState({ edit: undefined });
    }
  };

  filterSurveyQuestions = (): ISurveyQuestion[] => {
    const { surveyQuestions, surveyQuestionsOrder } = this.state;

    if (surveyQuestionsOrder)
      return surveyQuestions
        .filter((q) => surveyQuestionsOrder.includes(q.id))
        .sort(
          (a, b) =>
            surveyQuestionsOrder.indexOf(a.id) -
            surveyQuestionsOrder.indexOf(b.id),
        )
        .map((q, order) => ({ ...q, order }));

    return [];
  };

  handleSendSurvey = () => {
    const { io } = this.props;

    io.emit('sendSurvey');
  };

  render() {
    const { io, connected, state } = this.props;
    const { edit, surveyQuestions, surveyQuestionsOrder } = this.state;
    const orphanQuestions =
      surveyQuestions.length !== surveyQuestionsOrder.length;

    return (
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800 font-weight-bold">
          Enquête
          <br />
          <small className=" h6 text-muted">
            Deze pagina accepteert enkel wijzigingen indien de enquête niet live
            staat
          </small>
        </h1>
        <div className="row">
          <div className="col-12 col-xl-6">
            <SurveyForm
              io={io}
              buttonDisabled={!connected}
              edit={edit}
              handleEditInputChange={this.handleEditInputChange}
              handleTypeInputChange={this.handleTypeInputChange}
              saveEditSurveyQuestion={this.saveEditSurveyQuestion}
            />
          </div>
          <div className="col" />
          <div className="col-12 col-xl-4">
            <SurveyOptions
              surveyQuestions={this.filterSurveyQuestions()}
              connected={connected}
              state={state}
              onSendSurvey={this.handleSendSurvey}
            />
          </div>
        </div>
        {orphanQuestions && (
          <div className="alert alert-danger" role="alert">
            <i className="fas fa-exclamation-circle" />{' '}
            <span className="font-weight-bold">
              Niet alle vragen zijn aanwezig in dit overzicht!
            </span>{' '}
            <a href="mailto:support@hostin.cc">
              Neem contact op met een beheerder
            </a>{' '}
            om dit probleem op te lossen.
          </div>
        )}
        <SurveyTable
          surveyQuestions={this.filterSurveyQuestions()}
          edit={edit}
          buttonDisabled={!connected}
          handleMove={this.handleMove}
          handleEditSurveyQuestion={this.handleEditSurveyQuestion}
          handleDeleteSurveyQuestion={this.handleDeleteSurveyQuestion}
        />
      </div>
    );
  }
}

export default Survey;
