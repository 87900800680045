import * as React from 'react';
import moment from 'moment';

import { IWebinar } from '../../../../interfaces/IWebinar';
import WebinarCard from './WebinarCard';

export interface WebinarsOverviewProps {
  io: SocketIOClient.Socket;
  connected: boolean;
  webinars: IWebinar[];
  onArchive: (id: IWebinar['id']) => void;
  onResetReminders: (id: IWebinar['id']) => void;
}

export interface WebinarsOverviewState {
  search: string;
}

class WebinarsOverview extends React.Component<
  WebinarsOverviewProps,
  WebinarsOverviewState
> {
  constructor(props: WebinarsOverviewProps) {
    super(props);

    this.state = {
      search: '',
    };
  }

  handleInputChange = ({
    currentTarget: { id, value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    this.setState((s) => ({ ...s, [id]: value }));

  render() {
    const { search } = this.state;
    const { webinars, onArchive, onResetReminders } = this.props;
    const filteredWebinars = webinars.filter((w) =>
      !search
        ? true
        : w.name.toLowerCase().includes(search.toLowerCase()) ||
          w.company.toLowerCase().includes(search.toLowerCase()),
    );

    const futureWebinars = filteredWebinars.filter(({ end }) =>
      moment().subtract(2, 'hours').isSameOrBefore(end),
    );
    const earlyWebinars = filteredWebinars.filter(({ end }) =>
      moment().subtract(2, 'hours').isAfter(end),
    );

    return (
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800 font-weight-bold">Webinars</h1>
        {webinars.length === 0 ? (
          <span>Er zijn geen webinars gevonden</span>
        ) : (
          <>
            <div className="row mb-4">
              <div className="input-group mb-2 mr-sm-2 col-4">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="fas fa-search" />
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="search"
                  placeholder="Zoek in webinars"
                  value={search}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <h5 className="h5 mb-4 text-gray-800 font-weight-bold">
              {futureWebinars.length} aanstaande webinars
            </h5>
            <div className="row">
              {futureWebinars.map((webinar) => (
                <WebinarCard
                  key={webinar.id}
                  webinar={webinar}
                  onArchive={onArchive}
                  onResetReminders={onResetReminders}
                />
              ))}
            </div>
            <h5 className="h5 mb-4 text-gray-800 font-weight-bold">
              {earlyWebinars.length} eerdere webinars
            </h5>
            <div className="row">
              {earlyWebinars.map((webinar) => (
                <WebinarCard
                  key={webinar.id}
                  webinar={webinar}
                  onArchive={onArchive}
                  onResetReminders={onResetReminders}
                />
              ))}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default WebinarsOverview;
