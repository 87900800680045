import * as React from 'react';
import { Link } from 'react-router-dom';

export type Error404Props = Record<string, unknown>;

const Error404: React.SFC<Error404Props> = () => {
  return (
    <div className="container-fluid">
      <div className="text-center">
        <div className="error mx-auto" data-text="404">
          404
        </div>
        <p className="lead text-gray-800 mb-5">Pagina niet gevonden</p>
        <p className="text-gray-500 mb-0">
          Het ziet er naar uit dat je een glitch in de matrix hebt gevonden...
        </p>
        <Link to="/">&larr; Terug naar het begin</Link>
      </div>
    </div>
  );
};

export default Error404;
