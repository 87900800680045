import * as React from 'react';
import { Link } from 'react-router-dom';

export interface SidebarLinkSendProps {
  children: string;
  className: string;
  href: string;
}

export interface SidebarLinkProps extends SidebarLinkSendProps {
  icon: string;
}

const SidebarLink: React.SFC<SidebarLinkProps> = ({
  children,
  icon,
  className,
  href,
}) => {
  return (
    <li className={className}>
      <Link className="nav-link" to={href}>
        <i className={`fas fa-fw ${icon}`} />
        <span>{children}</span>
      </Link>
    </li>
  );
};

export default SidebarLink;
