import * as React from 'react';
import { toast } from 'react-toastify';
import validator from 'validator';

import HttpService from '../../../../services/HttpService';

import RegistrationsDropzone from './RegistrationsDropzone';
import RegistrationsErrors from './RegistrationsErrors';
import RegistrationsExample from './RegistrationsExample';
import RegistrationsSelectHeaders from './RegistrationsSelectHeaders';

export interface RegistrationsUploadProps {
  activeWebinarId: number;
}

export interface RegistrationsUploadState {
  sendConfirmation: boolean;
  registrations?: { [key: string]: unknown }[];
  headers?: string[];
  selectedHeaders?: { field: string; value: string }[];
}

class RegistrationsUpload extends React.Component<
  RegistrationsUploadProps,
  RegistrationsUploadState
> {
  constructor(props: RegistrationsUploadProps) {
    super(props);

    this.state = { sendConfirmation: true };
  }

  handleRegistrations = (
    registrations: { [key: string]: unknown }[],
    headers: string[],
  ) => this.setState({ registrations, headers });

  handleSelectedHeaders = (
    selectedHeaders: { field: string; value: string }[],
  ) => this.setState({ selectedHeaders });

  handleReset = () =>
    this.setState({
      registrations: undefined,
      headers: undefined,
      selectedHeaders: undefined,
    });

  handleUpload = async () => {
    const { activeWebinarId: webinarId } = this.props;
    const { registrations, selectedHeaders, sendConfirmation } = this.state;

    if (registrations && selectedHeaders) {
      const data = registrations?.map((r) => {
        const result: { [key: string]: unknown } = {};

        const registrationValue = (key?: string) => (key ? r[key] : null);

        selectedHeaders.forEach((h) => {
          result[h.field] = registrationValue(h.value);
        });

        return result;
      });

      const registrationsCount = data.length;

      await HttpService.post(
        '/register/upload',
        {
          registrations: data,
        },
        { params: { webinarId, sendConfirmation } },
      );

      this.handleReset();
      toast.success(`${registrationsCount} registraties geïmporteerd`);
    }
  };

  emailErrors = (key: string): string[] => {
    const { registrations } = this.state;

    if (registrations)
      return registrations.reduce((p, c, i) => {
        const v = key ? c[key] : undefined;

        if (v && !validator.isEmail(`${v}`))
          p.push(`Geen valide e-mail adres op regel ${i + 2}`);

        return p;
      }, [] as string[]);

    return [];
  };

  render() {
    const {
      registrations,
      headers,
      selectedHeaders,
      sendConfirmation,
    } = this.state;

    let errors: string[] = [];

    (selectedHeaders || []).forEach((sh) => {
      if (sh.field === 'email')
        errors = errors.concat(this.emailErrors(sh.value));
    });

    return (
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap mb-4">
          <div>
            <h1 className="h3 text-gray-800 font-weight-bold mb-0">
              Registraties uploaden
            </h1>
          </div>

          {registrations && (
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.handleReset()}
              >
                <i className="fas fa-undo mr-2" /> Reset
              </button>
            </div>
          )}
        </div>

        {!registrations || !headers ? (
          <RegistrationsDropzone onRegistrations={this.handleRegistrations} />
        ) : (
          <>
            <div className="row">
              <RegistrationsSelectHeaders
                className="col-12 col-md-6"
                headers={headers}
                onSelectedHeaders={this.handleSelectedHeaders}
              />
              <div className="col-12 col-md-6">
                <RegistrationsExample
                  registration={registrations[0] ?? []}
                  headers={selectedHeaders ?? []}
                />
                <RegistrationsErrors errors={errors} />
              </div>
            </div>
            <div className="row d-flex justify-content-end">
              <div className="col-12 col-md-3">
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="form-group text-center">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="sendConfirmation"
                          id="sendConfirmation"
                          value="on"
                          onChange={({ currentTarget: { checked } }) =>
                            this.setState({ sendConfirmation: checked })
                          }
                          checked={sendConfirmation}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="sendConfirmation"
                        >
                          Stuur bevestigingsmail
                        </label>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      onClick={() => this.handleUpload()}
                      disabled={
                        !selectedHeaders ||
                        selectedHeaders.length === 0 ||
                        !selectedHeaders.some((h) => h.field === 'firstname') ||
                        errors.length !== 0
                      }
                    >
                      {errors.length !== 0 ? (
                        'Controleer fouten'
                      ) : (
                        <>
                          <i className="fas fa-file-import mr-2" />{' '}
                          {registrations.length} registratie
                          {registrations.length === 1 ? '' : 's'} importeren
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default RegistrationsUpload;
