import * as React from 'react';
import { DragDropContext, DropResult, DragStart } from 'react-beautiful-dnd';
import moment from 'moment';

import {
  IQuestion,
  TQuestionsOrder,
  TQuestionStage,
  TUpdatedQuestion,
} from '../../../interfaces/IQuestion';

import AddQuestionCard from './AddQuestionCard';
import QuestionList from './QuestionList';
import AnswerQuestionModal from './AnswerQuestionModal';

export interface QuestionsProps {
  io: SocketIOClient.Socket;
  connected: boolean;
}

export interface QuestionsState {
  questions: IQuestion[];
  questionsOrder: TQuestionsOrder;
  lockedQuestions: number[];
  myLockedQuestions: number[];
  answerQuestion?: number;
}

class Questions extends React.Component<QuestionsProps, QuestionsState> {
  constructor(props: QuestionsProps) {
    super(props);

    this.state = {
      questions: [],
      questionsOrder: {
        incoming: [],
        edit: [],
        approved: [],
      },
      lockedQuestions: [],
      myLockedQuestions: [],
    };
  }

  componentDidMount() {
    const { io } = this.props;

    io.on(
      'questions',
      (questions: IQuestion[], questionsOrder: TQuestionsOrder) => {
        this.setState({
          questions,
          questionsOrder,
        });
      },
    );

    io.on('questionsOrder', (questionsOrder: TQuestionsOrder) =>
      this.setState({ questionsOrder }),
    );
    io.on('lockQuestion', this.handleLockQuestion);
    io.on('updateQuestion', this.onUpdateQuestion);
    io.on('liveQuestion', this.onLiveQuestion);
    io.on('deleteQuestion', this.onDeleteQuestion);

    io.emit('getData', 'questions');
  }

  componentWillUnmount() {
    const { myLockedQuestions } = this.state;
    const { io } = this.props;

    myLockedQuestions.forEach((q) => io.emit('lockQuestion', q, false));

    io.off('questions');
    io.off('questionsOrder');
    io.off('lockQuestion');
    io.off('updateQuestion');
    io.off('liveQuestion');
    io.off('deleteQuestion');
  }

  handleDeleteQuestion = (id: IQuestion['id']) => {
    const { io } = this.props;
    io.emit('deleteQuestion', id);

    this.onDeleteQuestion(id);
  };

  onDeleteQuestion = (id: IQuestion['id']) => {
    this.setState(({ questions }) => ({
      questions: questions.filter((q) => q.id !== id),
    }));
  };

  onSaveQuestionsOrder = (questionsOrder: TQuestionsOrder): void => {
    this.setState({ questionsOrder });

    const { io } = this.props;
    io.emit('questionsOrder', questionsOrder);
  };

  onLockQuestion = (questionId: number, locked: boolean): void => {
    let { myLockedQuestions } = this.state;

    if (locked && !myLockedQuestions.includes(questionId))
      myLockedQuestions.push(questionId);
    else if (!locked && myLockedQuestions.includes(questionId))
      myLockedQuestions = myLockedQuestions.filter((q) => q !== questionId);

    this.setState({ myLockedQuestions });

    const { io } = this.props;
    io.emit('lockQuestion', questionId, locked);
  };

  handleLockQuestion = (questionId: number, locked: boolean): void =>
    this.setState((s) => {
      let { lockedQuestions } = s;

      if (locked && !lockedQuestions.includes(questionId))
        lockedQuestions.push(questionId);
      else if (!locked && lockedQuestions.includes(questionId))
        lockedQuestions = lockedQuestions.filter((q) => q !== questionId);

      return { lockedQuestions };
    });

  onUpdateQuestion = ({
    id,
    reviewedName,
    reviewedQuestion,
    remark,
    answer,
  }: TUpdatedQuestion): void =>
    this.setState(({ questions }) => ({
      questions: questions.map((q) =>
        q.id === id
          ? { ...q, reviewedName, reviewedQuestion, remark, answer }
          : q,
      ),
    }));

  onDragStart = ({ draggableId }: DragStart) => {
    const questionId = parseInt(draggableId, 10);

    this.onLockQuestion(questionId, true);
  };

  onDragEnd = (result: DropResult) => {
    const { questionsOrder } = this.state;
    const {
      draggableId,
      reason,
      destination,
      source: { droppableId: sDID },
    } = result;

    if (destination && reason === 'DROP') {
      const { index: destinationIndex, droppableId: dDID } = destination;
      const questionId = parseInt(draggableId, 10);

      const sourceStage = sDID as TQuestionStage;
      const destinationStage = dDID as TQuestionStage;

      const sourceIndex = questionsOrder[sourceStage].indexOf(questionId);
      questionsOrder[sourceStage].splice(sourceIndex, 1);
      questionsOrder[destinationStage].splice(destinationIndex, 0, questionId);

      this.onSaveQuestionsOrder(questionsOrder);
      this.onLockQuestion(questionId, false);
    }
  };

  onSaveQuestion = (question: IQuestion) => {
    const { io } = this.props;
    io.emit('updateQuestion', question);

    this.onUpdateQuestion(question);
  };

  onLive = (id: IQuestion['id'], onAir: boolean): void => {
    const { io } = this.props;
    io.emit('liveQuestion', id, onAir);

    this.onLiveQuestion(id, onAir);
  };

  onLiveQuestion = (id: IQuestion['id'], onAir: boolean): void => {
    this.setState(({ questions }) => ({
      questions: questions.map((q) => {
        let { wasLive } = q;

        if (onAir) wasLive = moment().toISOString();

        if (q.id === id) return { ...q, onAir, wasLive };
        return q;
      }),
    }));
  };

  onAnswerQuestion = (answerQuestion?: IQuestion['id']): void =>
    this.setState({ answerQuestion });

  handleAnswerQuestion = (id: number, answer: string) => {
    const { io } = this.props;

    this.setState(({ questions }) => ({
      questions: questions.map((q) => (q.id === id ? { ...q, answer } : q)),
    }));

    io.emit('answerQuestion', id, answer);
  };

  filterQuestions = (stage: TQuestionStage): IQuestion[] => {
    const { questions, questionsOrder } = this.state;

    if (questionsOrder)
      return questions
        .filter((q) => questionsOrder[stage].includes(q.id))
        .sort(
          (a, b) =>
            questionsOrder[stage].indexOf(a.id) -
            questionsOrder[stage].indexOf(b.id),
        )
        .map((q, order) => ({ ...q, stage, order }));

    return [];
  };

  render() {
    const { io, connected } = this.props;
    const { questions, lockedQuestions, answerQuestion } = this.state;
    const questionToAnswer = questions.find(({ id }) => id === answerQuestion);

    const columns: { title: string; droppableId: TQuestionStage }[] = [
      { title: 'Inbox', droppableId: 'incoming' },
      { title: 'Voorselectie', droppableId: 'edit' },
      { title: 'Beschikbaar', droppableId: 'approved' },
    ];

    return (
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800 font-weight-bold">Vragen</h1>
        <div className="row">
          <div className="col-6 mb-4">
            <AddQuestionCard io={io} connected={connected} />
          </div>
        </div>
        <div className="row">
          <DragDropContext
            onDragStart={this.onDragStart}
            onDragEnd={this.onDragEnd}
          >
            {columns.map(({ title, droppableId }) => (
              <QuestionList
                key={droppableId}
                title={title}
                droppableId={droppableId}
                questions={this.filterQuestions(droppableId)}
                connected={connected}
                onEdit={this.onLockQuestion}
                onSave={this.onSaveQuestion}
                onDelete={this.handleDeleteQuestion}
                onLive={this.onLive}
                onAnswerQuestion={this.onAnswerQuestion}
                lockedQuestions={lockedQuestions}
              />
            ))}
          </DragDropContext>
        </div>

        <AnswerQuestionModal
          question={questionToAnswer}
          handleAnswerQuestion={this.handleAnswerQuestion}
        />
      </div>
    );
  }
}

export default Questions;
