import * as React from 'react';
import _ from 'lodash';

import { registrationsFields } from './RegistrationsSelectHeaders';

export interface RegistrationsExampleProps {
  className?: string;
  registration: { [key: string]: unknown };
  headers: { field: string; value: string }[];
}

const RegistrationsExample: React.FC<RegistrationsExampleProps> = ({
  className,
  registration,
  headers,
}) => {
  const registrationValue = (key?: string) =>
    key ? registration[key] : undefined;

  const items = _.map(registrationsFields, (v, f) => {
    const value = registrationValue(
      headers.find((o) => o.field === f)?.value,
    ) as string | number | undefined;

    if (!value) return undefined;

    return (
      <div className="form-group row" key={f}>
        <div className="col-3">{v}</div>
        <div className="col-9">{value}</div>
      </div>
    );
  }).filter((v) => !!v);

  return (
    <div className={className ?? ''}>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Voorbeeld</h6>
        </div>
        <div className="card-body">
          {items.length !== 0 ? (
            items
          ) : (
            <span className="text-muted font-italic">
              Koppel velden om een voorbeeld te zien
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegistrationsExample;
