import * as React from 'react';

export interface FormCheckProps {
  className?: string;
  id: string;
  checked: boolean;
  disabled?: boolean;
  text: string;
  onChange: (checked: boolean) => void;
}

const FormCheck: React.FC<FormCheckProps> = ({
  className,
  id,
  checked,
  disabled = false,
  text,
  onChange,
}) => {
  return (
    <div className={`form-group${className ? ` ${className}` : ''}`}>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={id}
          checked={checked}
          onChange={({ currentTarget: { checked: c } }) => onChange(c)}
          disabled={disabled}
        />
        <label
          className={`custom-control-label${disabled ? ' text-muted' : ''}`}
          htmlFor={id}
        >
          {text}
        </label>
      </div>
    </div>
  );
};

export default FormCheck;
