import axios, { AxiosResponse, AxiosError } from 'axios';
import { toast } from 'react-toastify';

import config from '../config';

const { endpoint, prefix } = config.api;
const token = localStorage.getItem('token');

/** Set baseURL to API endpoint from envorinment-file */
axios.defaults.baseURL = `${endpoint}${prefix}`;
if (token) axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

/** Create success handlers */
const successHandler = (response: AxiosResponse<unknown>) => response;

/** Create error handlers */
const errorHandler = (error: AxiosError) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    toast.error('Oeps... Er is iets fout gegaan!');
  }

  return Promise.reject(error);
};

/** Intercept errors and handle them in a Toastr */
axios.interceptors.response.use(successHandler, errorHandler);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  all: axios.all,
};
