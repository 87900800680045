import * as React from 'react';

import { TGetData } from '../../../types/TGetData';
import { IStatement } from '../../../interfaces/IStatement';

import StatementCard from './StatementCard';
import AddStatement from './AddStatement';

export interface StatementsProps {
  io: SocketIOClient.Socket;
  connected: boolean;
  handleGetData: (data: TGetData) => void;
  graphicsConnected: boolean;
}

export interface StatementsState {
  statements: IStatement[];
  lockedStatements: number[];
  myLockedStatements: number[];
}

class Statements extends React.Component<StatementsProps, StatementsState> {
  constructor(props: StatementsProps) {
    super(props);

    this.state = {
      statements: [],
      lockedStatements: [],
      myLockedStatements: [],
    };
  }

  componentDidMount() {
    const { io, handleGetData } = this.props;

    handleGetData('statements');

    io.on('statements', (statements: IStatement[]) =>
      this.setState({ statements }),
    );

    io.on('lockStatement', this.handleLockStatement);
    io.on('updatedStatement', this.onUpdatedStatement);
    io.on('deleteStatement', this.onDeleteStatement);
  }

  componentWillUnmount() {
    const { io } = this.props;

    io.off('statements');
    io.off('updatedStatement');
  }

  onUpdatedStatement = (statement: IStatement) =>
    this.setState(({ statements }) => ({
      statements: statements.map((s) => {
        if (s.id === statement.id) return statement;
        return s;
      }),
    }));

  handleStatementActive = (id: number, active: boolean): void => {
    const { io } = this.props;

    io.emit('statementActive', id, active);
  };

  handleDeleteStatement = (id: IStatement['id']) => {
    const { io } = this.props;
    io.emit('deleteStatement', id);

    this.onDeleteStatement(id);
  };

  onDeleteStatement = (id: IStatement['id']) => {
    this.setState(({ statements }) => ({
      statements: statements.filter((q) => q.id !== id),
    }));
  };

  onLockStatement = (statementId: number, locked: boolean): void => {
    let { myLockedStatements } = this.state;

    if (locked && !myLockedStatements.includes(statementId))
      myLockedStatements.push(statementId);
    else if (!locked && myLockedStatements.includes(statementId))
      myLockedStatements = myLockedStatements.filter((q) => q !== statementId);

    this.setState({ myLockedStatements });

    const { io } = this.props;
    io.emit('lockStatement', statementId, locked);
  };

  handleLockStatement = (statementId: number, locked: boolean): void =>
    this.setState((s) => {
      let { lockedStatements } = s;

      if (locked && !lockedStatements.includes(statementId))
        lockedStatements.push(statementId);
      else if (!locked && lockedStatements.includes(statementId))
        lockedStatements = lockedStatements.filter((q) => q !== statementId);

      return { lockedStatements };
    });

  onSaveStatement = (statement: IStatement) => {
    const { io } = this.props;
    io.emit('updateStatement', statement);

    this.onUpdatedStatement(statement);
  };

  setStatementGraphics = (
    activeStatementId: number,
    active: boolean,
    fullscreen = false,
  ) => {
    const { io } = this.props;

    io.emit('statementGraphics', activeStatementId, active, fullscreen);
  };

  render() {
    const { statements, lockedStatements } = this.state;
    const { io, connected, graphicsConnected } = this.props;
    const activeStatementId = statements.find((s) => s.active)?.id;

    return (
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800 font-weight-bold">Stellingen</h1>

        <div className="row">
          <div className="col-6">
            <AddStatement io={io} connected={connected} />
          </div>
        </div>
        {statements.length === 0 ? (
          <div>Er zijn geen stellingen gevonden</div>
        ) : (
          <div className="row">
            {statements.map((statement) => (
              <StatementCard
                key={statement.id}
                statement={statement}
                activeStatementId={activeStatementId}
                locked={lockedStatements.includes(statement.id)}
                graphicsConnected={graphicsConnected}
                setStatementGraphics={this.setStatementGraphics}
                onEdit={this.onLockStatement}
                onSave={this.onSaveStatement}
                onDelete={this.handleDeleteStatement}
                handleStatementActive={this.handleStatementActive}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default Statements;
