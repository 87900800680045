import * as React from 'react';
import { Link } from 'react-router-dom';

export type ResetPasswordProps = Record<string, unknown>;

export interface ResetPasswordState {
  email: string;
}

class ResetPassword extends React.Component<
  ResetPasswordProps,
  ResetPasswordState
> {
  constructor(props: ResetPasswordProps) {
    super(props);

    this.state = { email: '' };
  }

  componentDidMount(): void {
    document.body.classList.add('bg-gradient-primary');
  }

  componentWillUnmount(): void {
    document.body.classList.remove('bg-gradient-primary');
  }

  handleInputChange = ({
    currentTarget: { name, value },
  }: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState((s) => ({ ...s, [name]: value }));

  handleReset = async (e: React.FormEvent<HTMLFormElement>): Promise<void> =>
    e.preventDefault();

  render() {
    const { email } = this.state;

    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-reset-image" />
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">
                          Wachtwoord vergeten
                        </h1>
                      </div>
                      <form className="user" onSubmit={this.handleReset}>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control form-control-user"
                            id="email"
                            name="email"
                            aria-describedby="emailHelp"
                            placeholder="E-mail adres"
                            value={email}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-user btn-block"
                        >
                          Reset wachtwoord
                        </button>
                      </form>
                      <hr />
                      <div className="text-center">
                        <Link className="btn btn-link" to="/login">
                          Terug naar de inlogpagina
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
