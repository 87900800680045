import * as React from 'react';
// import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import config from '../config';

// import History from '../utilities/History';

import AuthService from '../services/AuthService';

export type LoginProps = Record<string, unknown>;

export interface LoginState {
  email: string;
  password: string;
  totp: string;
}

class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.state = { email: '', password: '', totp: '' };
  }

  componentDidMount(): void {
    document.body.classList.add('bg-gradient-primary');
  }

  componentWillUnmount(): void {
    document.body.classList.remove('bg-gradient-primary');
  }

  handleInputChange = ({
    currentTarget: { name, value },
  }: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState((s) => ({ ...s, [name]: value }));

  handleLogin = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    try {
      const { email, password, totp } = this.state;

      await AuthService.login({ email, password, totp });

      // toast.success('Je bent succesvol ingelogd');
      // History.push('/');

      window.location.href = '/';
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { message } = err.response.data;

        if (message) toast.error(message);
        else toast.error('Oeps... Er is iets fout gegaan');
      } else toast.error('Oeps... Er is iets fout gegaan');
    }
  };

  render() {
    const { email, password, totp } = this.state;
    const { version } = config;

    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">
                          Q Broadcasting Webinar
                        </h1>
                      </div>
                      <form className="user" onSubmit={this.handleLogin}>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control form-control-user"
                            id="email"
                            name="email"
                            aria-describedby="emailHelp"
                            placeholder="E-mailadres"
                            value={email}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control form-control-user"
                            id="password"
                            name="password"
                            placeholder="Wachtwoord"
                            value={password}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="totp"
                            name="totp"
                            placeholder="Authenticator-code"
                            value={totp}
                            onChange={this.handleInputChange}
                            maxLength={6}
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-user btn-block"
                        >
                          Login
                        </button>
                      </form>
                      {/* <hr />
                      <div className="text-center">
                        <Link
                          className="btn btn-link"
                          to="/reset-password"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Tooltip on top"
                        >
                          Wachtwoord vergeten
                        </Link>
                      </div> */}
                      <div className="text-center mt-4">
                        <small>
                          Build <strong>{version}</strong>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
