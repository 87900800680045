import * as React from 'react';

import History from '../../../utilities/History';

export interface HeaderProps {
  title: string;
  connected: boolean;
  questionCount: number;
  handleQuestionCount: (way: 'up' | 'down') => void;
}

const Header: React.SFC<HeaderProps> = ({
  title,
  connected,
  questionCount,
  handleQuestionCount,
}) => {
  return (
    <div className="row mb-3">
      <div className="col-12 text-center">
        <span className="float-left">
          <button
            type="button"
            className="btn btn-dark btn-circle"
            onClick={() => History.push('/')}
          >
            <i className="fas fa-times" />
          </button>
        </span>
        <div className="float-right">
          <button
            type="button"
            className="btn btn-dark mr-2"
            onClick={() => handleQuestionCount('up')}
          >
            <i className="fas fa-fw fa-arrow-up" />
          </button>
          <span className="alert alert-dark small p-2 mr-2" role="alert">
            Laat {questionCount} {questionCount === 1 ? 'vraag' : 'vragen'} zien
          </span>
          <button
            type="button"
            className="btn btn-dark mr-2"
            onClick={() => handleQuestionCount('down')}
          >
            <i className="fas fa-fw fa-arrow-down" />
          </button>
          <i
            className={`fas fa-circle text-${
              connected ? 'success' : 'danger'
            } text-lg mt-2`}
          />
        </div>
        <h1 className="h1">{title}</h1>
      </div>
    </div>
  );
};

export default Header;
