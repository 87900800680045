/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { IQuestion } from '../../../interfaces/IQuestion';

import QuestionCard from './QuestionCard';

export interface QuestionListProps {
  title: string;
  droppableId: string;
  questions: IQuestion[];
  lockedQuestions: number[];
  connected: boolean;
  onEdit: (quedtionId: number, locked: boolean) => void;
  onSave: (question: IQuestion) => void;
  onDelete: (id: IQuestion['id']) => void;
  onLive: (id: IQuestion['id'], live: boolean) => void;
  onAnswerQuestion: (id?: IQuestion['id']) => void;
}

const QuestionList: React.SFC<QuestionListProps> = ({
  title,
  droppableId,
  questions,
  lockedQuestions,
  connected,
  onEdit,
  onSave,
  onDelete,
  onLive,
  onAnswerQuestion,
}) => {
  const [currentEdit, setCurrentEdit] = useState<number | null>(null);

  return (
    <div className="col-4">
      <h1 className="h4 mb-4 text-gray-800">{title}</h1>
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              backgroundColor: snapshot.isDraggingOver
                ? '#eaeaea'
                : 'transparent',
              minHeight: 80,
              paddingBottom: 200,
            }}
          >
            {questions.length === 0 ? (
              <div>Er staan geen vragen in deze lijst</div>
            ) : (
              questions.map((question) => {
                const otherLive = questions.some(
                  (q) => q.onAir && q.id !== question.id,
                );

                return (
                  <Draggable
                    key={question.id}
                    draggableId={question.id.toString()}
                    index={question.order ?? 0}
                    isDragDisabled={
                      !connected ||
                      question.onAir ||
                      question.id === currentEdit ||
                      lockedQuestions.includes(question.id)
                    }
                  >
                    {(p) => (
                      <div
                        ref={p.innerRef}
                        {...p.draggableProps}
                        {...p.dragHandleProps}
                        style={{
                          margin: `0 0 20px 0`,
                          ...p.draggableProps.style,
                        }}
                      >
                        <QuestionCard
                          question={question}
                          currentEdit={currentEdit}
                          otherLive={otherLive}
                          setCurrentEdit={setCurrentEdit}
                          onEdit={onEdit}
                          onSave={onSave}
                          onDelete={onDelete}
                          onLive={onLive}
                          onAnswerQuestion={onAnswerQuestion}
                          locked={lockedQuestions.includes(question.id)}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default QuestionList;
