import * as React from 'react';
import { HeaderType } from 'react-bs-datatable/lib/helpers/types';

import { IRegistration } from '../../../../interfaces/IRegistration';

import DataTable from '../../../components/DataTable';

import RowCheck from './RowCheck';

export interface RegistrationsTableProps {
  registrations?: IRegistration[];
  checkedRegistrations: number[];
  checkRegistration: (id: IRegistration['id'], checked: boolean) => void;
  checkAll: (id: IRegistration['id'], checked: boolean) => void;
}

const RegistrationsTable: React.FC<RegistrationsTableProps> = ({
  registrations,
  checkedRegistrations,
  checkRegistration,
  checkAll,
}) => {
  const headers: HeaderType[] = [
    {
      title: 'Opties',
      prop: 'options',
      headerCell: () => (
        <RowCheck
          className="text-center"
          id={0}
          checked={
            registrations?.length === checkedRegistrations.length &&
            registrations?.length !== 0
          }
          onChange={checkAll}
          disabled={registrations?.length === 0}
        />
      ),
      cell: ({ id }: IRegistration) => (
        <i
          className={`${
            checkedRegistrations.some((r) => r === id)
              ? 'text-primary fas fa-check-'
              : 'text-secondary far fa-'
          }square fa-fw`}
          style={{ fontSize: '1.25rem' }}
        />
      ),

      cellProps: { className: 'text-center', style: { width: 100 } },
    },
  ];

  const addColumn = (
    title: string,
    prop: keyof IRegistration,
    filterable = true,
    cellProps?: HeaderType['cellProps'],
  ): void => {
    let check = registrations?.some((r: IRegistration) => !!r[prop]);
    let cell: HeaderType['cell'] = (r: IRegistration) => r[prop] || '-';

    if (prop === 'breakoutSessions') {
      check = true;
      cell = ({ breakoutSessions }: IRegistration) =>
        breakoutSessions
          ? breakoutSessions.map(({ id, name }) => (
              <div key={`bos-${id}`}>
                <span className="badge badge-primary mr-1">{name}</span>
                <br />
              </div>
            ))
          : '-';
    }

    if (check)
      headers.push({
        title,
        prop,
        filterable,
        cell,
        cellProps,
      });
  };

  addColumn('Voornaam', 'firstname', true, { style: { minWidth: '150px' } });
  addColumn('Achternaam', 'lastname', true, { style: { minWidth: '150px' } });
  addColumn('E-mail', 'email');
  addColumn('Gekoppelde breakout-sessies', 'breakoutSessions', true, {
    className: 'w-25',
  });

  return registrations ? (
    <div className="table-responsive">
      <DataTable
        headers={headers}
        data={registrations}
        classes={{ tbodyRow: 'registration-table-row' }}
        noResults="Geen registraties gevonden"
        rowsPerPage={25}
        rowsPerPageOption={[10, 25, 50, 100]}
        onRowClick={({ id }: IRegistration) =>
          checkRegistration(id, !checkedRegistrations.includes(id))
        }
      />
    </div>
  ) : (
    <div className="h1">
      <i className="fas fa-spinner fa-spin fa-fw" />
    </div>
  );
};

export default RegistrationsTable;
