import * as React from 'react';

import { IRegistration } from '../../../../interfaces/IRegistration';

export interface RegistrationsTableHeaderProps {
  registrations?: IRegistration[];
  checkedRegistrations: number[];
  getRegistrations: () => void;
  openLinkModal: () => void;
}

const RegistrationsTableHeader: React.FC<RegistrationsTableHeaderProps> = ({
  registrations,
  checkedRegistrations,
  getRegistrations,
  openLinkModal,
}) => {
  const { length: crl } = checkedRegistrations;

  return (
    <>
      <h6 className="m-0 font-weight-bold text-primary">
        Overzicht registraties{' '}
        {registrations && (
          <small className="font-italic ml-2">
            Totaal: {registrations.length}
          </small>
        )}{' '}
        {crl > 0 && (
          <small className="font-italic ml-2">Geselecteerd: {crl}</small>
        )}
      </h6>
      <div className="flex-grow-1" />
      <button
        type="button"
        className="btn btn-link btn-registration mr-2"
        onClick={({ currentTarget }) => {
          getRegistrations();
          currentTarget.blur();
        }}
        disabled={registrations === undefined}
      >
        <i
          className={`fas fa-sync fa-sm fa-fw text-gray-500${
            !registrations ? ' fa-spin' : ''
          }`}
        />
      </button>
      <div className="dropdown no-arrow">
        <button
          type="button"
          className="btn btn-link btn-registration dropdown-toggle"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          disabled={registrations === undefined}
        >
          <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400" />
        </button>
        <div
          className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
          aria-labelledby="dropdownMenuLink"
        >
          <div className="dropdown-header">
            {crl === 0
              ? 'Selecteer minimaal één registratie'
              : `Acties voor ${crl} registraties:`}
          </div>
          {crl > 0 && (
            <>
              <button
                type="button"
                className="dropdown-item"
                onClick={() => openLinkModal()}
              >
                <i className="fas fa-user-plus fa-fw mr-2" />
                (Ont)koppel breakout-sessies
              </button>
              {/* <div className="dropdown-divider" />
              <button type="button" className="dropdown-item">
                <i className="fas fa-trash-alt fa-fw mr-2" />
                Verwijderen
              </button> */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RegistrationsTableHeader;
